<template>
    <v-row class="pa-0" no-gutters :class="isDesktop ? 'gap-120 px-10' : 'gap-32'">
        <v-col :cols="isDesktop ? true : 12">
            <v-img :src="storyHeadingImage" />
        </v-col>
        <v-col :cols="isDesktop ? true : 12">
            <StoryHeadingWelcomeCard />

            <v-row no-gutters class="mt-4 ma-0 w-100 d-print-none">
                <div class="text-paragraph-sm">
                    Your Story will complete and your Superpower will be revealed when you complete
                    “What I Offer” and “Who I am”. “What I'm working on” is the springboard into Solve and Grow”.
                </div>
            </v-row>
            <v-row no-gutters class="mt-4 ma-0 w-100">
                <div class="text-paragraph-sm font-weight-600 cursor-pointer" @click="emit('click')">
                    About Story
                    <app-fa-icon icon="fa-arrow-down" class="ml-4" />
                </div>
            </v-row>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import StoryHeadingWelcomeCard from '@/components/story/heading/StoryHeadingWelcomeCard.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import storyHeadingImage from '@/assets/images/story/story-heading-image.svg';

    const emit = defineEmits(['click']);

    const { isDesktop } = useIsDesktop();
</script>
