<template>
    <v-container class="pa-0">
        <div class="text-heading-h3 mb-2 activities-heading d-print-none">Activities</div>
        <div class="text-paragraph-md text-black mb-6 d-print-none">
            Your summary statement will be revealed when you complete these activities.
            {{ props.additionalDescription }}
        </div>
        <v-row no-gutters justify="space-between" class="w-100">
            <v-col
                v-for="(item, index) in props.items"
                cols="12"
                :class="index === props.items.length - 1 ? '' : 'mb-4'"
                :key="index"
                class="fill-height print-story-section-activities-column"
            >
                <StorySectionActivity
                    class="fill-height px-4 pt-4 ma-0 rounded-lg"
                    :status="item.status"
                    :title="item.title"
                    :to="item.to"
                    :to-clickable="item.toClickable"
                >
                    <component :is="item.component" v-if="item.component" :item="item" />
                </StorySectionActivity>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import StorySectionActivity from '@/components/story/section/activity/StorySectionActivity.vue';
    import type { StoryActivity } from '@/store/story/types';

    const props = defineProps<{
        items: StoryActivity[];
        additionalDescription?: string;
    }>();
</script>

<style scoped lang="scss">
    .what-i-offer .activities-heading {
        color: rgb(var(--v-theme-on-secondary-blue-light));
    }

    .who-i-am .activities-heading {
        color: rgb(var(--v-theme-on-secondary-purple-light));
    }

    .what-i-am-working-on .activities-heading {
        color: rgb(var(--v-theme-on-secondary-pink-light));
    }
</style>
