import * as Sentry from '@sentry/vue';

// Components
import App from './App.vue';

// Composables
import { createApp } from 'vue';
// Plugins
import { registerErrorHandling, registerPlugins } from '@/plugins';
import router from '@/router';
import { registerFontAwesomePackage } from '@/plugins/font-awesome-icons/register';

const env = import.meta.env;

console.info(
    `Loading app with environment variables: \n meta.env = ${JSON.stringify({
        DEV: env.DEV,
        MODE: env.MODE,
        PROD: env.PROD,
    })}`,
);

const app = createApp(App);

const apiServerUrl = import.meta.env.VITE_API_SERVER_URL;

if (env.VITE_SENTRY_ENVIRONMENT) {
    Sentry.init({
        app,
        dsn: env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        tracePropagationTargets: ['localhost', new RegExp(apiServerUrl)],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: env.VITE_SENTRY_ENVIRONMENT,
    });
} else {
    console.log('Skipping Sentry initialisation because env.VITE_SENTRY_ENVIRONMENT is empty');
}

import { Chart, ArcElement } from 'chart.js';
Chart.register(ArcElement);


registerFontAwesomePackage();
registerPlugins(app);

if (env.PROD) {
    registerErrorHandling(app);
} else {
    console.log('not registering error handling for vue components in development mode');
}

app.mount('#app');
