<template>
    <LearnedExperienceEditItem
        v-if="isEditingThisExperience"
        class="my-4"
    />
    <v-card
        v-else
        min-width="100"
        min-height="80"
        elevation="0"
        class="rounded-8 px-4 py-5 border-2-grey-200"
        :class="computedCssClasses"
        @click="editable ? store.setEdit(props.item) : false"
    >
        <v-card-item class="pa-0">
            <span class="mx-0 font-weight-600 text-heading-h6">
                {{ props.item.title }}
            </span>
            <template v-slot:append>
                <v-icon size="small" v-if="editable">mdi-pencil</v-icon>
            </template>
        </v-card-item>
        <v-card-text class="mt-2 mb-4 pa-0 text-caption text-custom-neutral-grey-600">
            {{ props.item.description }}
        </v-card-text>
        <div class="d-flex align-center slider-wrapper">
            <v-icon size="xsmall" class="mr-2">mdi-thumb-down</v-icon>
            <v-progress-linear
                :model-value="progress"
                bg-color="custom-neutral-grey-200"
                :color="progressColour"
                height="6"
                :rounded="true"
                class="w-100"
            />
            <v-icon size="xsmall" class="ml-2">mdi-thumb-up</v-icon>
        </div>
    </v-card>

</template>

<script setup lang="ts">
    import { type CanvasLearnedExperience } from '@/store/learned-experiences/types';
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
    import { getEnjoymentColor } from '@/components/canvas/learned-experiences/util';
    import LearnedExperienceEditItem from '@/components/canvas/learned-experiences/LearnedExperienceEditItem.vue';
    import { computed } from 'vue';

    const props = withDefaults(
        defineProps<{
            item: CanvasLearnedExperience;
            editable?: boolean;
        }>(),
        { editable: true },
    );

    const store = useLearnedExperiencesStore();

    const computedCssClasses = computed(() => {
        return [
            props.editable ? 'cursor-pointer' : 'no-cursor-pointer',
            props.item.enjoyment >= 0 ? 'positive-enjoyment': 'negative-enjoyment'
        ];
    })

    const progress = computed(() => {
        return (props.item.enjoyment + 5) * 10;
    });

    const progressColour = computed(() => {
        return getEnjoymentColor(props.item.enjoyment);
    });

    const isEditingThisExperience = computed(() => {
        return store.isEditing && store.current?.id === props.item.id
    })
</script>

<style lang="scss" scoped>
    .slider-wrapper {
        max-width: 250px;
    }
    .positive-enjoyment {
        background: linear-gradient(
            to bottom right,
            white,
            rgb(var(--v-theme-primary-light))
        ) !important;
    }

    .negative-enjoyment {
        background: linear-gradient(
            to bottom right,
            white,
            rgb(var(--v-theme-secondary-red-light))
        ) !important;
    }
</style>
