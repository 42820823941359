<template>
    <v-container class="home-section-item pa-0 d-flex" :class="type">
        <v-sheet class="home-section-item__rectangle d-flex">
            <v-container class="home-section-item-card fill-height">
                <div class="home-section-item-card__icon">
                    <app-fa-icon :icon="icon" />
                </div>
                <div class="home-section-item-card__content" :class="responsiveClass">
                    <div
                        class="home-section-item-card__content__title text-heading-h3 align-self-stretch text-center text-custom-neutral-black mb-2"
                    >
                        {{ title }}
                    </div>
                    <div
                        class="home-section-item-card__content__description text-center text-paragraph-md text-custom-neutral-900 px-0"
                    >
                        {{ description }}
                    </div>
                </div>
            </v-container>
        </v-sheet>
    </v-container>
</template>
<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { responsiveClass } = useIsDesktop();

    defineProps<{
        type: 'story' | 'challenges' | 'growth';
        icon: string;
        title: string;
        description: string;
    }>();
</script>

<style lang="scss" scoped>
    .home-section-item {
        &.story {
            --color: rgb(var(--v-theme-story-outline));
        }

        &.challenges {
            --color: rgb(var(--v-theme-challenges-outline));
        }

        &.growth {
            --color: rgb(var(--v-theme-grow-outline));
        }
    }

    .home-section-item__rectangle {
        border-radius: 10px;

        border: 2px solid var(--color);
    }

    .home-section-item-card {
        height: fit-content;
        position: relative;
        right: 4.833px;
        top: -5px;

        display: flex;
        padding: 32px 20px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        flex: 1 0 0;
        align-self: stretch;

        border-radius: 8px;
        background: white;

        border: 2px solid var(--color);
    }

    .home-section-item-card__icon {
        font-size: 36px;
        font-weight: 400;
        line-height: 36px;
        word-wrap: break-word;

        color: var(--color);
    }

    .home-section-item-card__content__title {
        min-height: 58px;
    }

    .home-section-item-card__content__description {
        min-height: 102px;
    }

    .home-section-item__content {
        align-self: stretch;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: flex;
    }
</style>
