<template>
    <GrowPathPriorityActivity v-if="store.prioritiseSelectionView" />
    <app-page-content v-else>
        <v-container class="d-flex flex-column pa-0 ga-16">
            <v-container class="w-100 text-center pa-0" max-width="600">
                <div class="text-heading-h1 mb-5">What matters most to you?</div>
                <div class="text-paragraph-md">
                    In order to find the right pathway to your vision, we need to understand a bit
                    more about your needs and preferences. This helps us shape your plan more
                    accurately by reducing options.
                    <br />
                    <br />
                    We’ve highlighted {{ store.predefinedOptions.length }} main preference
                    categories. Some people won’t have any preferences in one category - that’s OK.
                    But choose as many preferences in each as you can - don’t worry if you don’t
                    have any right now, you can come back later and complete this. To move forward
                    though, you will need to choose at least {{ MINIMUM_AMOUNT_PREFERENCES }}.
                </div>
            </v-container>

            <v-container
                class="pa-0"
                v-for="(preferenceType, index) in store.predefinedOptions"
                :key="index"
            >
                <div class="text-center text-heading-h3 mb-8 d-flex ga-8 align-center">
                    <v-divider />
                    <div class="d-flex align-center">
                        {{ preferenceType.title }}
                        <v-tooltip
                            location-strategy="connected"
                            content-class="bg-white text-neutral-black text-paragraph-md elevation-3"
                        >
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" size="16" class="ml-2">
                                    mdi-help-circle
                                </v-icon>
                            </template>
                            {{ preferenceType.description }}
                        </v-tooltip>
                    </div>
                    <v-divider />
                </div>
                <v-row
                    no-gutters
                    class="pa-0 mb-4 category-items"
                    :class="isDesktop ? 'ga-6' : 'ga-2'"
                >
                    <v-col
                        class="pa-0 flex-grow-0"
                        v-for="(option, index) in preferenceType.preferences"
                        :key="index"
                    >
                        <GrowPathPreferenceCategorySelectionCard
                            :value="option"
                            :preference-type="preferenceType"
                            :is-selected="store.isSelected(option, preferenceType.type)"
                            @click="onCardClick(option, preferenceType.type)"
                            :width="isDesktop ? 220 : 150"
                            :height="isDesktop ? 220 : 150"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </app-page-content>

    <GrowVisionAndPrefsGuide />

    <app-page-footer>
        <template v-slot:actions>
            <app-page-tabs-footer-actions
                close-text="Back"
                @on-back="onBack"
                title="Continue"
                @on-submit="onSave"
                :submit-tooltip="submitTooltip"
                :submit-disabled="
                    store.prioritiseSelectionView
                        ? !store.isPrioritySelectionComplete
                        : !store.isPreferencesSelectionComplete
                "
            />
        </template>
    </app-page-footer>
</template>

<script setup lang="ts">
    import { computed, onMounted } from 'vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import {
        MINIMUM_AMOUNT_PREFERENCES,
        useGrowPreferencesStore,
    } from '@/store/grow/preferencesStore';
    import {
        type ApiGrowPredefinedPreferenceItem,
        GrowPreferencePreferenceTypeId,
    } from '@/api/types/grow/preferences';
    import GrowPathPreferenceCategorySelectionCard from '@/components/grow/path/preferences/GrowPathPreferenceCategorySelectionCard.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import GrowPathPriorityActivity from '@/components/grow/path/preferences/GrowPathPriorityActivity.vue';
    import GrowVisionAndPrefsGuide from '@/components/grow/GrowVisionAndPrefsGuide.vue';
    import { categoryValues } from '@/components/grow/path/preferences/utils';

    const store = useGrowPreferencesStore();
    const activityStore = useActivityStore();

    const { isDesktop } = useIsDesktop();

    const navigationStore = useNavigationStore();

    await store.load();

    const onCardClick = async (
        option: ApiGrowPredefinedPreferenceItem,
        type: GrowPreferencePreferenceTypeId,
    ) => {
        await store.togglePreference(option, type);
    };

    onMounted(async () => {
        await activityStore.load();
    });

    const onSave = async () => {
        if (!store.isError) {
            if (store.prioritiseSelectionView) {
                await useNavigationStore().setNext();
            } else {
                store.prioritiseSelectionView = true;
            }
        }
    };

    const submitTooltip = computed(() => {
        if (store.prioritiseSelectionView) {
            return store.isPrioritySelectionComplete
                ? ''
                : `You need at least one of each category and a max of ${categoryValues
                      .map((c) => `${c.maxAmount} ${c.title}`)
                      .join(', ')} before continuing...`;
        } else {
            return store.isPreferencesSelectionComplete
                ? ''
                : `You need at least ${MINIMUM_AMOUNT_PREFERENCES} preferences before continuing...`;
        }
    });

    const onBack = async () => {
        if (store.prioritiseSelectionView) {
            store.prioritiseSelectionView = false;
        } else {
            await navigationStore.setPrevious();
        }
    };
</script>

<style lang="scss" scoped>
    .category-items {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        align-self: stretch;
        flex-wrap: wrap;
    }
</style>
