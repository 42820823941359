<template>
    <v-card color="primary-light" elevation="0 rounded-8 pa-6" min-height="120">
        <v-card-title class="pa-0 mb-2 text-heading-h2 font-weight-700 text-custom-neutral-black">
            {{ formattedProgress }}% complete
            <v-progress-linear
                :model-value="store.progress"
                color="secondary-orange"
                class="w-100 mt-2"
                height="6"
                :rounded="true"
            ></v-progress-linear>
        </v-card-title>
        <v-card-text
            v-if="store.isActivitiesCompleted"
            class="text-paragraph-sm font-weight-600 text-custom-neutral-black pa-0"
        >
            Well done completing this job!
        </v-card-text>
        <v-card-text
            v-else-if="store.isInProgress"
            class="text-paragraph-sm font-weight-600 text-custom-neutral-black mb-5 pa-0"
        >
            {{ formattedDaysLeft }}
        </v-card-text>
        <v-card-text
            v-if="store.isInProgress"
            class="text-paragraph-sm text-custom-neutral-black pa-0"
        >
            Update your progress on this job by marking which activities you have completed.
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useGrowJobActivityStore } from '@/store/grow/jobActivityStore';

    const store = useGrowJobActivityStore();

    const formattedProgress = computed(() => {
        return Math.round(store.progress);
    });

    const formattedDaysLeft = computed(() => {
        const days = store.daysLeft;
        if (days === null) {
            return `No due date set for this job path.`;
        } else if (days > 1) {
            return `${days} days left on this job path.`;
        } else if (days === 1) {
            return `1 day left on this job path.`;
        } else if (days === 0) {
            return `This job path ends today.`;
        } else {
            return `This job path has ended.`;
        }
    });
</script>
