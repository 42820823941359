import type { RouteLocationRaw } from 'vue-router';
import type { ApiStoryStatement } from '@/api/types/canvas/apiStory';

export type StorySection = {
    content: string | null;

    /**
     * If null the feedback has not been provided yet
     * If true the feedback and is positive
     * If false the feedback and is positive
     */
    feedback?: boolean | null;
    state: StorySectionState | null;
};

export type StoryStatement = ApiStoryStatement

export type Story = {
    id: number;
    canvasId: number;
    uvp: StoryStatement | null;
    whoIAm: StoryStatement | null;
    whatIOffer: StoryStatement | null;
    whatIAmWorkingOn: StoryStatement | null;
    createdAt: Date;
    updatedAt: Date;
};

export enum StoryActivityKey {
    UniqueValueProposition = 'unique-value-proposition',
    CurrentChallenges = 'current-challenges',
    FutureAspirations = 'future-aspirations',
    LifeVision = 'life-vision',
    WorkPreferences = 'work-preferences',
    PersonalValues = 'personal-values',
    PersonalityType = 'personality-type',
    Drivers = 'drivers',
    PersonalStrengths = 'personal-strengths',
    Skills = 'learned-skills',
    TechnicalSkills = 'technical-skills',
    LearnedExperiences = 'learned-experiences',
}

export type StoryActivity = {
    key: StoryActivityKey;
    title: string;
    status: StoryActivityStatus;
    component?: any;
    toClickable?: boolean;
    to: RouteLocationRaw;
};

export enum StorySectionState {
    NotStarted = 'not-started',
    InProgress = 'in-progress',
    Completed = 'completed',
}

export enum StorySectionId {
    WhoIAm = 'who-i-am',
    WhatIOffer = 'what-i-offer',
    WhatIAmWorkingOn = 'what-i-am-working-on',
    UVP = 'uvp',
}

export type StorySectionItem = {
    id: StorySectionId;
    title: string;
    description: string;
    additionalDescription?: string;
    image: string;

    statement: ApiStoryStatement | null;
    activities: StoryActivity[];
    status: StorySectionState;
    showFeedbackQuestion: boolean;
};

export enum StoryActivityStatus {
    NotStarted = 'not-started',
    InProgress = 'in-progress',
    Completed = 'completed',
}
