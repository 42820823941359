<template>
    <StoryCard color="primary-light">
        <template #title>Pathways</template>

        <template #description>
            We recommend growth pathways for you that we think will best meet your profile and
            your vision & preferences. These pathways are ‘ways of working’ that will help you
            choose the type of work that suits you and your lifestyle.
        </template>

        <template #content>
            <v-container class="pa-0 bg-primary-light" v-if="pathsWithAcceptedJobs.length">
                <v-card variant="flat" class="pa-0" color="primary-light">
                    <v-card-text class="pa-0">
                        <v-card
                            v-for="(item, index) in pathsWithAcceptedJobs"
                            :key="index"
                            :item="item"
                            min-width="260"
                            class="rounded-0 rounded-lg challenge-item border-2-grey-200"
                            :class="index > 0 ? 'mt-2' : ''"
                            elevation="0"
                        >
                            <v-card-item class="w-100 pa-5">
                                <div class="text-heading-h4">{{ item.title }}</div>

                                <template v-if="isDesktop" #append>
                                    <PathwayInProgressChip
                                        v-if="hasAcceptedJobs(item)"
                                    />
                                </template>
                                <div v-if="!isDesktop && item.job_options.length">
                                    <PathwayInProgressChip
                                        v-if="hasAcceptedJobs(item)"
                                    />
                                </div>
                            </v-card-item>
                        </v-card>
                    </v-card-text>

                    <v-card-actions class="pa-0 mt-6">
                        <app-button
                            color="primary"
                            variant="flat"
                            :size="isDesktop ? 'large' : 'small'"
                            :to="growPathsRoute"
                            >View all pathways
                        </app-button>
                    </v-card-actions>
                </v-card>
            </v-container>
        </template>
    </StoryCard>
</template>

<script setup lang="ts">
    import StoryCard from '@/components/story/section/StoryCard.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useGrowPathStore } from '@/store/grow/store';
    import { computed } from 'vue';
    import type { FullPathOption } from '@/store/grow/types';
    import { growPathsRoute } from '@/router/routes';
    import PathwayInProgressChip from '@/components/common/pathway/PathwayInProgressChip.vue';

    const store = useGrowPathStore();

    const { isDesktop } = useIsDesktop();

    // TODO: CORE-265 - move these two functions to the store?
    const hasAcceptedJobs = (path: FullPathOption) => {
        return path.job_options.some((job) => job.state === 'accepted');
    };

    const pathsWithAcceptedJobs = computed(() => {
        return store.paths.filter(hasAcceptedJobs);
    });
</script>
