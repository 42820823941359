<template>
    <GrowPathPathwaysSuitability />

    <GrowPathwaysGuide />

    <app-page-footer>
        <template v-slot:actions>
            <app-page-tabs-footer-actions
                close-text="Back"
                @on-back="onBack"
                title="Continue"
                @on-submit="onSave"
                :submit-tooltip="submitTooltip"
                :submit-disabled="submitDisabled"
            />
        </template>
    </app-page-footer>
</template>

<script setup lang="ts">
    import { computed, onBeforeUnmount, onMounted } from 'vue';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import { useGrowPathwaysStore } from '@/store/grow/pathwaysStore';
    import { useActivityStore } from '@/store/activity/store';
    import GrowPathPathwaysSuitability from '@/components/grow/path/pathways/GrowPathPathwaysSuitability.vue';
    import GrowPathwaysGuide from '@/components/grow/GrowPathwaysGuide.vue';
    import { UserActivityArea } from '@/api/types/userActivity';

    const navigationStore = useNavigationStore();
    const store = useGrowPathwaysStore();
    const activityStore = useActivityStore();

    await store.load();

    onMounted(async () => {
        await activityStore.load();
    });

    const onBack = async () => {
        store.cancelPathsView()
        await navigationStore.setPrevious();
    };

    const onSave = async () => {
        await store.acceptGrowPath();

        await useNavigationStore().setNext();
    };

    const submitTooltip = computed(() => {
        return store.isAreaComplete
            ? ''
            : 'Selected a grow path option before continuing...';
    });

    const submitDisabled = computed(() => {
        return !store.isAreaComplete;
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.FutureAspiration);
    });
</script>
