<template>
    <app-page-tabs>
        <app-page-header>
            <template #title>
                <span v-if="growItem">
                    Grow:&nbsp;
                    <span class="font-weight-600">{{ growItem.description }}</span>
                </span>
                <span v-else>Grow</span>
            </template>
        </app-page-header>

        <template #tutorial>
            <GrowPathTutorial @done="navigationStore.setNext" />
        </template>

        <template #vision>
            <GrowPathVision />
        </template>
        <template #preferences>
            <GrowPathPreferences />
        </template>
        <template #pathways>
            <GrowPathPathways />
        </template>
        <template #jobs>
            <GrowPathJobs />
        </template>
        <template #activities>
            <GrowPathActivities />
        </template>
        <template #approach>
            <GrowPathApproach />
        </template>
        <template #progress>
            <GrowPathProgress />
        </template>

        <app-page-tabs-footer />

        <PageActivity :area="UserActivityArea.GrowPath" />
    </app-page-tabs>
</template>

<script setup lang="ts">
    import { computed, onBeforeUnmount, onMounted } from 'vue';

    import { useCanvasStore } from '@/store/canvas/store';

    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import { makeNavigationTabs } from '@/store/navigation/utils';
    import { useRoute } from 'vue-router';
    import GrowPathTutorial from '@/components/grow/path/GrowPathTutorial.vue';
    import GrowPathVision from '@/components/grow/path/GrowPathVision.vue';
    import GrowPathPreferences from '@/components/grow/path/GrowPathPreferences.vue';
    import { useLifeVisionStore } from '@/store/grow/lifeVisionStore';
    import { useGrowPreferencesStore } from '@/store/grow/preferencesStore';
    import GrowPathPathways from '@/components/grow/path/pathways/GrowPathPathways.vue';
    import { useGrowPathwaysStore } from '@/store/grow/pathwaysStore';
    import GrowPathJobs from '@/components/grow/path/pathways/GrowPathJobs.vue';
    import { useGrowJobsStore } from '@/store/grow/jobsStore';
    import GrowPathActivities from '@/components/grow/path/activities/GrowPathActivities.vue';
    import GrowPathApproach from '@/components/grow/path/approach/GrowPathApproach.vue';
    import { useGrowJobActivityStore } from '@/store/grow/jobActivityStore';
    import GrowPathProgress from '@/components/grow/path/progress/GrowPathProgress.vue';

    const growItem = computed(() => {
        return navigationStore.ctx?.item ?? null;
    });

    const route = useRoute();

    const items = makeNavigationTabs(
        [
            {
                title: 'Tutorial',
                key: 'tutorial',
                footer: false,
                onSelect: async () => {
                    await navigationStore.clearContext();
                },
                completed: () => lifeVisionStore.isAreaComplete,
            },
            {
                title: 'Vision',
                key: 'vision',
                default: true,
                onSelect: async () => {
                    await navigationStore.clearContext();
                },
                completed: () => lifeVisionStore.isAreaComplete,
                // The footer in this view is handled by the GrowPathVision component
                // At the moment the cb functions are too complex to handle here
                footer: false,
            },
            {
                title: 'Preferences',
                key: 'preferences',
                completed: () => growPreferencesStore.isAreaComplete,
                disabled: () => !lifeVisionStore.isAreaComplete,
                footer: false,
            },
            {
                title: 'Pathways',
                key: 'pathways',
                completed: () => growPathwaysStore.isAreaComplete,
                disabled: () => !growPreferencesStore.isAreaComplete,
                footer: false,
            },
            {
                title: 'Jobs',
                key: 'jobs',
                completed: () => growJobsStore.isAreaComplete,
                disabled: () => !growPathwaysStore.isAreaComplete,
                footer: false,
            },
            {
                title: 'Activities',
                key: 'activities',
                completed: () => jobActivityStore.isActivitiesSelectionComplete,
                disabled: () => !growJobsStore.isAreaComplete,
                footer: false,
            },
            {
                title: 'Approach',
                key: 'approach',
                completed: () => jobActivityStore.isActivitiesSequencingComplete,
                disabled: () => !jobActivityStore.isActivitiesSelectionComplete,
                footer: false,
            },
            {
                title: 'Progress',
                key: 'progress',
                completed: () => {
                    return jobActivityStore.isActivitiesCompleted;
                },
                disabled: () => {
                    return !jobActivityStore.isActivitiesSequencingComplete;
                },
                footer: false,
            },
        ],
        route.query.tab as string,
    );

    const navigationStore = useNavigationStore();
    const lifeVisionStore = useLifeVisionStore();
    const growPreferencesStore = useGrowPreferencesStore();
    const growPathwaysStore = useGrowPathwaysStore();
    const growJobsStore = useGrowJobsStore();
    const jobActivityStore = useGrowJobActivityStore();
    await navigationStore.init(items);

    const { setTutorial } = useNavigationStore();

    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();

    await canvasStore.load();
    await lifeVisionStore.load();
    await growPreferencesStore.load();

    onMounted(async () => {
        if (!lifeVisionStore.isAreaComplete) {
            await setTutorial();
        }

        await activityStore.addEntryActivity(UserActivityArea.GrowPath);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.GrowPath);
    });
</script>
