<template>
    <ProfileCard v-if="curriculums.length" title="Existing CV's">
        <v-card-text class="px-0 text-wrap text-paragraph-md">
            <span class="px-0 pt-5 pb-3">
                <CurriculumVitaeItem
                    :file="cv"
                    :key="index"
                    v-for="(cv, index) in curriculums"
                    class="mb-4"
                />
            </span>
        </v-card-text>
    </ProfileCard>

    <ProfileCard
        :title="curriculums.length ? 'Add your latest CV' : 'Add your CV'"
        :subtitle="`Sharing your CV serves as a starting point to identify your skills,
            capabilities, and experiences. Don’t worry if it’s not up to date! This helps us
            understand your career history and goals. ${linkedInHelpText}`"
    >
        <v-card-text class="pa-0 text-wrap text-paragraph-md">
            <span class="px-0 pt-5 pb-3">
                <CurriculumVitaeInput />
            </span>
        </v-card-text>
    </ProfileCard>
</template>

<script setup lang="ts">
    import CurriculumVitaeInput from '@/components/profile/curriculum/CurriculumVitaeInput.vue';
    import { computed } from 'vue';
    import { useUserProfileStore } from '@/store/profile/store';
    import CurriculumVitaeItem from '@/components/profile/curriculum/CurriculumVitaeItem.vue';
    import ProfileCard from '@/components/profile/ProfileCard.vue';
    import { linkedInHelpText } from '@/components/profile/curriculum/util';

    const store = useUserProfileStore();

    const curriculums = computed(() => {
        return (store.userInputs?.curriculums ?? []).map((cv) => {
            return new File([], cv.filename, {
                type: cv.content_type,
                lastModified: new Date(cv.updated_at).getTime(),
            });
        });
    });
</script>
