<template>
    <!-- ml-n4 compensates in mobile the padding on the <header />, so it starts next to the left    -->
    <v-list density="compact" nav>
        <AppHeaderListItem
            class="mb-4 pa-0"
            :to="homeRoute"
            :active="urlStore.isHomePath"
            :disabled="!userStore.hasProfileBeenSet"
            icon="home"
            tooltip="Home"
        />
        <AppHeaderListItem
            class="mb-4 pa-0"
            :to="storyRoute"
            :active="urlStore.isStoryPath"
            :disabled="!userStore.hasProfileBeenSet"
            icon="book"
            tooltip="Story"
        />
        <AppHeaderListItem
            class="pa-0"
            :to="challengesHomeRoute"
            :active="urlStore.isChallengePath"
            :disabled="!userStore.hasProfileBeenSet || !hasUvp"
            icon="road"
            :tooltip="solveTitle"
        />

        <AppHeaderListItem
            v-if="userStore.hasGPFeature"
            :to="growHomeRoute"
            :active="urlStore.isGrowPath"
            :disabled="!userStore.hasProfileBeenSet || !hasUvp"
            class="pa-0"
            icon="tree"
            :tooltip="growTitle"
        />
    </v-list>
</template>

<script setup lang="ts">
    import { useUsersStore } from '@/store/user/store';
    import { challengesHomeRoute, growHomeRoute, homeRoute, storyRoute } from '@/router/routes';
    import AppHeaderListItem from '@/components/header/AppHeaderListItem.vue';
    import { useURLStore } from '@/store/navigation/useURLStore';
    import { useNavigationTitles } from '@/composables/common/useNavigationTitles';
    import { useStoryStore } from '@/store/story/store';
    import { onMounted } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';

    const userStore = useUsersStore();
    const storyStore = useStoryStore();

    // we need to delay the loading of the story and canvas until the header is mounted
    // otherwise we get an error:
    //
    // "Component <Anonymous>: setup function returned a promise, but no <Suspense> boundary was
    // found in the parent component tree. A component with async setup() must be nested in a
    // <Suspense> in order to be rendered."
    onMounted(async () => {
        await useCanvasStore().load(); // story requires canvas
        await storyStore.load();
    });

    const { hasUvp, solveTitle, growTitle } = useNavigationTitles();

    const urlStore = useURLStore();
</script>

<style scoped lang="scss">
    .btn-navigation {
        opacity: 0.2;
    }

    :deep(.v-list-item-title) {
        font-size: 18px;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.045px;
    }
</style>
