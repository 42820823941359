import {
    addDays,
    addMonths,
    differenceInMonths,
    differenceInYears,
    format,
    isBefore,
    subMonths,
    subYears,
} from 'date-fns';
import { createEvent, type EventAttributes } from 'ics';
import { saveAs } from 'file-saver';
import type {
    ActionFrequency,
    CurrentChallengeAction,
} from '@/api/types/plan/currentChallengeAction';
import { UTCDate } from '@date-fns/utc';
import type { PathAcceptedJobActivity } from '@/store/grow/types';

const TITLE_PREFIX = 'Complete Actvo Activity';
const DEFAULT_MIN_DURATION = 30;

// Day of week codes for iCalendar (0 = Sunday)
const DAYS_OF_WEEK = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'] as const;

export const downloadActionAsICS = (
    action: CurrentChallengeAction | PathAcceptedJobActivity,
): void => {
    const event = buildEventAttributes(action);

    console.log('event', event);

    createEvent(event, (error, value) => {
        if (error) {
            console.error('Error creating ICS event:', error);
            return;
        }

        const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' });
        saveAs(blob, `${action.title}.ics`);
    });
};

export const buildEventAttributes = (
    action: CurrentChallengeAction | PathAcceptedJobActivity,
): EventAttributes => {
    const startDate = new Date();
    let dueDate: Date;
    if ('due_date' in action) {
        // This is a CurrentChallengeAction
        dueDate = new Date(action.due_date);
    } else {
        // This is a PathAcceptedJobActivity
        dueDate = new Date(action.due_at);
    }

    const title = `${TITLE_PREFIX}: ${action.title}`;

    const description = formatDescription(
        action.description,
        action.long_description,
        action.links,
    );

    // ensure duration is at least 30 minutes
    let duration: number;
    if ('duration_min' in action) {
        // This is a CurrentChallengeAction
        duration = Math.max(action.duration_min, DEFAULT_MIN_DURATION);
    } else {
        // This is a PathAcceptedJobActivity
        duration = DEFAULT_MIN_DURATION;
    }

    const [recurrenceRule, actualStartDate] = getRecurranceRuleAndStartDate(
        action.frequency,
        startDate,
        dueDate,
    );

    // Convert start date to array format required by ics
    const start: [number, number, number, number, number] = [
        actualStartDate.getFullYear(),
        actualStartDate.getMonth() + 1,
        actualStartDate.getDate(),
        startDate.getHours(), // this is intentional since getTimeStuff strips time
        startDate.getMinutes(), // this is intentional since getTimeStuff strips time
    ];

    const event = {
        start,
        title,
        description,
        duration: { minutes: duration },
        recurrenceRule,
    };

    return event;
};

export const getRecurranceRuleAndStartDate = (
    frequency: ActionFrequency,
    actualStartDate: Date,
    actualEndDate: Date,
): [string, Date] => {
    // In this function we only want to deal with years, months, and days.
    // We don't want to deal with hours and minutes.
    const startDate = new UTCDate(
        actualStartDate.getFullYear(),
        actualStartDate.getMonth(),
        actualStartDate.getDate(),
    );
    const endDate = new UTCDate(
        actualEndDate.getFullYear(),
        actualEndDate.getMonth(),
        actualEndDate.getDate(),
    );

    // Format the end date as YYYYMMDD for the UNTIL parameter
    const untilDate = format(endDate, 'yyyyMMdd');

    switch (frequency) {
        case 'daily':
            return [`FREQ=DAILY;INTERVAL=1;UNTIL=${untilDate}`, startDate];
        case 'weekly':
            // first day on or after startDate that is the same day of the week as endDate
            const weeklyStartDate = addDays(
                startDate,
                (endDate.getDay() - startDate.getDay() + 7) % 7,
            );
            return [
                `FREQ=WEEKLY;BYDAY=${dayOfWeekToCode(endDate)};INTERVAL=1;UNTIL=${untilDate}`,
                weeklyStartDate,
            ];
        case 'monthly':
            let monthlyStartDate = endDate;
            const monthDifference = differenceInMonths(endDate, startDate);
            if (monthDifference > 0) {
                monthlyStartDate = subMonths(endDate, monthDifference);
            }

            // if subtracting months puts us before the start date, we need to add one month
            if (isBefore(monthlyStartDate, startDate)) {
                monthlyStartDate = addMonths(monthlyStartDate, 1);
            }

            return [
                `FREQ=MONTHLY;BYMONTHDAY=${endDate.getDate()};INTERVAL=1;UNTIL=${untilDate}`,
                monthlyStartDate,
            ];
        case 'yearly':
            let yearlyStartDate = endDate;
            const yearDifference = differenceInYears(yearlyStartDate, startDate);
            if (yearDifference > 0) {
                yearlyStartDate = subYears(yearlyStartDate, yearDifference);
            }

            return [
                `FREQ=YEARLY;BYMONTH=${
                    endDate.getMonth() + 1
                };BYMONTHDAY=${endDate.getDate()};UNTIL=${untilDate}`,
                yearlyStartDate,
            ];
        default:
            console.error('Invalid frequency:', frequency);
            return ['', startDate];
    }
};

// The following 3 functions are only exported for testing purposes
export const dayOfWeekToCode = (date: Date): string => {
    return DAYS_OF_WEEK[date.getDay()];
};

export const formatLinks = (links?: string | null): string => {
    if (!links) return '';

    try {
        const parsedLinks = JSON.parse(links) as Array<{ title: string; url: string }>;
        return parsedLinks.map((link) => `• ${link.title} (${link.url})`).join('\n');
    } catch {
        return '';
    }
};

export const formatDescription = (
    description?: string,
    longDescription?: string,
    links?: string,
): string => {
    const formattedLinks = formatLinks(links);
    return [description, longDescription, formattedLinks].filter(Boolean).join('\n\n');
};
