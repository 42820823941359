<template>
    <app-page-content>
        <template #intro>
            <app-page-content-intro title="Select activities for your jobs">
                <div class="text-paragraph-md font-weight-600 mb-4">
                    {{ jobsStore.job?.title }}
                </div>

                You have chosen these jobs, and we are showing here what skills, experience or
                connection gaps you might want to work on to enable you to transition into these
                roles. Select as many as you can to start against each job, and we will then help
                you create a development plan.

                <br />
                <br />

                <GrowPathJobsSelectionJobOptionCompetency
                    v-if="jobsStore.job"
                    type="skill"
                    :value="jobsStore.job"
                />
                <GrowPathJobsSelectionJobOptionCompetency
                    v-if="jobsStore.job"
                    type="work_experience"
                    :value="jobsStore.job"
                />
            </app-page-content-intro>
        </template>

        <div v-if="!isLoadingMoreActivities">
            <GrowPathActivitiesJobActivity
                v-for="(activity, index) in activities"
                :key="index"
                :item="activity"
                class="mb-2 w-100"
                @read-more-click="onReadMore(activity)"
            />

            <div v-if="store._timeoutError">
                <div class="mt-4">
                    Sorry! Looks like your activities didn't load this time. Refresh and let's
                    <span class="px-0 cursor-pointer text-secondary-blue" @click="tryAgain">
                        try again.</span
                    >
                </div>
            </div>

            <GrowPathActivitiesJobActivityNewForm v-if="store.current" />
            <GrowPathActivitiesButtons
                v-else
                @create-own-activity="store.addActivity"
                @fetch-new-activities="loadMoreActivities"
            />
        </div>
        <app-global-loader v-else />
    </app-page-content>

    <GrowJobAndActivitiesGuide />

    <app-page-footer>
        <template v-slot:actions>
            <app-page-tabs-footer-actions
                close-text="Back"
                @on-back="navigationStore.setPrevious"
                title="Continue"
                @on-submit="onSave"
                :submit-tooltip="submitTooltip"
                :submit-disabled="!store.isActivitiesSelectionComplete"
            />
        </template>
    </app-page-footer>

    <v-dialog close-on-back max-width="640" v-model="showReadModal">
        <GrowPathActivitiesJobActivityReadMore
            v-if="current"
            :item="current"
            class="mb-2 w-100 fill-height"
            @close="showReadModal = false"
        />
    </v-dialog>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import AppPageContentIntro from '@/components/common/app-page/AppPageContentIntro.vue';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import {
        MINIMUM_AMOUNT_JOB_ACTIVITIES,
        useGrowJobActivityStore,
    } from '@/store/grow/jobActivityStore';
    import GrowPathActivitiesJobActivity from '@/components/grow/path/activities/GrowPathActivitiesJobActivity.vue';
    import type { PathJobActivity } from '@/store/grow/types';
    import GrowPathActivitiesJobActivityReadMore from '@/components/grow/path/activities/GrowPathActivitiesJobActivityReadMore.vue';
    import GrowPathActivitiesJobActivityNewForm from '@/components/grow/path/activities/GrowPathActivitiesJobActivityNewForm.vue';
    import GrowPathActivitiesButtons from '@/components/grow/path/activities/GrowPathActivitiesButtons.vue';
    import GrowPathJobsSelectionJobOptionCompetency from '@/components/grow/path/pathways/GrowPathJobsSelectionJobOptionCompetency.vue';
    import { useGrowJobsStore } from '@/store/grow/jobsStore';
    import GrowJobAndActivitiesGuide from '@/components/grow/GrowJobAndActivitiesGuide.vue';

    const current = ref<PathJobActivity | null>(null);
    const showReadModal = ref(false);

    const jobsStore = useGrowJobsStore();
    const store = useGrowJobActivityStore();
    const navigationStore = useNavigationStore();

    await store.load();

    const isLoadingMoreActivities = ref(false);
    const loadMoreActivities = async () => {
        try {
            isLoadingMoreActivities.value = true;
            await store.loadMore();
        } finally {
            isLoadingMoreActivities.value = false;
        }
    };

    const onReadMore = (item: PathJobActivity) => {
        showReadModal.value = true;
        current.value = item;
    };

    const tryAgain = async () => {
        try {
            isLoadingMoreActivities.value = true;
            await store.load();
        } finally {
            isLoadingMoreActivities.value = false;
        }
    };

    const activities = computed(() => {
        return store.activities;
    });

    const submitTooltip = computed(() => {
        return store.isActivitiesSelectionComplete
            ? ''
            : `You need at least ${MINIMUM_AMOUNT_JOB_ACTIVITIES} activities before continuing...`;
    });

    const onSave = async () => {
        await store.save();

        await navigationStore.setNext();
    };
</script>
