<template>
    <v-card variant="flat" elevation="0">
        <div class="text-heading-h4 font-weight-600">{{ title }}</div>

        <v-progress-linear
            :model-value="competencyPercentage"
            height="6"
            color="secondary-blue"
            class="mt-4"
        />

        <v-card-text class="pa-0 mt-4">
            <v-container v-if="requiredCompetencies.length" class="d-flex ga-2 pa-0 align-center flex-wrap">
                <div class="text-paragraph-sm font-weight-600">{{ requiredTitle }}:</div>
                <v-chip
                    width="fit-content"
                    v-for="(competency, index) in requiredCompetencies"
                    variant="flat"
                    class="bg-secondary-blue-light rounded-4"
                >
                    {{ competency.competency }}
                </v-chip>
            </v-container>
        </v-card-text>

        <v-card-text class="pa-0 mt-4">
            <v-container v-if="possessedCompetencies.length" class="d-flex ga-2 pa-0 align-center">
                <div class="text-paragraph-sm font-weight-600">{{ possessedTitle }}:</div>
                <StorySectionActivityInlineList
                    :items="possessedCompetencies.map((c) => c.competency)"
                />
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import type { ApiJobOption, ApiJobOptionCompetencyType } from '@/api/types/grow/jobs';
    import StorySectionActivityInlineList from '@/components/story/section/activity/StorySectionActivityInlineList.vue';

    const props = defineProps<{
        type: ApiJobOptionCompetencyType;
        value: ApiJobOption;
    }>();

    const title = computed(() => {
        switch (props.type) {
            case 'skill':
                return 'Skills';
            case 'work_experience':
                return 'Work Experience';
            default:
                break;
        }
    });
    const requiredTitle = computed(() => {
        switch (props.type) {
            case 'skill':
                return 'Required skills';
            case 'work_experience':
                return 'Required work experience';
            default:
                break;
        }
    });
    const requiredCompetencies = computed(() =>
        props.value.competencies.filter((c) => c.type === props.type && c.match_type === 'needed'),
    );

    const possessedTitle = computed(() => {
        switch (props.type) {
            case 'skill':
                return 'Skills I already have';
            case 'work_experience':
                return 'Experiences I already have';
            default:
                break;
        }
    });

    const possessedCompetencies = computed(() =>
        props.value.competencies.filter(
            (c) => c.type === props.type && c.match_type === 'possessed',
        ),
    );

    const competencyPercentage = computed(() => {
        const possessed = possessedCompetencies.value.length;
        const total = requiredCompetencies.value.length + possessed;
        return (possessed / total) * 100;
    });
</script>
