import type { DataState } from '@/store/common/dataState';
import type { ApiGrowPredefinedItem } from '@/api/types/grow/lifeVision';
import {
    type ApiGrowPredefinedPreferenceItem,
    type ApiGrowPredefinedPreferenceType,
    type ApiGrowPreference,
    type ApiPreferenceCategory,
    GrowPreferencePreferenceTypeId,
} from '@/api/types/grow/preferences';
import type { ApiPathOption } from '@/api/types/grow/pathways';
import type {
    ApiFullJobOption,
    ApiFullPathOption,
    ApiJobActivity,
    ApiJobActivityCreateData,
    ApiJobOption,
    JobActivityCategory,
} from '@/api/types/grow/jobs';

export type FullPathOption = ApiFullPathOption;
export type FullJobOption = ApiFullJobOption;
export type PathJobCategory = {
    title: JobActivityCategory;
    activities: PathJobActivity[];
};
export type GrowPathStoreState = {
    paths: FullPathOption[];
    path: FullPathOption | null;
};

export type CanvasGrowLifeVision = {
    id?: number;

    timeframe: string;
    location: string;
    passions: string;
    way_of_working: string;
    what_i_imagine: string;
};

export type LifeVisionStoreState = {
    options: ApiGrowPredefinedItem[];
    original: CanvasGrowLifeVision | null;
    value: CanvasGrowLifeVision | null;

    /**
     * Whether the statement view is active.
     */
    statementView: boolean;

    state: DataState;
    error: any | null;
};

export type CanvasGrowPreference = ApiGrowPreference;
export type SelectableGrowPreferenceCategory = NonNullable<ApiPreferenceCategory>;
export type GrowPredefinedPreferenceType = ApiGrowPredefinedPreferenceType;
export type CanvasGrowPreferenceWithCategory = CanvasGrowPreference & {
    category: NonNullable<ApiPreferenceCategory>;
};
export type GrowPredefinedPreferenceItem = ApiGrowPredefinedPreferenceItem;

export type CanvasGrowPreferenceType = ApiGrowPredefinedPreferenceType & {
    icon: string;
    iconColor: string;
    color: string;
    type: GrowPreferencePreferenceTypeId;
};

export type PreferencesStoreState = {
    predefinedOptions: CanvasGrowPreferenceType[];
    original: CanvasGrowPreference[];
    value: CanvasGrowPreference[];
    carouselItems: CanvasGrowPreference[];
    currentIndex: number;

    niceList: CanvasGrowPreference[];
    needList: CanvasGrowPreference[];
    bonusList: CanvasGrowPreference[];

    /**
     * Whether the statement view is active.
     */
    prioritiseSelectionView: boolean;

    state: DataState;
    error: any | null;
};

export type PathOption = ApiPathOption & {
    icon: string;
};

export type PathJobOption = ApiJobOption;

export type PathwaysStoreState = {
    pathOptions: PathOption[];
    path: PathOption | null;
    _timeoutError: boolean;
    _abortLoadingPaths: AbortController | null;
    _needsUpdate: boolean;
};

export type JobsStoreState = {
    pathJobOptions: PathJobOption[];
    job: PathJobOption | null;
    isLoadingJobs: boolean;
    _timeoutError: boolean;
    _abortLoadingJobs: AbortController | null;
};

export type JobActivityStoreState = {
    current: JobActivityCreateData | null;
    _activities: PathJobActivity[];
    _selectedActivities: PathJobActivity[];
    _timeoutError: boolean;
    _abortActivities: AbortController | null;
};

export enum JobActivityProgressState {
    New = 'new',
    InProgress = 'in-progress',
    Completed = 'completed',
}

export type ApiJobActivityState = 'suggested' | 'accepted' | 'denied';

export type PathJobActivity = ApiJobActivity;
export type PathAcceptedJobActivity = PathJobActivity & {
    state: 'accepted';
    order: number;
};

export type JobActivityCreateData = ApiJobActivityCreateData;
