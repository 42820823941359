import { type ApiResponse, get, post, put } from '@/services/apiClient';
import type {
    ApiGrowLifeVision,
    ApiGrowLifeVisionCreateOrUpdate,
    ApiGrowPredefinedItem
} from '@/api/types/grow/lifeVision';

export async function getPredefinedLifeVisionItems(
    accessToken: string,
): Promise<ApiResponse<ApiGrowPredefinedItem[]>> {
    const request = get<ApiGrowPredefinedItem[]>(`grow/predefined-life-vision-items/`);
    return request(accessToken);
}

export async function getLifeVision(
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<ApiGrowLifeVision>> {
    const request = get<ApiGrowLifeVision>(`canvas/${canvasId}/grow/life-vision/`);
    return request(accessToken);
}

export async function putLifeVision(
    canvasId: number,
    lifeVisionId: number,
    updateData: ApiGrowLifeVisionCreateOrUpdate,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<ApiGrowLifeVisionCreateOrUpdate, void>(
        `canvas/${canvasId}/grow/life-vision/${lifeVisionId}`,
    );

    return request(accessToken, updateData);
}

export async function postLifeVision(
    canvasId: number,
    updateData: ApiGrowLifeVisionCreateOrUpdate,
    accessToken: string,
): Promise<ApiResponse<ApiGrowLifeVision>> {
    const request = post<ApiGrowLifeVisionCreateOrUpdate, ApiGrowLifeVision>(
        `canvas/${canvasId}/grow/life-vision`,
    );

    return request(accessToken, updateData);
}
