<template>
    <GrowPathPreferenceCategorySelectionCard
        class="carousel-card"
        :value="value"
        :preference-type="preferenceType ?? {}"
        :width="props.width"
        :min-width="props.width"
        :height="props.height"
    />
</template>
<script setup lang="ts">
    import GrowPathPreferenceCategorySelectionCard from '@/components/grow/path/preferences/GrowPathPreferenceCategorySelectionCard.vue';
    import type { ApiGrowPreference } from '@/api/types/grow/preferences';
    import { useGrowPreferencesStore } from '@/store/grow/preferencesStore';
    import { computed } from 'vue';

    const props = defineProps<{
        value: ApiGrowPreference;
        removable?: boolean;
        width?: string | number;
        height?: number;
        hideDescription?: boolean;
    }>();

    const store = useGrowPreferencesStore();

    const preferenceType = computed(() => {
        return store.predefinedOptions.find((options) => {
            // Comparing using lower-case just in case, as this is not an enum in the DB
            return options.type.toLowerCase() === props.value.type.toLowerCase();
        })!;
    });
</script>
