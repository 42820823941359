import type { ISO8601 } from '@/lib/dates';
import {
    type ApiJobActivityState,
    type JobActivityProgressState,
    type PathAcceptedJobActivity,
} from '@/store/grow/types';
import type { ApiPathOption } from '@/api/types/grow/pathways';

export type ApiJobOptionState = 'suggested' | 'accepted' | 'denied';

export type ApiJobOptionCompetencyType = 'skill' | 'work_experience';
export type ApiJobOptionCompetencyMatchType = 'possessed' | 'needed';

export type ApiJobOptionCompetency = {
    id: number;

    type: ApiJobOptionCompetencyType;
    match_type: ApiJobOptionCompetencyMatchType;
    competency: string;
};

export type ApiJobOption = {
    id: number;

    title: string;
    summary: string;
    fit_to_person: string;
    fit_to_person_pct: number;

    state: ApiJobOptionState;
    competencies: ApiJobOptionCompetency[];

    created_at: ISO8601;
    updated_at: ISO8601;
};

export enum JobActivityCategory {
    Research = 'Research',
    Skills = 'Skills',
    Experience = 'Experience',
    Connections = 'Connections',
}

export type JobActivityFrequency = 'daily' | 'weekly' | 'monthly' | 'yearly';

export type ApiJobActivityBase = {
    title: string;
    description: string;
    long_description: string;
    links?: string;
    category: JobActivityCategory;
    frequency: JobActivityFrequency;
};

export type ApiJobActivity = ApiJobActivityBase & {
    id: number;
    job_option_id: number;
    order?: number | null;

    // Note: The phase is not used in the client for now
    phase: string | null;

    due_at: ISO8601;
    progress_state: JobActivityProgressState;
    state: ApiJobActivityState;

    created_at: ISO8601;
    updated_at: ISO8601;
};

export type ApiJobActivityCreateData = ApiJobActivityBase & {
    links: string | null;
    order: null;
};

export type ApiJobActivityUpdateData = ApiJobActivityBase & {
    state: ApiJobActivityState;
    progress_state: JobActivityProgressState;
    // Note: The phase is not used in the client for now
    phase: string | null;
    order: number | null;
    due_at: ISO8601;
};

export type ApiBulkUpdateJobActivity = ApiJobActivity;
export type ApiBulkUpdateAcceptedJobActivity = PathAcceptedJobActivity;

export type ApiFullJobOption = ApiJobOption & { activities: ApiJobActivity[] };
export type ApiFullPathOption = ApiPathOption & {
    job_options: ApiFullJobOption[];
};
