<template>
    <GrowIntroGuide />

    <v-container class="d-flex flex-column pa-0 ga-16">
        <GrowHomeHeading @click="scrollToRef" />

        <GrowHomeVision v-if="hasUvp" />

        <GrowHomePreferences v-if="hasUvp" />

        <GrowHomePathways v-if="store.acceptedPaths.length > 0" />

        <GrowHomeJobs v-if="store.currentAcceptedJobs.length > 0" />

        <!--
            Use a simple div instead of attaching the ref to the v-row,
            so scrolling works on the HTMLElement. Otherwise $el has to be used
        -->
        <div ref="about">
            <v-row class="pa-0 w-100 mb-16" no-gutters>
                <GrowHomeAbout @click="onGetStartedClick" />
            </v-row>
        </div>
    </v-container>
</template>

<script setup lang="ts">
    import { onBeforeUnmount } from 'vue';

    import { useCanvasStore } from '@/store/canvas/store';
    import { UserActivityArea } from '@/api/types/userActivity';
    import { useActivityStore } from '@/store/activity/store';
    import { useRouter } from 'vue-router';
    import GrowHomeAbout from '@/components/grow/home/GrowHomeAbout.vue';
    import GrowHomeHeading from '@/components/grow/home/GrowHomeHeading.vue';
    import GrowIntroGuide from '@/components/grow/GrowIntroGuide.vue';
    import { growPathRoute } from '@/router/routes';
    import GrowHomeVision from '@/components/grow/home/GrowHomeVision.vue';
    import GrowHomePreferences from '@/components/grow/home/GrowHomePreferences.vue';
    import { useGrowPathStore } from '@/store/grow/store';
    import { useScrollToRef } from '@/composables/useScrollToRef';
    import GrowHomePathways from '@/components/grow/home/GrowHomePathways.vue';
    import GrowHomeJobs from '@/components/grow/home/GrowHomeJobs.vue';
    import { useStoryStore } from '@/store/story/store';
    import { computed } from 'vue';

    const { refElement: about, scrollToRef } = useScrollToRef();

    const router = useRouter();
    const store = useGrowPathStore();
    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();

    await canvasStore.load();
    await store.load();

    await activityStore.addEntryActivity(UserActivityArea.PlanFuture);
    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.PlanFuture);
    });

    const onGetStartedClick = async () => {
        await router.push(growPathRoute);
    };

    const storyStore = useStoryStore();
    await storyStore.load();
    const hasUvp = computed(() => storyStore.isUvpCompleted);
</script>
