import { type ApiResponse, get, post, put } from '@/services/apiClient';
import type {
    ApiBulkUpdateJobActivity,
    ApiJobActivity,
    ApiJobActivityCreateData,
    ApiJobActivityUpdateData,
} from '@/api/types/grow/jobs';

export async function postGenerateJobOptionActivity(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = post<void, void>(
        `canvas/${canvasId}/grow/path/${growPathOptionId}/job/${jobOptionId}/activities/generate`,
    );
    return request(accessToken);
}

export async function getJobOptionActivities(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    accessToken: string,
): Promise<ApiResponse<ApiJobActivity[]>> {
    const request = get<ApiJobActivity[]>(
        `canvas/${canvasId}/grow/path/${growPathOptionId}/job/${jobOptionId}/activities/`,
    );
    return request(accessToken);
}

export async function postCreateJobOptionActivity(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    accessToken: string,
    activity: ApiJobActivityCreateData,
): Promise<ApiResponse<ApiJobActivity>> {
    const request = post<ApiJobActivityCreateData, ApiJobActivity>(
        `canvas/${canvasId}/grow/path/${growPathOptionId}/job/${jobOptionId}/activities/`,
    );
    return request(accessToken, activity);
}

export async function putUpdateJobOptionActivity(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    activityId: number,
    accessToken: string,
    updatedActivity: ApiJobActivityUpdateData,
): Promise<ApiResponse<void>> {
    const request = put<ApiJobActivityUpdateData, void>(
        `canvas/${canvasId}/grow/path/${growPathOptionId}/job/${jobOptionId}/activities/${activityId}`,
    );
    return request(accessToken, updatedActivity);
}

export async function putBulkUpdateJobOptionActivities(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    accessToken: string,
    updateActivities: ApiBulkUpdateJobActivity[],
): Promise<ApiResponse<void>> {
    const request = put<ApiBulkUpdateJobActivity[], void>(
        `canvas/${canvasId}/grow/path/${growPathOptionId}/job/${jobOptionId}/activities/bulk-update`,
    );
    return request(accessToken, updateActivities);
}

export async function postRegenerateJobOptionActivity(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = post<void, void>(
        `canvas/${canvasId}/grow/path/${growPathOptionId}/job/${jobOptionId}/activities/regenerate`,
    );
    // TODO: add a method of that indicates how in depth the suggestions should be
    //       e.g.: include personality type
    //       e.g.: include core values
    return request(accessToken);
};
