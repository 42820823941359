<template>
    <ChallengePathGuide />

    <ChallengesHomeHeading @click="scrollToRef" />

    <v-container
        v-if="currentChallengesStore.activeChallenges.length"
        class="pa-0 d-print-none"
        fluid
    >
        <v-row class="pa-0 w-100" no-gutters :class="isDesktop ? 'mb-8' : 'mb-4'">
            <ChallengesHomeChallengesCard />
        </v-row>

        <v-row class="pa-0 w-100" no-gutters>
            <ChallengesHomePathwaysCard />
        </v-row>
    </v-container>

    <!--
        Use a simple div instead of attaching the ref to the v-row,
        so scrolling works on the HTMLElement. Otherwise $el has to be used
    -->
    <div ref="about">
        <v-row class="pa-0 w-100 mb-16" no-gutters>
            <ChallengesHomeAbout @click="onGetStartedClick" />
        </v-row>
    </div>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, ref } from 'vue';

    import { useCanvasStore } from '@/store/canvas/store';
    import { useChallengePathStore } from '@/store/challenge-path/challengePathStore';

    import { UserActivityArea } from '@/api/types/userActivity';
    import { useActivityStore } from '@/store/activity/store';
    import ChallengePathGuide from '@/components/pathway/ChallengePathGuide.vue';
    import ChallengesHomeHeading from '@/components/pathway/home/ChallengesHomeHeading.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import ChallengesHomeChallengesCard from '@/components/pathway/home/ChallengesHomeChallengesCard.vue';
    import ChallengesHomePathwaysCard from '@/components/pathway/home/ChallengesHomePathwaysCard.vue';
    import { useCurrentChallengeStore } from '@/store/current-challenges/store';
    import ChallengesHomeAbout from '@/components/pathway/home/ChallengesHomeAbout.vue';
    import { useRouter } from 'vue-router';
    import { challengePathwaysRoute, challengeActivityRoute } from '@/router/routes';
    import { useScrollToRef } from '@/composables/useScrollToRef';

    const { refElement: about, scrollToRef } = useScrollToRef();

    const { isDesktop } = useIsDesktop();

    const store = useChallengePathStore();
    const currentChallengesStore = useCurrentChallengeStore();
    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();

    await canvasStore.load();
    await currentChallengesStore.load();
    await store.load();

    await activityStore.addEntryActivity(UserActivityArea.ChallengePath);

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.ChallengePath);
    });

    const router = useRouter();
    const onGetStartedClick = async () => {
        if (!currentChallengesStore.activeChallenges.length) {
            await router.push(challengeActivityRoute);
            return;
        }

        await router.push(challengePathwaysRoute);
    };
</script>
