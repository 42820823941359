import { createInBackground, fetch, update } from '@/services/base';
import {
    getJobOptions,
    postGenerateJobOptions,
    putAcceptJobOption,
} from '@/services/grow/jobs/api';
import type { ApiJobOption } from '@/api/types/grow/jobs';
import type { ApiResponse } from '@/services/apiClient';

export async function generateJobOptions(
    canvasId: number,
    growPathOptionId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    return await createInBackground(
        'generate-job-options',
        async () => await postGenerateJobOptions(canvasId, growPathOptionId, accessToken),
    );
}

export async function fetchJobOptions(
    canvasId: number,
    growPathOptionId: number,
    accessToken: string,
): Promise<ApiJobOption[]> {
    return fetch(
        'job-options',
        async () => await getJobOptions(canvasId, growPathOptionId, accessToken),
    );
}

export async function acceptJobOption(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    accessToken: string,
): Promise<void> {
    return update(
        'accept-job-option',
        async () => await putAcceptJobOption(canvasId, growPathOptionId, jobOptionId, accessToken),
    );
}
