<template>
    <app-page-content>
        <template v-slot:intro>
            <app-page-content-intro title="Now let’s prioritise">
                <v-container class="pa-0 d-flex flex-column ga-5">
                    <div class="text-paragraph-md">
                        Using what you defined as your main preferences, now you need to select
                        the most important. As we want to make sure you get the right pathway and
                        job match for you. Eliminate your options down to your top 10, by placing
                        the things that matter most into these 3 categories to determine your
                        hierarchy.
                    </div>

                    <div v-for="(category, index) in categoryValues">
                        <div class="text-heading-md font-weight-600">
                            {{ category.title }} ({{ category.maxAmount }})
                        </div>
                        <div class="text-paragraph-sm">
                            {{ category.description }}
                        </div>
                    </div>

                    <GrowPathUpdateMessage />
                </v-container>
            </app-page-content-intro>
        </template>

        <v-container class="px-0" v-if="store.carouselItems.length">
            <v-row no-gutters justify="space-around">
                <v-col>
                    <GrowPathPriorityActivityCarousel />
                </v-col>
            </v-row>
        </v-container>

        <v-divider class="my-12" v-if="store.carouselItems.length" />

        <GrowPathPriorityActivitySelection />
    </app-page-content>
</template>
<script setup lang="ts">
    import GrowPathPriorityActivityCarousel from '@/components/grow/path/preferences/GrowPathPriorityActivityCarousel.vue';
    import GrowPathPriorityActivitySelection from '@/components/grow/path/preferences/GrowPathPriorityActivitySelection.vue';
    import { categoryValues } from '@/components/grow/path/preferences/utils';
    import { useGrowPreferencesStore } from '@/store/grow/preferencesStore';
    import GrowPathUpdateMessage from '@/components/grow/common/GrowPathUpdateMessage.vue';

    const store = useGrowPreferencesStore();
</script>
