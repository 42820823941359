<template>
    <app-page-content v-if="store.statementView" :fill-height="true">
        <div class="statement-container-wrapper fill-height">
            <div
                class="statement-container text-center pa-0 font-italic"
                :class="isDesktop ? 'ga-10' : 'ga-5'"
            >
                <div class="text-caption-subtitle">your dream life vision</div>
                <GrowPathVisionStatement
                    v-if="lifeVision"
                    :value="lifeVision"
                    :highlight-color="colors['navigation-outline']"
                    class="text-misc-sentence-builder"
                />
                <GrowPathVisionStatementEditOption @edit="statementView = false" />
            </div>
        </div>
    </app-page-content>
</template>

<script setup lang="ts">
    import { useLifeVisionStore } from '@/store/grow/lifeVisionStore';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import GrowPathVisionStatement from '@/components/grow/path/vision/GrowPathVisionStatement.vue';
    import GrowPathVisionStatementEditOption from '@/components/grow/path/vision/GrowPathVisionStatementEditOption.vue';
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import { useTheme } from 'vuetify';

    const theme = useTheme()
    const colors = theme.current.value.colors;
    const store = useLifeVisionStore();

    const { isDesktop } = useIsDesktop();

    const { statementView } = storeToRefs(store);

    const lifeVision = computed(() => {
        return store.value;
    });
</script>

<style scoped lang="scss">
    .statement-container-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;
    }

    .statement-container {
        display: flex;
        max-width: 1200px;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        align-self: stretch;
    }
</style>
