import { GrowPreferencePreferenceTypeId } from '@/api/types/grow/preferences';

export const PREDEFINED_PREFERENCE_TYPE_DEFINITION = {
    [GrowPreferencePreferenceTypeId.Culture]: {
        icon: 'users',
        iconColor: 'text-secondary-purple',
        color: 'secondary-pink-light',
        type: GrowPreferencePreferenceTypeId.Culture,
    },
    [GrowPreferencePreferenceTypeId.Industry]: {
        icon: 'building',
        iconColor: 'text-story-button',
        color: 'yellow-light',
        type: GrowPreferencePreferenceTypeId.Industry,
    },
    [GrowPreferencePreferenceTypeId.Outcomes]: {
        icon: 'home',
        iconColor: 'text-secondary-red',
        color: 'secondary-red-light',
        type: GrowPreferencePreferenceTypeId.Outcomes,
    },
    [GrowPreferencePreferenceTypeId.Place]: {
        icon: 'check',
        iconColor: 'text-secondary-blue',
        color: 'secondary-blue-light',
        type: GrowPreferencePreferenceTypeId.Place,
    },
    [GrowPreferencePreferenceTypeId.Activities]: {
        icon: 'map',
        iconColor: 'text-secondary-pink',
        color: 'secondary-pink-light',
        type: GrowPreferencePreferenceTypeId.Activities,
    },
    [GrowPreferencePreferenceTypeId.People]: {
        icon: 'users',
        iconColor: 'text-story-button',
        color: 'yellow-light',
        type: GrowPreferencePreferenceTypeId.People,
    },
    [GrowPreferencePreferenceTypeId.Money]: {
        icon: 'coins',
        iconColor: 'text-secondary-yellow',
        color: 'secondary-blue-light',
        type: GrowPreferencePreferenceTypeId.Money,
    },
    [GrowPreferencePreferenceTypeId.Causes]: {
        icon: 'hand',
        iconColor: 'text-secondary-red',
        color: 'secondary-red-light',
        type: GrowPreferencePreferenceTypeId.Causes,
    },
} as const;
