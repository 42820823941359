<template>
    <v-card
        elevation="0"
        min-width="fit-content"
        variant="flat"
        class="category-selection px-5 py-4 rounded-8"
        :color="preferenceType.color"
        :class="`category-selection-${name} ${formattedCategory}`"
    >
        <v-card-item class="pa-0 text-capitalize text-heading-h4">
            <template v-slot:prepend>
                <v-icon class="cursor-pointer preference-card-handle">mdi-drag</v-icon>
                <app-fa-icon
                    class="ml-2"
                    :icon="preferenceType.icon"
                    :class="preferenceType.iconColor"
                    size="1x"
                />
            </template>
            <div class="text-heading-h5 px-1">
                {{ preference.title }}
            </div>
            <template v-slot:append>
                <v-btn
                    class="opacity-1 fill-height pa-1"
                    v-if="showRemove"
                    variant="plain"
                    @click.capture.stop.prevent="onRemoveItem"
                >
                    <v-icon size="x-large" color="custom-neutral-black">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-card-item>
    </v-card>
</template>
<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { useDisplay } from 'vuetify';
    import type {
        CanvasGrowPreference,
        SelectableGrowPreferenceCategory,
    } from '@/store/grow/types';
    import { useGrowPreferencesStore } from '@/store/grow/preferencesStore';

    const { name } = useDisplay();
    const showRemove = ref(true);

    const props = defineProps<{
        preference: CanvasGrowPreference;
        category: SelectableGrowPreferenceCategory;
    }>();

    const store = useGrowPreferencesStore();

    const onRemoveItem = () => {
        store.onCategoryRemoved(props.preference);
    };

    const formattedCategory = computed(() => {
        return props.preference.category;
    });

    const preferenceType = computed(() => {
        return store.predefinedOptions.find((options) => {
            // Comparing using lower-case just in case, as this is not an enum in the DB
            return options.type.toLowerCase() === props.preference.type.toLowerCase();
        })!;
    });
</script>

<style scoped lang="scss">
    .category-selection {
        :deep(.v-card-item__append) {
            padding: 0;
            width: 44px;
            right: -4px;
            top: 18px;
        }
    }
</style>
