<template>
    <div class="d-block position-relative">
        <span v-if="isDesktop" class="home-statement-card-background-image-1-desktop d-print-none">
            <HomeStatementCardImage1 :color="color" />
        </span>
        <span v-if="isDesktop" class="home-statement-card-background-image-2-desktop d-print-none">
            <HomeStatementCardImage2 :color="color" />
        </span>

        <StoryCard
            id="home-statement"
            class="home-statement-card"
            :min-height="hasStatement ? 200 : 160"
        >
            <template #title> {{ title }}</template>

            <template #description>
                {{ description }}
            </template>

            <template #content>
                <slot />
            </template>
        </StoryCard>
    </div>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import HomeStatementCardImage1 from '@/components/common/home/statement/HomeStatementCardImage1.vue';
    import HomeStatementCardImage2 from '@/components/common/home/statement/HomeStatementCardImage2.vue';
    import StoryCard from '@/components/story/section/StoryCard.vue';

    const { isDesktop } = useIsDesktop();

    defineProps<{
        color: string;
        hasStatement: boolean;
        title: string;
        description: string;
    }>();
</script>

<style lang="scss">
    .home-statement-card {
        overflow: visible !important;
    }

    .home-statement-card-background-image-1-desktop {
        position: absolute;
        top: -30px;
        left: -30px;
    }

    .home-statement-card-background-image-2-desktop {
        position: absolute;
        bottom: -52px;
        right: -30px;
    }
</style>
