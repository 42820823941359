<template>
    <app-button
        v-if="isSelected"
        @click="emit('onSelected')"
        prepend-icon="mdi-check"
        color="primary"
        variant="flat"
    >
        {{ text }}
    </app-button>
    <app-button
        v-else
        @click="emit('onSelected')"
        prepend-icon="mdi-circle-outline"
        variant="outlined"
    >
        {{ text }}
    </app-button>
</template>
<script setup lang="ts">
    defineProps<{
        text: string;
        isSelected: boolean;
    }>();

    const emit = defineEmits(['onSelected']);
</script>
