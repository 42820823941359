<template>
    <StoryCard color="primary-light">
        <template #title>Job & Activities</template>

        <template #description>
            Within the Pathway you choose, there are lots of jobs you could do. We recommend the
            ones we think most suit your skills, experience, and needs. We will then help you
            transition into this job by recommending learning activities to prepare you.
        </template>

        <template #content>
            <v-container class="pa-0 bg-primary-light" v-if="notStartedJobs.length">
                <div class="text-heading-h3">Not started</div>
                <v-card variant="flat" class="pa-0" color="primary-light">
                    <v-card-text class="pa-0 mt-5">
                        <GrowHomeJobListItem
                            v-for="(item, index) in notStartedJobs"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            @start="onStartClick(item)"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>

            <v-container class="pa-0 mt-8 bg-primary-light" v-if="inProgressJobs.length">
                <div class="text-heading-h3">In progress</div>
                <v-card variant="flat" class="pa-0" color="primary-light">
                    <v-card-text class="pa-0 mt-5">
                        <GrowHomeJobListItem
                            v-for="(item, index) in inProgressJobs"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            @update="onUpdateInProgressClick(item)"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>

            <v-container class="pa-0 mt-8 bg-primary-light" v-if="completedJobs.length">
                <div class="text-heading-h3">Completed</div>
                <v-card variant="flat" class="pa-0" color="primary-light">
                    <v-card-text class="pa-0 mt-5">
                        <GrowHomeJobListItem
                            v-for="(item, index) in completedJobs"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>
        </template>
    </StoryCard>
</template>

<script setup lang="ts">
    import StoryCard from '@/components/story/section/StoryCard.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useGrowPathStore } from '@/store/grow/store';
    import type { FullJobOption } from '@/store/grow/types';
    import { growActivitiesRoute, growProgressRoute, type DeclaredRoute } from '@/router/routes';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import { useRouter } from 'vue-router';
    import GrowHomeJobListItem from '@/components/grow/home/GrowHomeJobListItem.vue';

    const store = useGrowPathStore();

    const { isDesktop } = useIsDesktop();

    const getJobsByState = () => {
        const notStartedJobs: FullJobOption[] = [];
        const inProgressJobs: FullJobOption[] = [];
        const completedJobs: FullJobOption[] = [];

        // TODO: CORE-265 - this is a temporary solution to get the jobs by state
        store.acceptedPaths.forEach((path) => {
            path.job_options.forEach((job) => {
                if (job.state === 'accepted') {
                    const acceptedActivities = job.activities.filter(activity => activity.state === 'accepted');
                    if (acceptedActivities.length > 0) {
                        if (acceptedActivities.every(activity => activity.progress_state === 'completed')) {
                            completedJobs.push(job);
                        } else {
                            inProgressJobs.push(job);
                        }
                    } else {
                        notStartedJobs.push(job);
                    }
                }
            });
        });

        return { notStartedJobs, inProgressJobs, completedJobs };
    };

    const { notStartedJobs, inProgressJobs, completedJobs } = getJobsByState();

    const navigationStore = useNavigationStore();

    const router = useRouter();

    const onStartClick = (item: FullJobOption) => {
        onStartOrUpdateInProgressClick(item, growActivitiesRoute);
    };

    const onUpdateInProgressClick = (item: FullJobOption) => {
        onStartOrUpdateInProgressClick(item, growProgressRoute);
    };

    const onStartOrUpdateInProgressClick = (item: FullJobOption, route: DeclaredRoute) => {
        // Navigating to the progress tab of the job option with a job option in context
        // Order matters here, first set the context then navigate to the progress tab
        //
        // Code smell: This should be only one action.
        // The path should include maybe the challenge in context in the query params or in a path param?
        navigationStore.setContext({ item });
        router.push({ ...route });
    };
</script>
