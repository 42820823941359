import { defineStore } from 'pinia';
import { verify } from '@/store/verify';
import { useCanvasStore } from '@/store/canvas/store';
import { sendEvent, useGuideWebsocket } from '@/services/guide/guideClient';
import {
    GuideDialogState,
    type GuideEvent,
    type GuideMessage,
    type GuideMessageEvent,
    GuideMessagePriority,
    type GuideStoreState,
    GuideSupportedScreen,
    WebsocketStatus,
} from '@/store/guide/types';
import { usePersonalValuesStore } from '@/store/personal-values/store';
import { filterMessages, getMessageEvent } from '@/store/guide/util';
import { PersonalValuesEventFactory } from '@/store/guide/event-factory/personalValuesEventFactory';
import { CurrentChallengesEventFactory } from '@/store/guide/event-factory/currentChallengesEventFactory';
import { useCurrentChallengeStore } from '@/store/current-challenges/store';
import { useCurrentPlanStore } from '@/store/plan/current/store';
import { useCareerPlanStore } from '@/store/plan/career/store';
import type { PersonalValue } from '@/api/types/personalValue';
import type { PersonalValueSelectionType } from '@/api/types/canvas/personalValue';
import { PersonalValueSelectionSubType } from '@/api/types/canvas/personalValue';
import { SkillsEventFactory } from '@/store/guide/event-factory/skillsEventFactory';
import { useSkillsStore } from '@/store/skills/store';
import { FutureAspirationsEventFactory } from '@/store/guide/event-factory/futureAspirationsEventFactory';
import { GrowPathPathwaysEventFactory } from '@/store/guide/event-factory/growPathPathwaysEventFactory';
import { CurrentPlanEventFactory } from '@/store/guide/event-factory/currentPlanEventFactory';
import { FuturePlanEventFactory } from '@/store/guide/event-factory/futurePlanEventFactory';
import { LearnedExperiencesEventFactory } from '@/store/guide/event-factory/learnedExperiencesEventFactory';
import { ChallengePathEventFactory } from '@/store/guide/event-factory/challengePathEventFactory';
import { type IntroStoreState } from '@/store/home/types';
import { useFutureAspirationsStore } from '@/store/future-aspirations/store';
import { useUsersStore } from '@/store/user/store';
import { last } from 'lodash';
import {
    addGuideMessagedActivity,
    addGuideOpenedActivity,
    getUserActivityAreaFromScreen,
} from '@/services/activity/service';
import { UserActivityEvent } from '@/api/types/userActivity';
import { IntroEventFactory } from './event-factory/introEventFactory';
import { formatDistanceToNow } from 'date-fns';
import {
    type GetAccessToken,
    getAccessTokenSilentlyOrFail,
    makeAccessTokenState,
    setAccessTokenGenerator,
} from '@/store/common/accessTokenState';
import type { StoryStoreState } from '@/store/story/store';
import { StoryEventFactory } from '@/store/guide/event-factory/storyEventFactory';
import { PersonalStrengthsEventFactory } from '@/store/guide/event-factory/personalStrengthsEventFactory';
import { usePersonalStrengthsStore } from '@/store/personal-strengths/store';
import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
import { useGrowPathStore } from '@/store/grow/store';
import { GrowPathJobsAndActivitiesEventFactory } from './event-factory/growPathJobAndActivitiesEventFactory';
import { GrowPathVisionAndPreferencesEventFactory } from './event-factory/growPathVisionAndPreferencesEventFactory';
import { GrowPathIntroEventFactory } from './event-factory/growPathIntroEventFactory';

export const personalValuesEventFactory = (state: GuideStoreState) => {
    return new PersonalValuesEventFactory(state, usePersonalValuesStore().$state);
};

export const currentChallengesEventFactory = (state: GuideStoreState) => {
    return new CurrentChallengesEventFactory(state, useCurrentChallengeStore().$state);
};

export const skillsEventFactory = (state: GuideStoreState) => {
    return new SkillsEventFactory(state, useSkillsStore().$state);
};

export const personalStrengthsEventFactory = (state: GuideStoreState) => {
    return new PersonalStrengthsEventFactory(state, usePersonalStrengthsStore().$state);
};

export const futureAspirationsEventFactory = (state: GuideStoreState) => {
    return new FutureAspirationsEventFactory(state, useFutureAspirationsStore().$state);
};

export const introEventFactory = (state: GuideStoreState) => {
    return new IntroEventFactory(state, {} as IntroStoreState);
};

function learnedExperiencesEventFactory(state: GuideStoreState) {
    return new LearnedExperiencesEventFactory(state, useLearnedExperiencesStore().$state);
}

export const storyEventFactory = (state: GuideStoreState) => {
    return new StoryEventFactory(state, {} as StoryStoreState);
};

export const currentPlanEventFactory = (state: GuideStoreState) => {
    return new CurrentPlanEventFactory(state, useCurrentPlanStore().$state);
};

export const challengePathEventFactory = (state: GuideStoreState) => {
    return new ChallengePathEventFactory(state, useCurrentPlanStore().$state);
};

export const futurePlanEventFactory = (state: GuideStoreState) => {
    return new FuturePlanEventFactory(state, useCareerPlanStore().$state);
};

export const growPathIntroEventFactory = (state: GuideStoreState) => {
    return new GrowPathIntroEventFactory(state, useGrowPathStore().$state);
};

export const growPathVisionAndPreferencesEventFactory = (state: GuideStoreState) => {
    return new GrowPathVisionAndPreferencesEventFactory(state, useGrowPathStore().$state);
};

export const growPathPathwaysEventFactory = (state: GuideStoreState) => {
    return new GrowPathPathwaysEventFactory(state, useGrowPathStore().$state);
};

export const growPathJobsAndActivitiesEventFactory = (state: GuideStoreState) => {
    return new GrowPathJobsAndActivitiesEventFactory(state, useGrowPathStore().$state);
};

function makeFactory(state: GuideStoreState, screen: GuideSupportedScreen | null) {
    switch (screen) {
        case GuideSupportedScreen.CurrentChallenges:
            return currentChallengesEventFactory(state);
        case GuideSupportedScreen.CurrentPlan:
            return currentPlanEventFactory(state);
        case GuideSupportedScreen.FutureAspirations:
            return futureAspirationsEventFactory(state);
        case GuideSupportedScreen.FuturePlan:
            return futurePlanEventFactory(state);
        case GuideSupportedScreen.GrowPathIntro:
            return growPathIntroEventFactory(state);
        case GuideSupportedScreen.GrowPathVisionAndPreferences:
            return growPathVisionAndPreferencesEventFactory(state);
        case GuideSupportedScreen.GrowPathPathways:
            return growPathPathwaysEventFactory(state);
        case GuideSupportedScreen.GrowPathJobsAndActivities:
            return growPathJobsAndActivitiesEventFactory(state);
        case GuideSupportedScreen.Intro:
            return introEventFactory(state);
        case GuideSupportedScreen.LearnedExperiences:
            return learnedExperiencesEventFactory(state);
        case GuideSupportedScreen.PersonalStrengths:
            return personalStrengthsEventFactory(state);
        case GuideSupportedScreen.PersonalValues:
            return personalValuesEventFactory(state);
        case GuideSupportedScreen.Story:
            return storyEventFactory(state);
        case GuideSupportedScreen.Skills:
            return skillsEventFactory(state);
        case GuideSupportedScreen.ChallengePath:
            return challengePathEventFactory(state);
        default:
            throw new Error(`no enter event for screen ${screen}`);
    }
}

export const useGuideStore = defineStore('guide-store', {
    state: (): GuideStoreState => ({
        ws: null,

        messages: [],
        messageIds: new Set<string>(),
        messagesLoaded: false,

        state: GuideDialogState.Closed,
        question: '',
        screen: null,
        ...makeAccessTokenState(),
    }),
    getters: {
        isInitialised(_state): boolean {
            return this.ws !== null;
        },
        isConnectionOpen(state): boolean {
            return state.ws?.status === WebsocketStatus.Open;
        },
        isConnectionClosed(state): boolean {
            return state.ws?.status === WebsocketStatus.Closed;
        },
        isConnecting(state): boolean {
            return state.ws?.status === WebsocketStatus.Connecting;
        },
        isDialogOpen(state): boolean {
            return state.state === GuideDialogState.Open;
        },
        isClosedWithSuggestions(state): boolean {
            return state.state === GuideDialogState.ClosedWithSuggestions;
        },
        /**
         * Retrieves the messages suitable for display in the chat.
         *
         * Note: These messages have been filtered on the server side to remove adjacent
         * duplicates. This ensures a clean chat interface by avoiding redundancy,
         * particularly because the client may have access to messages that are not yet
         * present in the server history.
         */
        displayableMessages(_state): GuideMessage[] {
            const withContent = this.messages.filter((m) => m.content !== undefined);
            return filterMessages(withContent);
        },
        /**
         * @returns if the last user interaction (a question, or a suggestions) has not been responded yet.
         * This will not pay attention to other type of events that have not been driven specifically by the user
         */
        isWaitingForResponse(state): boolean {
            const lastMessage = last(state.messages);
            const user = verify(useUsersStore().user, 'No user');
            if (lastMessage) {
                return lastMessage.senderId === user.id.toString();
            }

            return false;
        },
    },
    actions: {
        async connect(screen: GuideSupportedScreen, getAccessToken: GetAccessToken): Promise<void> {
            // Only stop the connection if we're switching to a different screen
            if (this.screen !== screen) {
                this._stop();
            }

            this.$reset();

            this._setScreen(screen);
            setAccessTokenGenerator(this, getAccessToken);

            console.log(`Guide store in screen '${screen}' connecting ...`);

            const onConnected = () => {
                console.log('WS opened...');
            };

            const onDisconnected = (ws: WebSocket, event: CloseEvent): void => {
                console.log('WS disconnected...');
            };

            const onError = (ws: WebSocket, event: Event): void => {
                console.log('WS on error...');
            };

            const onFailed = (): void => {
                console.log('WS on failed after retries...');
            };

            const onMessage = (ws: WebSocket, event: MessageEvent): void => {
                const data = event.data;
                console.debug('WS Message received', data);
                if (data) {
                    if (data === 'pong') {
                        console.debug('WS still alive...');
                        return;
                    }

                    const eventShowMessage = getMessageEvent(data);
                    if (eventShowMessage) {
                        this._addMessage(eventShowMessage);
                        this.messagesLoaded = true;
                    }
                }
                console.log('WS on message...');
            };

            const canvasId = verify(useCanvasStore().canvas?.id, 'No canvas id');

            const accessToken = await getAccessTokenSilentlyOrFail(this);
            // @ts-ignore not sure how to fix this for now
            this.ws = useGuideWebsocket(screen, canvasId, accessToken, {
                onConnected,
                onDisconnected,
                onError,
                onMessage,
                onFailed,
            });
        },
        showDialog(): void {
            this._setState(GuideDialogState.Open);
        },
        hideDialog(): void {
            this._setState(GuideDialogState.Closed);
        },
        setStateBasedOnPriority(priority: GuideMessagePriority): void {
            if (this.isDialogOpen) {
                // nothing to do as dialog is already opened
                return;
            }

            // TODO: review messages priority
            // At the moment to test the guide animation every message while the chat is closed
            // will set the guide in 'talk to me' state
            // if (priority <= GuideMessagePriority.Normal) {
            //     // nothing to do at this state
            //     return;
            // } else if (priority === GuideMessagePriority.High) {
            //     this._setState(GuideDialogState.ClosedWithSuggestions);
            // } else if (priority > GuideMessagePriority.High) {
            //     this.showDialog();
            // }
            this._setState(GuideDialogState.ClosedWithSuggestions);
        },
        loadChat({ options = {} }: { options: { reset?: boolean } }): void {
            // HACK
            // A) It seems vue-advanced-chat needs this variable to be toggled when opened again.
            // Our chat is inside v-dialog, so it is mounted everytime the dialog is opened
            // This makes <vue-advanced-chat> to be mounted again and does not work as expected
            // For some reason toggling this variable makes it works as expected.
            //
            // To understand this behaviour:
            // 1. Comment the following code executed in the setTimeout and the chat will not load
            // 2. Open the guide and close it a few times, and at some point will not load
            // TODO: Have a better understanding of this hack
            // TODO: Could we prevent the vue-advanced-chat to be mounted again, so we can remove this hack?
            this.messagesLoaded = false;
            setTimeout(() => {
                this.messagesLoaded = true;
            });
        },
        async sendMessage(message: { content: string }): Promise<void> {
            if (!this.isDialogOpen) {
                throw new Error('dialog is not opened');
            }

            this._sendEvent(this._eventFactory().question(message.content));

            this._clearQuestion();

            await this._sendUserActivity(UserActivityEvent.GuideMessaged);
        },
        async onUserEnterScreen(screen: GuideSupportedScreen): Promise<void> {
            console.log(`[guide-store]: onUserEnterScreen \'${screen}\'`);

            this._sendEvent(this._eventFactory().enter());

            await this._sendUserActivity(UserActivityEvent.GuideOpened);
        },
        onUserLeaveScreen(screen: GuideSupportedScreen) {
            console.log(`[guide-store]: onUserLeaveScreen \'${screen}\'`);
            if (!this.isInitialised) return;

            this._sendEvent(this._eventFactory().leave());
        },
        /** @param type the selected value or null whe is unselected */
        onUserPersonalTypeSelection(value: PersonalValue, type: PersonalValueSelectionType | null) {
            this._sendEvent(
                personalValuesEventFactory(this.$state as GuideStoreState).typeSelection(
                    value,
                    type,
                ),
            );
        },
        /** @param subType the selected value or null whe is unselected */
        onUserPersonalSubTypeSelection(
            value: PersonalValue,
            subType: PersonalValueSelectionSubType | null,
        ) {
            this._sendEvent(
                personalValuesEventFactory(this.$state as GuideStoreState).subTypeSelection(
                    value,
                    subType,
                ),
            );
        },
        // ####################################################################
        //
        // Side effects
        //
        //
        _addMessage(messageEvent: GuideMessageEvent): void {
            this.messagesLoaded = false;
            const message = messageEvent.value;
            if (!message) {
                return;
            }

            if (this.messageIds.has(message.id)) {
                return;
            }
            this.messageIds.add(message.id);

            const chatMessage = {
                _id: message.id,
                content: message.content,
                senderId: message.sender_id,
                date: formatDistanceToNow(new Date(message.created_at), {
                    addSuffix: true,
                    includeSeconds: true,
                }),
                timestamp: new Date(message.created_at).toString().substring(16, 21),
                ctx: messageEvent,
                animate: !message.history,
            };
            console.log('New message', chatMessage);

            this.messages = [...this.messages, chatMessage];

            if (message.priority) {
                this.setStateBasedOnPriority(message.priority || GuideMessagePriority.Normal);
            }

            this.messagesLoaded = true; //TODO: WOULD BE GOOD TO UNDERSTAND WHY THIS HACK IS REQUIRED?
        },
        _setState(state: GuideDialogState): void {
            this.state = state;
        },
        _clearQuestion() {
            this.question = '';
        },
        _eventFactory() {
            return makeFactory(this.$state as GuideStoreState, this.screen);
        },
        _setScreen(screen: GuideSupportedScreen | null) {
            this.screen = screen;
        },
        _sendEvent(event: GuideEvent) {
            const websocket = this.ws;

            if (websocket) {
                sendEvent(websocket, event);
            } else {
                // This is likely one the following scenario:
                // 1. The connection has not been established yet, but the user interacted with the guide
                // e.g.: Selected a value
                // What should we do in this case? Maybe if the connection is being established,
                // we can queue the events
                //

                throw new Error(`No websocket when sending event of type ${event.type}`);
            }
        },
        async _sendUserActivity(activity: UserActivityEvent): Promise<void> {
            const user = verify(useUsersStore().user, 'No user');
            const area = getUserActivityAreaFromScreen(this.screen as any);

            const accessToken = await getAccessTokenSilentlyOrFail(this);
            switch (activity) {
                case UserActivityEvent.GuideMessaged:
                    await addGuideMessagedActivity(user.id, area, accessToken);
                    break;
                case UserActivityEvent.GuideOpened:
                    await addGuideOpenedActivity(user.id, area, accessToken);
                    break;
            }
        },
        stop() {
            this._stop();
        },
        _stop() {
            console.log('WS Explicitly calling stop');
            this.ws?.close();
        },
    },
});
