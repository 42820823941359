import { defineStore } from 'pinia';
import { useCanvasStore } from '@/store/canvas/store';
import type { JobsStoreState, PathJobOption } from '@/store/grow/types';
import { useAppErrorStore } from '@/store/appErrorStore';
import { useLoadingStore } from '@/store/loadingStore';
import * as Sentry from '@sentry/vue';
import { verify } from '@/store/verify';
import { useGrowPathwaysStore } from '@/store/grow/pathwaysStore';
import { acceptJobOption, fetchJobOptions, generateJobOptions } from '@/services/grow/jobs/service';
import { pollGrowJobs } from '@/services/grow/jobs/pollGrowJobs';
import type { ApiJobOption } from '@/api/types/grow/jobs';
import { PollEndpointTimeoutError } from '@/services/pollUntil';

export const useGrowJobsStore = defineStore({
    id: 'grow-jobs-store',
    state: (): JobsStoreState => ({
        pathJobOptions: [],
        job: null,
        isLoadingJobs: false,
        _timeoutError: false,
        _abortLoadingJobs: null,
    }),
    getters: {
        isAreaComplete(state): boolean {
            return !!state.job;
        },
    },
    actions: {
        isJobSelected(option: PathJobOption): boolean {
            return this.job?.id === option.id;
        },
        toggleJobSelection(option: PathJobOption): void {
            if (this.isJobSelected(option)) {
                this.job = null;
            } else {
                this.job = option;
            }
        },
        async load(): Promise<void> {
            this.isLoadingJobs = true;
            const abortController = new AbortController();
            this._abortLoadingJobs = abortController;

            const pathwaysStore = useGrowPathwaysStore();
            const growPathOptionId = verify(
                pathwaysStore.path?.id,
                'grow path option id is not set',
            );

            console.info('Loading path jobs options (new)...');
            await useAppErrorStore().catchErrors(async () => {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();
                const jobOptions = await fetchJobOptions(canvasId, growPathOptionId, accessToken);

                if (jobOptions.length === 0) {
                    console.info('Job options not started');

                    useLoadingStore().setLoadingText(
                        'Setting up these jobs for you.',
                        'Please stay on this page. This can take up to a minute...',
                    );

                    await generateJobOptions(canvasId, growPathOptionId, accessToken);

                    try {
                        const fetchJobOptions = await pollGrowJobs(
                            canvasId,
                            growPathOptionId,
                            accessToken,
                            abortController.signal,
                        );
                        this._setInitialState(fetchJobOptions);
                    } catch (error) {
                        if (error instanceof PollEndpointTimeoutError) {
                            Sentry.captureException(error);
                            this._timeoutError = true;
                        } else if (error instanceof Error && error.name === 'AbortError') {
                            console.log('Loading jobs cancelled');
                        } else {
                            throw error;
                        }
                    } finally {
                        this.isLoadingJobs = false;
                    }
                } else {
                    this.isLoadingJobs = false;
                    this._setInitialState(jobOptions);
                }
            });
        },
        resetJobsView(): void {
            this.job = null;
            this.pathJobOptions = [];
            if (this._abortLoadingJobs) {
                this._abortLoadingJobs.abort();
            }
            this._abortLoadingJobs = null;
        },
        async acceptJob(): Promise<void> {
            await useAppErrorStore().catchErrors(async () => {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                const pathwaysStore = useGrowPathwaysStore();
                const jobOptionId = verify(this.job?.id, 'job option id is not set');
                const growPathId = verify(pathwaysStore.path?.id, 'grow path id is not set');
                await acceptJobOption(canvasId, growPathId, jobOptionId, accessToken);
            });
        },
        _setInitialState(apiOptions: ApiJobOption[]): void {
            this.pathJobOptions = apiOptions;

            if (!this.job) {
                const firstAcceptedJob = this.pathJobOptions.find(
                    (job) => job.state === 'accepted',
                );

                if (firstAcceptedJob) {
                    this.job = firstAcceptedJob;
                }
            }
        },
    },
});
