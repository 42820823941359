<template>
    <app-selectable-card
        min-height="140"
        min-width="260"
        :title="item.title"
        :description="item.description"
        :isSelected="isSelected"
        :disabled="isDisabled"
        @onSelected="onSelected"
    >
        <template #append-title>
            <app-label :type="item.category" />
        </template>

        <template #extra>
            <div class="pa-0">
                <app-button
                    class="px-0 text-none text-custom-neutral-grey-900 font-weight-600 opacity-1"
                    variant="plain"
                    @click="onReadMore"
                >
                    Read more
                </app-button>
            </div>
        </template>
    </app-selectable-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { isJobOptionActivity } from '@/store/grow/util';
    import { type PathJobActivity, JobActivityProgressState } from '@/store/grow/types';
    import { useGrowJobActivityStore } from '@/store/grow/jobActivityStore';

    const props = defineProps<{
        item: PathJobActivity;
    }>();

    const emit = defineEmits(['read-more-click']);

    const onReadMore = (item: PathJobActivity) => {
        emit('read-more-click', item);
    };

    const store = useGrowJobActivityStore();
    const onSelected = (value: boolean) => {
        store.toggleSelected(props.item);
    };

    const isSelected = computed(() => {
        const activity = props.item;
        return store.isSelected(activity);
    });

    const isDisabled = computed(() => {
        const action = props.item;
        return (
            isJobOptionActivity(action) &&
            action.progress_state === JobActivityProgressState.Completed
        );
    });
</script>

<style scoped lang="scss">
    .action-item-description {
        color: rgb(var(--v-theme-custom-neutral-grey-700));
    }

    .action-selected {
        border-radius: 8px;
        border: 2px solid;
        border-color: rgb(var(--v-theme-primary));
    }

    .action-not-selected {
        border-radius: 8px;
        border: 2px solid;
        border-color: rgb(var(--v-theme-custom-neutral-grey-200));
    }

    .action-item-action-type {
        position: absolute;
        right: 20px;
    }
</style>
