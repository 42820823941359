<template>
    <v-card
        variant="outlined"
        elevation="0"
        class="job-option"
        :class="isDesktop ? 'rounded-12' : 'rounded-8'"
    >
        <v-card-item class="px-4 py-6 job-option__title ga-6">
            <div class="text-heading-h3">{{ value.title }}</div>
            <GrowPathwayTitle :title="pathTitle" :description="pathDescription" class="mt-1" />
        </v-card-item>

        <v-card-text class="job-option__content d-flex flex-column ga-4 pa-5">
            <div class="text-paragraph-md pl-4">
                <ul>
                    <li>{{ value.summary }}</li>
                    <li>{{ value.fit_to_person }}</li>
                </ul>
            </div>

            <v-divider />

            <GrowPathJobsSelectionJobOptionCompetency type="skill" :value="value" />

            <v-divider />

            <GrowPathJobsSelectionJobOptionCompetency type="work_experience" :value="value" />

            <div>
                <GrowPathPathwaysContinueButton
                    text="Continue with this job"
                    :is-selected="isSelected"
                    @on-selected="emit('onSelected')"
                />
            </div>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import type { PathJobOption } from '@/store/grow/types';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import GrowPathPathwaysContinueButton from '@/components/grow/path/pathways/GrowPathPathwaysContinueButton.vue';
    import GrowPathJobsSelectionJobOptionCompetency from '@/components/grow/path/pathways/GrowPathJobsSelectionJobOptionCompetency.vue';
    import GrowPathwayTitle from '@/components/grow/common/GrowPathwayTitle.vue';

    const props = defineProps<{
        value: PathJobOption;
        pathTitle: string;
        pathDescription: string;
        isSelected: boolean;
    }>();

    const emit = defineEmits(['onSelected']);

    const { isDesktop } = useIsDesktop();

    // Note: Do not delete this variable, as it is bound to the < style />
    const matchPercentage = computed(() => {
        return props.value.fit_to_person_pct;
    });
</script>

<style lang="scss" scoped>
    .job-option {
        border: 2px solid rgb(var(--v-theme-navigation-outline));

        .job-option__title {
            background: linear-gradient(
                to right,
                rgba(var(--v-theme-primary), 0.5) v-bind('matchPercentage + "%"'),
                transparent v-bind('matchPercentage + "%"')
            );
        }

        .job-option__content {
            border-top: 2px solid rgb(var(--v-theme-navigation-outline));
        }
    }
</style>
