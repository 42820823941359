<template>
    <v-container class="d-flex flex-column ga-4 pa-0" v-if="value">
        <GrowPathVisionFormControl
            title="How far forward are we looking?"
            subtitle="We’re going to build a plan for you to achieve your future ambition so the
                best place to start is the end. How far forward would you like to plan? Set
                yourself a realistic goal and timeframe."
        >
            <v-select
                color="neutral-grey"
                v-model="value.timeframe"
                :items="store.timeframeItems"
                :rules="[requiredRule('Timeframe')]"
                label="Select a timeframe"
                variant="outlined"
                class="mt-4 w-100"
            />
        </GrowPathVisionFormControl>

        <GrowPathVisionFormControl title="Where will you be living?">
            <v-select
                color="neutral-grey"
                v-model="value.location"
                :items="store.locationItems"
                :rules="[requiredRule('Location')]"
                label="Location"
                variant="outlined"
                class="mt-4 w-100"
            />
        </GrowPathVisionFormControl>

        <GrowPathVisionFormControl
            title="What are your passions?"
            subtitle="What do you love doing, and what fires you up! Being happy at work usually
            occurs when you can bring to life things you are passionate about.
            So lets get creative but keep it short (no more than 8 words)."
        >
            <v-text-field
                variant="outlined"
                class="mt-4 w-100"
                label="Passions"
                v-model="value.passions"
                :rules="[requiredRule('This')]"
            />
        </GrowPathVisionFormControl>

        <GrowPathVisionFormControl
            title="How do you like to work?"
            subtitle="In an ideal world, in the timeframe you’ve specified - what role will work
                play in your life?"
        >
            <v-select
                color="neutral-grey"
                v-model="value.way_of_working"
                :items="store.waysOfWorkingItems"
                :rules="[requiredRule('Way of working')]"
                label="Ideal way of working"
                variant="outlined"
                class="mt-4 w-100"
            />
        </GrowPathVisionFormControl>

        <GrowPathVisionFormControl
            title="One word that defines your future"
            subtitle="Distilling down a vision statement into 1 word is hard. But if you had to
                describe what an ideal job will provide for you, or how it will make you feel -
                what would your word be?"
        >
            <v-text-field
                variant="outlined"
                class="mt-4 w-100"
                label="In a word, my future is..."
                v-model="value.what_i_imagine"
                :rules="[requiredRule('This')]"
            />
        </GrowPathVisionFormControl>
    </v-container>
</template>

<script setup lang="ts">
    import { useLifeVisionStore } from '@/store/grow/lifeVisionStore';
    import { storeToRefs } from 'pinia';
    import GrowPathVisionFormControl from '@/components/grow/path/vision/GrowPathVisionFormControl.vue';
    import { requiredRule } from '@/components/profile/rules';

    const store = useLifeVisionStore();

    const { value } = storeToRefs(store);
</script>
