<template>
    <StoryCard color="primary-light">
        <template #title> My Preferences</template>

        <template #description>
            Preferences are choices we make that help us be confident in deciding on our future.
            Once you’ve prioritised preferences we will use these to recommend pathways and jobs
            for you. So choose carefully!
        </template>

        <template #content>
            <v-container class="d-flex flex-column ga-4 pa-0">
                <StorySectionActivity
                    title="My preferences"
                    :to="growPreferencesRoute"
                    :status="status"
                    :expanded="true"
                    :to-clickable="isLifeVisionCompleted"
                    class="fill-height px-4 pt-4 ma-0 rounded-lg d-flex flex-column"
                >
                    <v-container class="pa-0 d-flex flex-column ga-4" v-if="isLifeVisionCompleted">
                        <GrowPreferencesLists />
                    </v-container>
                    <v-container class="d-flex flex-column ga-4 pa-0" v-else>
                        This will be available when you complete your the Life Vision activity.
                    </v-container>
                </StorySectionActivity>
            </v-container>

        </template>
    </StoryCard>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import StoryCard from '@/components/story/section/StoryCard.vue';
    import StorySectionActivity from '@/components/story/section/activity/StorySectionActivity.vue';
    import { useGrowPreferencesStore } from '@/store/grow/preferencesStore';
    import { makeActivityStatus } from '@/store/story/makeActivityStatus';
    import { growPreferencesRoute } from '@/router/routes';
    import { useLifeVisionStore } from '@/store/grow/lifeVisionStore';
    import GrowPreferencesLists from '@/components/grow/common/GrowPreferencesLists.vue';

    const store = useGrowPreferencesStore();
    const lifeVisionStore = useLifeVisionStore();

    const status = computed(() => {
        return makeActivityStatus(store.isAreaComplete, store.isAreaInProgress);
    });

    const isLifeVisionCompleted = computed(() => {
        return lifeVisionStore.isLifeVisionCompleted;
    });
</script>
