import type { ISO8601 } from '@/lib/dates';

export type ApiPreferenceCategory = 'need' | 'nice' | 'bonus' | null;

export type ApiGrowPreferenceBase = {
    title: string;
    type: GrowPreferencePreferenceTypeId;
    category: ApiPreferenceCategory;
};

export type ApiGrowPreferenceCreate = ApiGrowPreferenceBase;

export type ApiGrowPreferenceUpdate = {
    category: ApiPreferenceCategory;
};

export type ApiGrowPreference = ApiGrowPreferenceBase & {
    id: number;
    canvas_id: number;
    created_at: ISO8601;
    updated_at: ISO8601;
};

export type ApiGrowPredefinedPreferenceType = {
    id: number;
    title: string;
    description: string;
    order: number;
    preferences: ApiGrowPredefinedPreferenceItem[];
};

export enum GrowPreferencePreferenceTypeId {
    Industry = 'industry',
    Culture = 'culture',
    Outcomes = 'outcomes',
    Place = 'place',
    Activities = 'activities',
    People = 'people',
    Money = 'money',
    Causes = 'causes',
}

export type ApiGrowPredefinedPreferenceItem = {
    id: number;
    title: string;
};
