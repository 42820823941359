import {
    type GuideEnterEvent,
    GuideEventType,
    type GuideLeaveEvent,
    type GuideQuestionEvent,
    type GuideSuggestionEvent,
    GuideSupportedScreen,
} from '@/store/guide/types';
import { EventFactory } from '@/store/guide/event-factory/eventFactory';
import type { GrowPathStoreState } from '@/store/grow/types';

export class GrowPathJobsAndActivitiesEventFactory extends EventFactory<GrowPathStoreState> {
    screen = GuideSupportedScreen.GrowPathJobsAndActivities;

    public enter(): GuideEnterEvent {
        return this.make<GuideEnterEvent>(GuideEventType.Enter, {});
    }

    leave(): GuideLeaveEvent {
        return this.make<GuideLeaveEvent>(GuideEventType.Leave, {});
    }

    question(message: string): GuideQuestionEvent {
        return this.make<GuideQuestionEvent>(GuideEventType.Question, { q: message });
    }

    suggestion(): GuideSuggestionEvent {
        return this.make<GuideSuggestionEvent>(GuideEventType.Suggestion, {});
    }
}
