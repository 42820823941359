<template>
    <v-card
        :disabled="isDisabled"
        min-height="fit-content"
        min-width="260"
        class="story-section-activity-card pa-5 d-flex flex-column"
        elevation="0"
    >
        <v-card-item class="pa-0">
            <div class="w-100 pb-5">
                <div class="text-heading-h4 cursor-pointer">
                    {{ item.title }}
                </div>
                <div class="mt-2">
                    <app-label :type="item.category" />
                </div>
            </div>
        </v-card-item>
        <v-card-text class="pa-0">
            <div class="text-custom-neutral-grey-700 text-paragraph-sm w-100">
                {{ item.description }}
            </div>

            <div class="text-custom-neutral-grey-700 text-paragraph-sm w-100 mt-4">
                <div v-html="formattedLongDescription" id="htmlContainer"></div>
            </div>

            <v-card-text class="pa-0" v-if="links.length">
                <div class="mt-5">
                    <v-divider class="my-5" />
                    <ul class="ml-4">
                        <li v-for="link in links" :key="link.url">
                            <a :href="link.url" target="_blank">{{ link.title }}</a>
                        </li>
                    </ul>
                </div>
            </v-card-text>

            <div class="mt-4">
                <FrequencySelector
                    :model-value="selectedFrequency"
                    @update:model-value="onUpdateApproach"
                    :options="frequencyOptions"
                />
            </div>

            <div class="mt-4">
                <v-text-field
                    class="pa-0"
                    label="Intended Due date"
                    variant="outlined"
                    :rules="[requiredRule('Due date')]"
                    :hide-details="false"
                    type="date"
                    :model-value="dueDate"
                    @update:model-value="onChangeDate"
                    validate-on="input"
                />
            </div>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { requiredRule } from '@/components/profile/rules';
    import { mapBrowserDateStringToIso, mapIsoDateToBrowserFormat } from '@/lib/dates';
    import FrequencySelector from '@/components/pathway/single-challenge/sequencing/FrequencySelector.vue';
    import {
        FREQUENCY_TO_BACKEND_MAP,
        type FrequencyOption,
        getSimplifiedFrequencyFromApproach,
        SIMPLIFIED_FREQUENCY_OPTIONS,
    } from '@/components/pathway/single-challenge/sequencing/frequencySelector';
    import { parseLinks } from '@/components/pathway/single-challenge/sequencing/parseLinks';
    import { type PathAcceptedJobActivity, JobActivityProgressState } from '@/store/grow/types';

    const props = defineProps<{
        item: PathAcceptedJobActivity;
    }>();

    const frequencyOptions = ref<FrequencyOption[]>(SIMPLIFIED_FREQUENCY_OPTIONS);

    // Find or create the option based on the current item's properties
    const selectedFrequency = ref(getSimplifiedFrequencyFromApproach(props.item.frequency, 0));

    const dueDate = computed(() => {
        const date = props.item.due_at;
        if (date) {
            return mapIsoDateToBrowserFormat(date);
        }

        return '';
    });

    const onChangeDate = (value: string) => {
        const date = mapBrowserDateStringToIso(value);

        if (date) {
            props.item.due_at = date;
        }
    };

    const formattedLongDescription = computed(() => {
        return (props.item.long_description ?? '').replace(/\n/g, '<br>');
    });

    const links = computed(() => {
        return parseLinks(props.item.links);
    });

    function onUpdateApproach(updatedValue: FrequencyOption) {
        selectedFrequency.value = updatedValue;

        // Convert to backend-compatible values:
        const { frequency, duration_min, duration_max } =
            FREQUENCY_TO_BACKEND_MAP[updatedValue.key];

        props.item.frequency = frequency;
    }

    const isDisabled = computed(() => {
        const action = props.item;
        return action.progress_state === JobActivityProgressState.Completed;
    });
</script>

<style scoped lang="scss">
    .story-section-activity-card {
        border: 2px solid;
        border-color: rgb(var(--v-theme-custom-neutral-grey-200));
    }

    .action-item-frequency {
        position: absolute;
        bottom: 20px;
    }
</style>
