import { defineStore } from 'pinia';
import { makeDataState } from '@/store/common/dataState';
import { useAppErrorStore } from '@/store/appErrorStore';
import { verify } from '@/store/verify';
import { type CanvasContext, useCanvasStore } from '@/store/canvas/store';
import { useLoadingStore } from '@/store/loadingStore';

import * as Sentry from '@sentry/vue';
import type { PathAcceptedJobActivity, PathJobActivity, JobActivityStoreState } from '@/store/grow/types';
import { JobActivityProgressState } from '@/store/grow/types';
import {
    bulkUpdateJobOptionActivities,
    regenerateJobOptionActivity,
    createJobOptionActivity,
    fetchJobOptionActivities,
    generateJobOptionActivity,
    updateJobOptionActivity,
} from '@/services/grow/activities/service';
import { useGrowPathwaysStore } from '@/store/grow/pathwaysStore';
import {
    pollJobActivities,
} from '@/services/grow/activities/pollJobActivities';
import { PollEndpointTimeoutError } from '@/services/pollUntil';
import { isAcceptedJobActivity, makeNewJobActivity } from '@/store/grow/util';
import type {
    ApiBulkUpdateAcceptedJobActivity,
    ApiBulkUpdateJobActivity,
    ApiJobActivity,
    ApiJobActivityUpdateData,
} from '@/api/types/grow/jobs';
import { cloneDeep } from 'lodash';
import { useGrowJobsStore } from '@/store/grow/jobsStore';
import { daysLeft } from '@/lib/dates';

export const MINIMUM_AMOUNT_JOB_ACTIVITIES = 3;
type RequestContext = CanvasContext & {
    pathId: number;
    jobId: number;
};

export const useGrowJobActivityStore = defineStore({
    id: 'grow-job-activity-store',
    state: (): JobActivityStoreState => ({
        current: null,
        _activities: [],
        _selectedActivities: [],
        // feedback: null,
        _timeoutError: false,
        _abortActivities: null,
        ...makeDataState(),
    }),
    getters: {
        activities(state): PathJobActivity[] {
            const actions = state._activities ?? [];
            const _byState = (a: PathJobActivity, b: PathJobActivity) => {
                if (a.progress_state === b.progress_state) {
                    return a.id - b.id;
                }
                return a.progress_state === JobActivityProgressState.Completed ? -1 : 1;
            };

            return [...actions.sort(_byState)];
        },
        acceptedActivities(state): PathAcceptedJobActivity[] {
            return this._selectedActivities.filter((a) => {
                return isAcceptedJobActivity(a);
            });
        },
        progress(state): number {
            const actions = state._selectedActivities ?? [];
            if (actions.length === 0) {
                return 0;
            }

            const completedActions = actions.filter(
                (action) => action.progress_state === JobActivityProgressState.Completed,
            );
            return (completedActions.length / actions.length) * 100;
        },
        daysLeft(state): number | null {
            // Filter out null or invalid dueDates and map to date objects
            const validDates = state._selectedActivities
                .map((activity) => activity.due_at)
                .filter((date) => !!date)
                .map((date) => new Date(date));

            return daysLeft(validDates);
        },
        isInProgress(): boolean {
            return this.progress >= 0 && this.progress < 100;
        },
        isActivitiesCompleted(): boolean {
            return this.progress === 100;
        },
        isAddingNew(): boolean {
            return !!this.current;
        },
        isActivitiesSelectionComplete(): boolean {
            return this._selectedActivities.length >= MINIMUM_AMOUNT_JOB_ACTIVITIES;
        },
        isActivitiesSequencingComplete(): boolean {
            return (
                this.isActivitiesSelectionComplete &&
                this._selectedActivities.every((activity, index) => {
                    return activity.order === index;
                })
            );
        },
    },
    actions: {
        async load(): Promise<void> {
            const abortController = new AbortController();
            this._abortActivities = abortController;

            const ctx = await this._makeContext();
            const currentJobIdFromActivities = this.activities[0]?.job_option_id;

            if (currentJobIdFromActivities && currentJobIdFromActivities !== ctx.jobId) {
                console.log(
                    `Resetting job activity store as current activities belong to
                        ${currentJobIdFromActivities} and select job is ${ctx.jobId}`,
                );
                this.$reset();
            }

            console.info('Loading job activities ...');
            await useAppErrorStore().catchErrors(async () => {
                const ctx = await this._makeContext();
                const activities = await fetchJobOptionActivities(
                    ctx.canvasId,
                    ctx.pathId,
                    ctx.jobId,
                    ctx.accessToken,
                );

                if (activities.length === 0) {
                    console.info('Job option not started');

                    useLoadingStore().setLoadingText(
                        'Setting up this job for you.',
                        'Please stay on this page. This can take up to a minute...',
                    );

                    await generateJobOptionActivity(
                        ctx.canvasId,
                        ctx.pathId,
                        ctx.jobId,
                        ctx.accessToken,
                    );

                    try {
                        const jobActivities = await pollJobActivities(
                            ctx.canvasId,
                            ctx.pathId,
                            ctx.jobId,
                            ctx.accessToken,
                            abortController.signal
                        );

                        this._setInitialState(jobActivities);
                    } catch (error) {
                        if (error instanceof PollEndpointTimeoutError) {
                            Sentry.captureException(error);
                            this._timeoutError = true;
                        } else {
                            throw error;
                        }
                    }
                } else {
                    this._setInitialState(activities);
                }
            });
        },
        async loadMore(): Promise<void> {
            const abortController = new AbortController();
            this._abortActivities = abortController;
            this.$reset();

            console.info('Loading job activities (more)...');
            await useAppErrorStore().catchErrors(async () => {
                const ctx = await this._makeContext();
                const activities = await fetchJobOptionActivities(
                    ctx.canvasId,
                    ctx.pathId,
                    ctx.jobId,
                    ctx.accessToken,
                );

                const priorActivitiesLength = activities.length;

                useLoadingStore().setLoadingText(
                    'Finding some new activities for you.',
                    'Please stay on this page. This can take up to a minute...',
                );

                await regenerateJobOptionActivity(
                    ctx.canvasId,
                    ctx.pathId,
                    ctx.jobId,
                    ctx.accessToken,
                );

                try {
                    const newActivities = await pollJobActivities(
                        ctx.canvasId,
                        ctx.pathId,
                        ctx.jobId,
                        ctx.accessToken,
                        abortController.signal,
                        priorActivitiesLength,
                    );

                    this._setInitialState(newActivities);
                } catch (error) {
                    if (error instanceof PollEndpointTimeoutError) {
                        Sentry.captureException(error);
                        this._timeoutError = true;
                    } else {
                        throw error;
                    }
                }
            });
        },
        isSelected(activity: PathJobActivity): boolean {
            return this.getSelected(activity) !== null;
        },
        getSelected(activity: PathJobActivity): PathJobActivity | null {
            return (
                this._selectedActivities.find((a) => {
                    return a.id === activity.id;
                }) ?? null
            );
        },
        addActivity(): void {
            this.current = makeNewJobActivity();
        },
        cancelNew(): void {
            this.current = null;
        },
        /**
         * Save the current selected activities without altering the order
         */
        async save(): Promise<void> {
            await useAppErrorStore().catchErrors(async () => {
                const ctx = await this._makeContext();
                const payload = this.activities.map((activity: PathJobActivity) => {
                    const isSelected = this.isSelected(activity);
                    return {
                        ...activity,
                        state: isSelected ? 'accepted' : 'suggested',
                        // If un-selected, set order to null. Only accepted activities have an order.
                        order: isSelected ? activity.order : null,
                    };
                }) as ApiBulkUpdateJobActivity[];

                await bulkUpdateJobOptionActivities(
                    ctx.canvasId,
                    ctx.pathId,
                    ctx.jobId,
                    ctx.accessToken,
                    payload,
                );
            });
        },
        /**
         * Save the current selected activities in the order they are in the list.
         * This is used when the user has manually ordered the activities.
         */
        async saveApproach(): Promise<void> {
            await useAppErrorStore().catchErrors(async () => {
                const ctx = await this._makeContext();
                const acceptedActivities = this._selectedActivities.map(
                    (activity: PathJobActivity, index: number) => {
                        return {
                            ...activity,
                            state: 'accepted',
                            order: index,
                        };
                    },
                ) as ApiBulkUpdateAcceptedJobActivity[];

                await bulkUpdateJobOptionActivities(
                    ctx.canvasId,
                    ctx.pathId,
                    ctx.jobId,
                    ctx.accessToken,
                    acceptedActivities,
                );

                // Update the local state, so we do not have to fetch the activities again
                this._selectedActivities = acceptedActivities;
            });
        },
        async saveNew(): Promise<void> {
            await useAppErrorStore().catchErrors(async () => {
                const value = verify(this.current, 'No activity to save');
                const newActivity = cloneDeep(value);
                const ctx = await this._makeContext();
                const [createdActivity, _response] = await createJobOptionActivity(
                    ctx.canvasId,
                    ctx.pathId,
                    ctx.jobId,
                    ctx.accessToken,
                    newActivity,
                );

                this._activities.push(createdActivity);
                this.toggleSelected(createdActivity);
                this.current = null;
            });
        },
        toggleSelected(activity: PathJobActivity) {
            const found = this.getSelected(activity);

            if (found) {
                const index = this._selectedActivities.indexOf(found);
                this._selectedActivities.splice(index, 1);
            } else {
                this._selectedActivities.push(activity);
            }
        },
        moveActionUp(index: number) {
            const action = this._selectedActivities[index];
            if (action) {
                this._selectedActivities.splice(index, 1);
                this._selectedActivities.splice(index - 1, 0, action);
            }
        },
        moveActionDown(index: number) {
            const action = this._selectedActivities[index];
            if (action) {
                this._selectedActivities.splice(index, 1);
                this._selectedActivities.splice(index + 1, 0, action);
            }
        },
        async onMarkAsCompleted(activity: PathAcceptedJobActivity): Promise<void> {
            await useAppErrorStore().catchErrors(async () => {
                const newActivity: ApiJobActivityUpdateData = {
                    ...cloneDeep(activity),
                    progress_state: JobActivityProgressState.Completed,
                };

                const ctx = await this._makeContext();
                await updateJobOptionActivity(
                    ctx.canvasId,
                    ctx.pathId,
                    ctx.jobId,
                    activity.id,
                    ctx.accessToken,
                    newActivity,
                );

                // Update the local state, so we do not have to fetch the activities again
                activity.progress_state = JobActivityProgressState.Completed;
            });
        },
        async _makeContext(): Promise<RequestContext> {
            return {
                ...(await useCanvasStore().makeContext()),
                pathId: verify(useGrowPathwaysStore().path?.id, 'Grow path id not found'),
                jobId: verify(useGrowJobsStore().job?.id, 'Job path id not found'),
            };
        },
        _setInitialState(activities: ApiJobActivity[]): void {
            this._activities = activities;
            this._selectedActivities = this._activities.filter((a) => a.state === 'accepted');
        },
    },
});
