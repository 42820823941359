<template>
    <app-error-boundary>
        <v-app class="app-container" :class="cssClasses">
            <AuthenticationProvider>
                <template v-if="userStore.isLoaded">
                    <AppHeaderDesktop v-if="isDesktop" />
                    <AppHeaderMobile v-else class="d-print-none" />
                    <v-main
                        :style="
                            isDesktop
                                ? '--v-layout-left: 54px; --v-layout-right: 4px; --v-layout-top: 4px; --v-layout-bottom: 4px;'
                                : '--v-layout-top: 60px;'
                        "
                        class="app-main-layout"
                    >
                        <v-container
                            fluid
                            class="app-main-layout-wrapper bg-white fill-height pa-0"
                            :class="cssClasses"
                        >
                            <AppLayoutWithNav v-if="hasNavAndFooter" />
                            <AppLayoutWithoutNav v-else />
                        </v-container>
                    </v-main>
                </template>
            </AuthenticationProvider>
            <LiveChat />
        </v-app>
    </app-error-boundary>
</template>

<script setup lang="ts">
    import { useRoute } from 'vue-router';
    import AppHeaderDesktop from '@/components/header/AppHeaderDesktop.vue';
    import AppHeaderMobile from '@/components/header/AppHeaderMobile.vue';
    import { computed, onBeforeMount, onMounted } from 'vue';
    import { useUsersStore } from '@/store/user/store';
    import AuthenticationProvider from '@/views/auth/AuthenticationProvider.vue';
    import { useAuth0 } from '@/auth/auth';
    import router from '@/router';
    import {
        type DeclaredRoute,
        privacyRoute,
        profileRoute,
        termsRoute,
        welcomeRoute,
    } from '@/router/routes';
    import AppErrorBoundary from '@/components/common/AppErrorBoundary.vue';
    import { ApiAuthError, Auth0Error } from '@/errors';
    import { useUserProfileStore } from '@/store/profile/store';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useInlineGuideStore } from '@/store/inline-guide/store';
    import { useCurriculumSubscribeChangesStore } from '@/store/syncing/curriculumSyncingStore';
    import { makeLogoutHandle } from '@/components/utils';
    import AppLayoutWithNav from '@/AppLayoutWithNav.vue';
    import AppLayoutWithoutNav from '@/AppLayoutWithoutNav.vue';
    import { useDynamicTheme } from '@/store/useDynamicTheme';
    import LiveChat from '@/components/common/LiveChat.vue';

    const userStore = useUsersStore();
    const profileStore = useUserProfileStore();
    const { user, getAccessTokenSilently } = useAuth0();

    const { responsiveClass, isDesktop } = useIsDesktop();

    const { logout } = useAuth0();

    // Initializes the dynamic theme store. Required for theme management—do not remove.
    const _themeStore = useDynamicTheme();

    const route = useRoute();

    onBeforeMount(async () => {
        // Initialise user after the authentication from auth0 finished.
        try {
            await userStore.firstLoadUser(user, getAccessTokenSilently);
            profileStore.init();
        } catch (e) {
            if (e instanceof ApiAuthError || e instanceof Auth0Error) {
                // If there was an error authenticating the user, log them out.
                // That way, their session state is clean and they can try again.
                // If the session state is not clean, the user will just see
                // an "Unexpected application error" message.
                // NOTE! This error here is only evaluated on page (re)load.
                // This means that clicking around in the single-page application
                // will not trigger this error handling. However, this is good
                // enough for now. The main error we are handling is if the user
                // was disabled in the database, which is a rare case. Eventually,
                // the user will reload the page and get logged out, cleanly.
                // TODO: Handle this case also for clicks/actions within the single-
                // page application.
                const _logout = makeLogoutHandle(useInlineGuideStore(), logout);
                await _logout();
            } else {
                // Re-throw the error if it is not an AuthError.
                throw e;
            }
        }

        if (isCurrent(privacyRoute) || isCurrent(termsRoute)) {
            // TODO: This looks a bit hacky. Could be taken to some kind of router guard.
            console.log('User without profile set accessing privacy page.');
            return;
        } else if (!userStore.hasProfileBeenSet) {
            console.info(
                'User has been logged for first time. Navigating to welcome on boarding flow',
            );
            await router.push(welcomeRoute);
        } else if (!profileStore.isComplete) {
            console.warn('Profile is not complete. Redirecting to profile page');
            await router.push(profileRoute);
        } else if (userStore.hasProfileBeenSet) {
            console.log('Profile has already been set by user. Nothing to do on login');
            return;
        } else {
            console.info(
                'User has been logged for first time. Navigating to welcome on boarding flow',
            );
            await router.push(welcomeRoute);
        }
    });

    const isCurrent = (r: DeclaredRoute) => {
        return r.name === route.name;
    };

    /** Whether the layout is an activity layout, including the top navigation bar and bottom navigation footer */
    const hasNavAndFooter = computed(() => {
        return route.meta.hasNavAndFooter;
    });

    const cssClasses = computed(() => ({
        [responsiveClass.value]: true,
    }));

    onMounted(() => {
        useCurriculumSubscribeChangesStore().start();
    });
</script>

<style scoped lang="scss">
    .app-container {
        &.desktop {
            :deep(.v-application__wrap) {
                background: var(--v-background-linear-gradient);
                position: fixed;
                width: 100%;
                height: 100%;
                z-index: -1;
            }

            :deep(.v-main) {
                &.app-main-layout {
                    height: 100%;

                    .app-main-layout-wrapper {
                        /* Ensure padding and borders are included in the size */
                        box-sizing: border-box;
                        border-radius: 8px;
                        margin-left: 0;
                        width: 100%;
                    }
                }
            }
        }
    }
</style>
