import { create, fetch, remove, update } from '@/services/base';
import {
    postPreference,
    deletePreference,
    getPredefinedPreferenceTypes,
    getPreferences,
    putPreference,
} from '@/services/grow/preferences/api';
import type {
    ApiGrowPredefinedPreferenceItem, ApiGrowPredefinedPreferenceType,
    ApiGrowPreference,
    ApiGrowPreferenceCreate,
    ApiGrowPreferenceUpdate,
} from '@/api/types/grow/preferences';

export async function fetchGrowPredefinedPreferenceTypes(
    accessToken: string,
): Promise<ApiGrowPredefinedPreferenceType[]> {
    return fetch(
        'predefined-preference-types',
        async () => await getPredefinedPreferenceTypes(accessToken),
    );
}

export async function fetchGrowPreferences(
    canvasId: number,
    accessToken: string,
): Promise<ApiGrowPreference[]> {
    return fetch('preferences', async () => await getPreferences(canvasId, accessToken));
}

export async function createGrowPreference(
    canvasId: number,
    createData: ApiGrowPreferenceCreate,
    accessToken: string,
): Promise<ApiGrowPreference> {
    const [preference, _response] = await create(
        'create-preference',
        async () => await postPreference(canvasId, createData, accessToken),
    );

    return preference;
}

export async function updateGrowPreference(
    canvasId: number,
    preferenceId: number,
    updateData: ApiGrowPreferenceUpdate,
    accessToken: string,
): Promise<void> {
    return update(
        'update-preference',
        async () => await putPreference(canvasId, preferenceId, updateData, accessToken),
    );
}

export async function removeGrowPreference(
    canvasId: number,
    preferenceId: number,
    accessToken: string,
): Promise<void> {
    return remove(
        'delete-preference',
        async () => await deletePreference(canvasId, preferenceId, accessToken),
    );
}
