<template>
    <GrowPathVisionStatement
        v-if="lifeVision"
        ref="visionRef"
        :value="lifeVision"
        :render-html="false"
    />
    <StorySectionActivityInlineList
        :items="[lifeVisionStatementText]"
        class="w-100"
    />
</template>

<script setup lang="ts">
    import StorySectionActivityInlineList from '@/components/story/section/activity/StorySectionActivityInlineList.vue';
    import { useLifeVisionStore } from '@/store/grow/lifeVisionStore';
    import { computed, ref } from 'vue';
    import GrowPathVisionStatement from '@/components/grow/path/vision/GrowPathVisionStatement.vue';
    import type { StoryActivity } from '@/store/story/types';

    // this is needed for correct rendering on the Story page, do not remove
    defineProps<{
        item?: StoryActivity;
    }>();

    const visionRef = ref();

    const store = useLifeVisionStore();

    const lifeVision = computed(() => {
        return store.value;
    });

    const lifeVisionStatementText = computed(() => {
        return visionRef.value?.statementAsText;
    });
</script>
