<template>
    <HomeSection
        type="story"
        :about-description="aboutDescription"
        :image="image"
        :section-items="sectionItems"
        :to="storyRoute"
        submit-text="Go to Story"
    />
</template>

<script setup lang="ts">
    import image from '@/assets/images/home/story-text.svg';

    import HomeSection from '@/components/home/HomeSection.vue';
    import { storyRoute } from '@/router/routes';

    const aboutDescription = 'Story is your personal record of your Career Achievements and Professional Value.';

    const sectionItems = [
        {
            icon: 'search',
            title: 'Clarity on your character & achievements',
            description:
                'We offer activities to help you get clear on your unique style and character, and a way to record and understand the value of your achievements.',
        },
        {
            icon: 'arrow-up-right-dots',
            title: 'Discover your superpower',
            description:
                'Everyone has a unique talent. We show you yours. We use the information provided to craft powerful summary statements on what makes you uniquely valuable to the workforce.',
        },
        {
            icon: 'compass',
            title: 'Move your vision for your future forward',
            description:
                'Use the insights from your story such as your Growth Values, to focus on new behaviours and talents you have to accelerate your ambition and open up new growth.',
        },
    ];
</script>
