import { fetchPathOptions } from '@/services/grow/pathways/service';
import type { ApiPathOption } from '@/api/types/grow/pathways';
import { pollUntil } from '@/services/pollUntil';
import type { GenericAbortSignal } from 'axios';

/**
 * We assume that path options will be generated within 90 seconds. If not, we will show an error message,
 * and allow the user to try again. On the retry, we will show the loading screen again.
 * However, if the path options are generated within 90 seconds, we will show the path options.
 *
 * It is possible that path options from the previous run are shown, but this is acceptable.
 * Even maybe, the user see the double activities, but this is acceptable too.
 */
const POLLING_PATHWAYS_OPTIONS_ACTIONS_TIMEOUT = 90 * 1000;

/**
 * Repeatedly fetch a resource until a condition is met or a timeout reached
 */
export async function pollPathways(
    canvasId: number,
    accessToken: string,
    abortSignal: GenericAbortSignal,
): Promise<ApiPathOption[]> {
    return await pollUntil(
        'pathways',
        async (): Promise<ApiPathOption[] | null> => {
            const pathOptions = await fetchPathOptions(canvasId, accessToken);
            return pathOptions.length > 0 ? pathOptions : null;
        },
        { pollTimeout: POLLING_PATHWAYS_OPTIONS_ACTIONS_TIMEOUT, signal: abortSignal },
    );
}
