<template>
    <v-card
        variant="plain"
        class="opacity-1 fill-height bg-white px-5 pa-4 w-100 story-section-activity-card"
        :class="responsiveClass"
    >
        <template v-slot:title>
            <div
                class="text-heading-h4 story-section-activity-title-container"
                @click="isExpandable ? (isExpanded = !isExpanded) : () => {}"
                :class="isExpandable ? 'cursor-pointer' : ''"
            >
                <span class="story-section-activity-card__title">{{ title }}</span>
                <StorySectionItemStatus :value="status" class="story-section-activity-card__status" />

                <span class="story-section-activity-card__title__icon cursor-pointer d-print-none">
                    <app-fa-icon
                        v-if="!isExpandable"
                        class="go-to-activity"
                        :class="{ 'inactive-icon': !toClickable }"
                        icon="arrow-right"
                        @click="onGoToActivity"
                    />
                    <app-fa-icon
                        v-else-if="!isExpanded"
                        class="expand-button"
                        icon="chevron-down"
                    />
                    <app-fa-icon v-else icon="chevron-up" />
                </span>
            </div>
        </template>

        <v-card-text class="pa-0 pt-4 pb-4 d-print-none" v-if="isExpanded">
            <slot />
        </v-card-text>

        <v-card-text class="pa-0 pt-4 pb-4 print-story-section-activity-content d-print-only">
            <slot />
        </v-card-text>

        <v-divider
            v-if="isExpanded && toClickable"
            :thickness="1"
            class="my-3 w-100 border-opacity-20 d-print-none"
            color="custom-neutral-black"
        />

        <v-row no-gutters class="pa-0 w-100 d-print-none" v-if="isExpanded && toClickable">
            <app-button
                variant="plain"
                :to="to"
                class="ma-0 text-paragraph-sm font-weight-600 cursor-pointer"
            >
                View activity
            </app-button>
        </v-row>
    </v-card>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { computed, ref } from 'vue';
    import StorySectionItemStatus from '@/components/story/section/StorySectionItemStatus.vue';

    import { type RouteLocationRaw, useRouter } from 'vue-router';
    import { StoryActivityStatus } from '@/store/story/types';

    const { responsiveClass } = useIsDesktop();

    const props = withDefaults(defineProps<{
            title: string;
            status: StoryActivityStatus;
            component?: any;

            to: RouteLocationRaw;

            // Optional prop to control the expanded state by default
            expanded?: boolean;
            toClickable?: boolean;
        }>(),
        {
            toClickable: true,
        }
    );

    const isExpandable = computed(() => {
        return props.status === StoryActivityStatus.Completed;
    });

    const isExpanded = ref<boolean>(props.expanded ?? false);

    const router = useRouter();
    const onGoToActivity = async () => {
        if (props.toClickable) {
            await router.push(props.to);
        }
    };
</script>

<style scoped lang="scss">
    :deep(.v-card-item) {
        padding: 0;
    }

    .story-section-activity-card {
        &.desktop {
            .story-section-activity-card__title {
                margin-right: 20px;
                margin-bottom: 8px;
            }

            --top-button: 16px;
        }

        &.mobile {
            .story-section-activity-card__title {
                display: block; // makes occupy the whole row
                margin-bottom: 8px;
            }

            --top-button: 30px;
        }

        .story-section-activity-card__title__icon {
            position: absolute;
            right: 20px; // compensates card padding
            top: var(--top-button);
        }
    }

    .inactive-icon {
        color: #CCCCCC; // Light gray color for inactive icons
        opacity: 0.7;
        cursor: default;
    }
</style>
