<template>
    <Pie
        :data="pieData"
        :options="pieChartOptions"
        :plugins="[pieChartOptions.plugins.customTextPlugin]"
    />
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { Pie } from 'vue-chartjs';
    import { BaseColors, GrowPathThemeColors } from '@/plugins/vuetify/theme';

    const props = defineProps<{
        value: number;
    }>();

    const pieData = computed(() => {
        return {
            datasets: [
                {
                    data: [props.value, 100 - props.value],
                    backgroundColor: [GrowPathThemeColors.primary, GrowPathThemeColors['primary-light']],
                },
            ],
        };
    });

    const pieChartOptions = computed(() => {
        return {
            responsive: true,
            plugins: {
                tooltip: {
                    enabled: true, // Enable tooltips
                },
                customTextPlugin: {
                    id: 'customTextPlugin',
                    afterDraw(chart: any) {
                        const { ctx, chartArea } = chart;
                        const total = chart.data.datasets[0].data.reduce(
                            (a: number, b: number) => a + b,
                            0,
                        );
                        const match = chart.data.datasets[0].data[0];
                        const percentage = ((match / total) * 100).toFixed() + '%';
                        const centerX = chartArea.left + (chartArea.right - chartArea.left) / 2;
                        const centerY = chartArea.top + (chartArea.bottom - chartArea.top) / 2;

                        ctx.save();
                        ctx.font = 'bold 27px Inter';
                        ctx.fillStyle = BaseColors.black;
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        ctx.fillText(percentage, centerX, centerY - 10);

                        // Draw "Match" text below the percentage
                        ctx.font = 'bold 12px Inter'; // Slightly smaller font
                        ctx.fillStyle = BaseColors.black;
                        ctx.fillText('match', centerX, centerY + 15);
                        ctx.restore();
                    },
                },
            },
        };
    });
</script>
