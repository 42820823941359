<template>
    <app-tutorial section-name="Challenge Paths" action-text="Start" :steps="4">
        <template #title="{ step }">
            <span v-if="step === 1">Welcome to Grow Path</span>
            <span v-else-if="step === 2">Data-driven outcomes</span>
            <span v-else-if="step === 3">It’s your future, your choice</span>
            <span v-else-if="step === 4">Share with your manager to ensure success</span>
        </template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    Whether you are planning to become a leader in your current field, or want to
                    retrain in the future to do something completely new - we can help you get
                    there.
                </p>
                <p>
                    Grow Pathways will show you the plan that will suit you best, and match jobs
                    to your needs. We even show you how to get started and close gaps and experience
                    ensuring success.
                </p>
            </div>
            <div v-else-if="step === 2">
                <p>
                    We don’t just take the goals and needs you tell us about here to determine the
                    right growth path for you, we also use all the information you have shared with
                    us in your Story. So make sure to keep that up to date!
                </p>
            </div>
            <div v-else-if="step === 3">
                <p>
                    Our job is to present you with a series of options for pathways - ways of
                    working, and then jobs within those pathways.
                </p>
                <p>
                    These are just options but the choice is yours what you pursue! We suggest you
                    work through a couple of different paths before deciding.
                </p>
            </div>
            <div v-else-if="step === 4">
                <p>
                    Forging a pathway inside your current company will take time and support from
                    leadership.
                </p>
                <p>
                    Make sure to share your plan(s) and get your organisations feedback and support
                    to grow in a way that works for your organisations plans, as well as yours.
                </p>
            </div>
        </template>
    </app-tutorial>
</template>
