import { defineStore } from 'pinia';
import { useCanvasStore } from '@/store/canvas/store';
import type { PathOption, PathwaysStoreState } from '@/store/grow/types';
import { useAppErrorStore } from '@/store/appErrorStore';
import { useLoadingStore } from '@/store/loadingStore';
import * as Sentry from '@sentry/vue';
import {
    acceptPathOption,
    fetchPathOptions,
    generatePathOptions,
} from '@/services/grow/pathways/service';
import { pollPathways } from '@/services/grow/pathways/pollPathways';
import { verify } from '@/store/verify';
import type { ApiPathOption } from '@/api/types/grow/pathways';
import {
    PathOptionTitle,
    PREDEFINED_PATH_DEFINITION,
} from '@/components/grow/path/pathways/predefinedPathDefinition';
import { PollEndpointTimeoutError } from '@/services/pollUntil';

export const useGrowPathwaysStore = defineStore({
    id: 'grow-pathways-store',
    state: (): PathwaysStoreState => ({
        pathOptions: [],
        path: null,
        _timeoutError: false,
        _abortLoadingPaths: null,
        _needsUpdate: false,
    }),
    getters: {
        anySelected(): boolean {
            return this.path !== null;
        },
        isAreaComplete(state): boolean {
            return !!state.path;
        },
        needsLoadingGrowPathOptions(state): boolean {
            return state._needsUpdate || state.pathOptions.length === 0;
        },
    },
    actions: {
        setNeedsUpdate(): void {
            this._needsUpdate = true;
        },
        isSelected(option: PathOption): boolean {
            return this.path?.id === option.id;
        },
        toggleSelection(option: PathOption): void {
            if (this.isSelected(option)) {
                this.path = null;
            } else {
                this.path = option;
            }
        },
        async load(): Promise<void> {
            const abortController = new AbortController();
            this._abortLoadingPaths = abortController;

            if (!this.needsLoadingGrowPathOptions) {
                console.info('Path options already loaded');
                return;
            }

            console.info('Loading path options (new)...');
            await useAppErrorStore().catchErrors(async () => {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();
                const pathOptions = await fetchPathOptions(canvasId, accessToken);

                if (pathOptions.length === 0) {
                    console.info('Path options not started');

                    const loadingTitle = this._needsUpdate
                        ? 'Recomputing pathway options for you.'
                        : 'Setting up this pathway for you.';
                    useLoadingStore().setLoadingText(
                        loadingTitle,
                        'Please stay on this page. This can take up to a minute...',
                    );

                    await generatePathOptions(canvasId, accessToken);

                    try {
                        const fetchPathOptions = await pollPathways(
                            canvasId,
                            accessToken,
                            abortController.signal,
                        );
                        this._setPathOptions(fetchPathOptions);
                    } catch (error) {
                        if (error instanceof PollEndpointTimeoutError) {
                            Sentry.captureException(error);
                            this._timeoutError = true;
                        } else if (error instanceof Error && error.name === 'AbortError') {
                            console.log('Loading paths cancelled');
                        } else {
                            throw error;
                        }
                    }
                } else {
                    this._setPathOptions(pathOptions);
                }
            });
        },
        cancelPathsView(): void {
            if (this._abortLoadingPaths) {
                this._abortLoadingPaths.abort();
            }
            this._abortLoadingPaths = null;
        },
        async acceptGrowPath(): Promise<void> {
            await useAppErrorStore().catchErrors(async () => {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                const growPathOptionId = verify(this.path?.id, 'grow path option id is not set');
                await acceptPathOption(canvasId, growPathOptionId, accessToken);
            });
        },
        _setPathOptions(apiOptions: ApiPathOption[]): void {
            this._needsUpdate = false;
            this.pathOptions = apiOptions.map((pathOption: ApiPathOption) => {
                const definition = verify(
                    PREDEFINED_PATH_DEFINITION[pathOption.title.toLowerCase() as PathOptionTitle],
                    `Path option definition not found for ${pathOption.title}`,
                );

                return {
                    ...pathOption,
                    ...definition,
                };
            });
        },
    },
});
