<template>
    <v-carousel
        v-model="currentIndex"
        :show-arrows="true"
        :hide-delimiters="true"
        :height="255"
        :class="classes"
    >
        <v-carousel-item v-for="(item, i) in store.carouselItems" :key="item.id" cover>
            <GrowPathPriorityActivityCarouselCard
                :value="item"
                class="fill-height"
                :width="mainCardWidth"
            />
        </v-carousel-item>
        <template v-slot:prev="{ props }">
            <GrowPathPriorityActivityCarouselCard
                v-if="prevCard"
                :value="prevCard"
                :width="mainCardWidth"
                class="fill-height"
                @click="props.onClick"
            />
            <span v-else></span>
        </template>
        <template v-slot:next="{ props }">
            <GrowPathPriorityActivityCarouselCard
                v-if="nextCard"
                :value="nextCard"
                :width="mainCardWidth"
                class="fill-height"
                @click="props.onClick"
            />
            <span v-else></span>
        </template>
    </v-carousel>
    <v-row class="pa-0 pt-6 pb-2 w-100 mt-n12" no-gutters align="center">
        <v-col :cols="isDesktop ? 3 : 2" class="pa-0 text-right">
            <v-btn
                v-if="prevCard !== null"
                class="rounded-4"
                variant="flat"
                color="custom-yellow"
                icon="mdi-arrow-left"
                @click="() => (prevCard !== null ? (currentIndex = currentIndex - 1) : null)"
            />
        </v-col>
        <v-col class="justify-center">
            <GrowPathPriorityActivityCarouselButtons @click="onSelection" />
        </v-col>
        <v-col :cols="isDesktop ? 3 : 2" class="pa-0 text-left">
            <v-btn
                v-if="nextCard !== null"
                class="rounded-4"
                variant="flat"
                color="custom-yellow"
                :style="{ 'border-radius': '4px' }"
                icon="mdi-arrow-right"
                @click="() => (nextCard !== null ? (currentIndex = currentIndex + 1) : null)"
            ></v-btn>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useDisplay } from 'vuetify';
    import { storeToRefs } from 'pinia';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import GrowPathPriorityActivityCarouselCard from '@/components/grow/path/preferences/GrowPathPriorityActivityCarouselCard.vue';
    import { useGrowPreferencesStore } from '@/store/grow/preferencesStore';
    import GrowPathPriorityActivityCarouselButtons from '@/components/grow/path/preferences/GrowPathPriorityActivityCarouselButtons.vue';
    import type { ApiPreferenceCategory } from '@/api/types/grow/preferences';

    const store = useGrowPreferencesStore();

    const { isDesktop } = useIsDesktop();

    const onSelection = async (category: ApiPreferenceCategory) => {
        await store.updateCategory(category);
    };

    // Bind store state to v-model
    // https://stackoverflow.com/questions/76370713/how-to-use-pinia-store-getters-on-v-model-of-an-input
    const { currentIndex } = storeToRefs(store);

    const { name } = useDisplay();
    const classes = computed(() => {
        const next = nextCard.value ? 'has-next ' : 'no-has-next';
        const prev = prevCard.value ? 'has-prev' : 'no-has-prev';
        return `preferences-carousel ${next} ${prev} ${name.value}`;
    });

    const nextCard = computed(() => {
        if (currentIndex.value < store.carouselItems.length - 1) {
            return store.carouselItems[currentIndex.value + 1];
        } else {
            return null;
        }
    });

    const prevCard = computed(() => {
        if (store.carouselItems.length > 0 && currentIndex.value > 0) {
            return store.carouselItems[currentIndex.value - 1];
        } else {
            return null;
        }
    });

    const mainCardWidth = computed(() => {
        switch (name.value) {
            case 'xs':
                return 300;
            case 'sm':
            case 'md':
                return 440;
            case 'lg':
            case 'xl':
            case 'xxl':
                return 255;
            default:
                return 300;
        }
    });
</script>
<style lang="scss" scoped>
    :deep(.v-window-item) {
        align-self: center;
    }

    // Transition overrides
    .v-window-x-transition-enter-active,
    .v-window-x-transition-leave-active,
    .v-window-x-reverse-transition-enter-active,
    .v-window-x-reverse-transition-leave-active,
    .v-window-y-transition-enter-active,
    .v-window-y-transition-leave-active,
    .v-window-y-reverse-transition-enter-active,
    .v-window-y-reverse-transition-leave-active {
        transition: 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }

    .preferences-carousel {
        --main-width: v-bind(mainCardWidth);

        &.has-prev.no-has-next {
            :deep(.v-window__controls) {
                justify-content: left;
                left: -24px;
                padding: 0;
            }
        }

        &.no-has-prev.has-next {
            :deep(.v-window__controls) {
                justify-content: right;
                left: 24px;
                padding: 0;
            }
        }


        &.has-prev.has-next {
            :deep(.v-window__controls) {
                justify-content: center;
                padding: 0;

                // This container has position absolute on top of the main card.
                // The intention is to keep the gap between the previous and next card using the
                // main card width and some extra space.
                gap: calc(((var(--main-width) * 1) + 18) * 1px) !important;

                .carousel-card:first-child .v-overlay {
                    // TODO: gradient to try to making whole content diffuse is not working
                    //background: linear-gradient(to left, white 0%, white 50%, white 100%);
                    opacity: 1;
                }

                .carousel-card:last-child {
                    // TODO: gradient to try to making whole content diffuse is not working
                    //background: linear-gradient(
                    //    to left,
                    //    white 0%,
                    //    /* Diffuse white on the left */ white 50%,
                    //    /* Fully white from the center */ white 100% /* Stays white at the right edge */
                    //);
                    opacity: 1;
                }
            }
        }
    }
</style>
