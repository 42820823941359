import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowsLeftRight,
    faArrowUpRightDots,
    faBook,
    faBuilding,
    faBullseye,
    faCaretDown,
    faChalkboardUser,
    faChartLine,
    faCheck,
    faChevronDown,
    faChevronUp,
    faCoins,
    faCompass,
    faFolderOpen,
    faGraduationCap,
    faHand,
    faHandHoldingHeart,
    faHome,
    faLifeRing,
    faMap,
    faMessage,
    faPaintBrush,
    faPaperclip,
    faQuestion,
    faRoad,
    faRocket,
    faSearch,
    faStairs,
    faSyncAlt,
    faThumbsDown,
    faThumbsUp,
    faTree,
    faUsers,
    faUserTie,
} from '@fortawesome/free-solid-svg-icons';

/**
 * When integrating Font Awesome icons into your project, it's important to understand
 * that each icon must be explicitly imported and added to the library.
 * Unlike some icon libraries that provide all icons by default,
 * Font Awesome requires you to manually import each icon you wish to use.
 *
 * This approach offers several benefits:
 *
 * Reduced Bundle Size: By only including the icons that are actually utilized
 * in your project, you can significantly minimize your application's overall
 * bundle size, leading to faster load times and improved performance.
 *
 * Granular Control: Manually importing icons allows for better control over
 * which icons are available in your application, ensuring that only the necessary assets are loaded.
 */
export function registerFontAwesomePackage() {
    library.add([
        faArrowLeft,
        faArrowRight,
        faCaretDown,
        faChevronUp,
        faChevronDown,
        faThumbsDown,
        faThumbsUp,
        faPaperclip,
        faSearch,
        faArrowUpRightDots,
        faCompass,
        faMessage,
        faLifeRing,
        faBullseye,
        faBook,
        faHome,
        faTree,
        faQuestion,
        faRoad,
        faArrowDown,
        faArrowRight,
        faUsers,
        faHand,
        faCheck,
        faBuilding,
        faCoins,
        faMap,
        faChartLine,
        faStairs,
        faArrowsLeftRight,
        faSyncAlt,
        faUserTie,
        faFolderOpen,
        faChalkboardUser,
        faPaintBrush,
        faRocket,
        faHandHoldingHeart,
        faGraduationCap,
    ]);
}
