<template>
    <GrowPathwaysGuide />
    <!-- TODO: CORE-261 do we need this success modal     -->
    <AppGuideModal
        v-model="showNewSuccess"
        title="Well done for taking this first step toward overcoming your challenge!"
        action-text="Continue"
        @submit="onContinueAfterSave"
    >
        <template #default>
            Your challenge pathway is locked in! Check back in often to reflect on the plan you’ve
            made and track your progress. Remember I’m always here if you need help with any of your
            activities. I’m excited to see how you get on.
        </template>
    </AppGuideModal>

    <!-- TODO: CORE-261 do we need this success modal     -->
    <AppGuideModal
        v-model="showUpdateSuccess"
        title="Well done for updating your activities to make sure you stay on track!"
        action-text="Continue"
        @submit="onContinueAfterSave"
    >
        <template #default>
            Your challenge pathway has been updated! Check back in often to reflect on the plan
            you’ve made and track your progress. Remember I’m always here if you need help with any
            of your activities. I’m excited to see how you get on.
        </template>
    </AppGuideModal>

    <app-page-content>
        <template #intro>
            <app-page-content-intro title="Sequence the activities and assign dates for the phases">
                <div class="text-paragraph-md font-weight-600 mb-4">
                    {{ jobsStore.job?.title }}
                </div>

                Commit to a plan of activities to ensure you are ready to transition in the
                time-frame you have chosen. We've grouped the activities you have chosen into three
                stages, and given your vision timeframe, have suggested some end dates for each
                phase.
                <GrowPatchActivityCategoryDescription  class="mt-8"/>
            </app-page-content-intro>
        </template>

        <v-container
            class="pa-0 d-flex job-activities-sequencing-container"
            v-for="(action, index) in _selectedActivities || []"
            :key="`${index}`"
        >
            <app-sorting-controls
                :position="index + 1"
                :total="_selectedActivities.length"
                @move-up="store.moveActionUp(index)"
                @move-down="store.moveActionDown(index)"
            />
            <GrowPathApproachActivityListItem
                :item="action as PathAcceptedJobActivity"
                class="mb-2 w-100"
            />
        </v-container>
    </app-page-content>

    <app-page-footer>
        <template v-slot:actions>
            <app-page-tabs-footer-actions
                close-text="Back"
                @on-back="navigationStore.setPrevious"
                title="Continue"
                :submit-disabled="isSubmitDisabled"
                @on-submit="onSave"
            />
        </template>
    </app-page-footer>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import AppSortingControls from '@/components/common/AppSortingControls.vue';
    import AppPageContentIntro from '@/components/common/app-page/AppPageContentIntro.vue';
    import { storeToRefs } from 'pinia';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import { useGrowJobActivityStore } from '@/store/grow/jobActivityStore';
    import GrowPathApproachActivityListItem from '@/components/grow/path/approach/GrowPathApproachActivityListItem.vue';
    import type { PathAcceptedJobActivity } from '@/store/grow/types';
    import { JobActivityCategory } from '@/api/types/grow/jobs';
    import GrowPathwaysGuide from '@/components/grow/GrowPathwaysGuide.vue';
    import { useGrowJobsStore } from '@/store/grow/jobsStore';
    import GrowPatchActivityCategoryDescription from '@/components/grow/path/approach/GrowPatchActivityCategoryDescription.vue';

    const jobsStore = useGrowJobsStore();
    const store = useGrowJobActivityStore();

    const { _selectedActivities } = storeToRefs(store);

    const showNewSuccess = ref(false);
    const showUpdateSuccess = ref(false);

    const navigationStore = useNavigationStore();

    const onSave = async () => {
        await store.saveApproach();

        await navigationStore.setNext();
    };

    const onContinueAfterSave = async () => {};

    const isSubmitDisabled = computed(() => {
        // Some basic checks to ensure the selected actions are valid. Need to come back to this
        // I only focused on editable fields for now
        return store._selectedActivities.some(
            (activity) =>
                !activity.title || !activity.description || !activity.frequency || !activity.due_at,
        );
    });
</script>

<style lang="scss" scoped>
    .job-activities-sequencing-container {
        min-height: 300px;
        height: fit-content;
    }
</style>
