<template>
    <HomeStatementCard
        :color="colors['grow-button']"
        title="My vision"
        description="I am working towards achieving a vision for myself that will ensure my work
            contributes to my lifestyle and aligns with my values."
        :has-statement="true"
    >
        <v-container class="pa-0" v-if="isLifeVisionCompleted && lifeVision">
            <GrowPathVisionStatement
                :value="lifeVision"
                :highlight-color="colors['custom-neutral-black']"
                class="text-misc-story"
            />
            <GrowPathVisionStatementEditOption @edit="onEditVision" />
        </v-container>
        <v-container class="pa-0" v-else>
            <StorySectionActivity
                title="Life vision"
                :to="growLifeVisionRoute"
                :status="status"
                :expanded="true"
                class="fill-height px-4 pt-4 ma-0 rounded-lg d-flex flex-column"
            >
            </StorySectionActivity>
        </v-container>
    </HomeStatementCard>
</template>

<script setup lang="ts">
    import GrowPathVisionStatement from '@/components/grow/path/vision/GrowPathVisionStatement.vue';
    import { BaseColors } from '@/plugins/vuetify/theme';
    import HomeStatementCard from '@/components/common/home/statement/HomeStatementCard.vue';
    import { useLifeVisionStore } from '@/store/grow/lifeVisionStore';
    import { computed } from 'vue';
    import GrowPathVisionStatementEditOption from '@/components/grow/path/vision/GrowPathVisionStatementEditOption.vue';
    import { useRouter } from 'vue-router';
    import { growLifeVisionRoute } from '@/router/routes';
    import { makeActivityStatus } from '@/store/story/makeActivityStatus';
    import StorySectionActivity from '@/components/story/section/activity/StorySectionActivity.vue';

    const colors = BaseColors;

    const store = useLifeVisionStore();

    const isLifeVisionCompleted = computed(() => {
        return store.isLifeVisionCompleted;
    });

    const lifeVision = computed(() => {
        return store.value;
    });

    const status = computed(() => {
        return makeActivityStatus(store.isLifeVisionCompleted);
    });

    const router = useRouter();

    const onEditVision = async () => {
        await router.push(growLifeVisionRoute);
    };
</script>

<style scoped lang="scss">
    :deep(.home-statement-card) {
        background: linear-gradient(180deg, #c1d692 0%, #95b158 100%) !important;
    }
</style>
