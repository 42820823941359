import { type ApiResponse, get, post, put } from "@/services/apiClient";
import type { ApiJobOption } from "@/api/types/grow/jobs";

export async function postGenerateJobOptions(
    canvasId: number,
    growPathOptionId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = post<void, void>(`canvas/${canvasId}/grow/path/${growPathOptionId}/job/generate/`);
    return request(accessToken);
}

export async function getJobOptions(
    canvasId: number,
    growPathOptionId: number,
    accessToken: string,
): Promise<ApiResponse<ApiJobOption[]>> {
    const request = get<ApiJobOption[]>(`canvas/${canvasId}/grow/path/${growPathOptionId}/job/`);
    return request(accessToken);
}

export async function putAcceptJobOption(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<void, void>(`canvas/${canvasId}/grow/path/${growPathOptionId}/job/${jobOptionId}/accept`);
    return request(accessToken);
}
