import type { PathAcceptedJobActivity, CanvasGrowLifeVision, PathJobActivity, JobActivityCreateData } from '@/store/grow/types';
import { ActionType } from '@/api/types/plan/currentChallengeAction';
import { JobActivityCategory } from '@/api/types/grow/jobs';

export function isLifeVisionCompleted(lifeVision: CanvasGrowLifeVision | null): boolean {
    if (!lifeVision) return false;

    const { timeframe, location, passions, way_of_working, what_i_imagine } = lifeVision;
    return !!timeframe && !!location && !!passions && !!way_of_working && !!what_i_imagine;
}

export function isJobOptionActivity(action: any): action is PathJobActivity {
    return action.type === ActionType.UserCreated && action.id !== undefined;
}
// Guard clause function to ensure action is of type 'Action'
export function isAcceptedJobActivity(action: any): action is PathAcceptedJobActivity {
    return action.state === 'accepted' && action.order !== undefined;
}

export function makeNewJobActivity(): JobActivityCreateData {
    return {
        category: JobActivityCategory.Skills,
        order: null,
        title: '',
        description: '',
        long_description: '',
        links: '',
        frequency: 'daily',
    };
}
