<template>
    <v-card min-height="80" class="rounded-8 pa-4 bg-white app-card box-shadow-none">
        <v-card-text class="mx-0 px-2 text-paragraph-md">
            <v-row no-gutters class="mt-4 mb-5">
                <v-text-field
                    variant="outlined"
                    v-model="current!.title"
                    label="Title"
                    persistent-placeholder
                    placeholder="The title of this activity"
                />
            </v-row>

            <v-row no-gutters class="mb-5">
                <v-textarea
                    variant="outlined"
                    :counter="true"
                    :persistent-counter="true"
                    rows="6"
                    :rules="[
                        (v) =>
                            (v || '').length <= DESCRIPTION_MAX_LIMIT ||
                            'Description must be 500 characters or less',
                    ]"
                    v-model="current!.description"
                    persistent-placeholder
                    placeholder="Record briefly what this activity is about."
                    label="Description"
                />
            </v-row>

            <v-row no-gutters>
                <v-select
                    v-model="current!.category"
                    :items="actionTypeOptions"
                    label="Activity Type"
                    item-value="value"
                    item-title="text"
                    outlined
                />
            </v-row>
        </v-card-text>

        <v-card-actions>
            <SubmitButton text="Save" :disabled="!isSubmitEnabled" @click="onSaveClick" />

            <CloseButton @click="store.cancelNew"> Cancel</CloseButton>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
    import { storeToRefs } from 'pinia';
    import SubmitButton from '@/components/footer-actions/SubmitButton.vue';
    import { computed } from 'vue';
    import CloseButton from '@/components/footer-actions/CloseButton.vue';
    import { useGrowJobActivityStore } from '@/store/grow/jobActivityStore';
    import { JobActivityCategory } from '@/api/types/grow/jobs';

    const store = useGrowJobActivityStore();
    const { current } = storeToRefs(store);

    const emit = defineEmits(['edit-click', 'delete-click', 'change-type']);

    const DESCRIPTION_MAX_LIMIT = 500;

    // Map enum values to titles for the select options
    const actionTypeOptions = [
        { value: JobActivityCategory.Connections, text: 'connections' },
        { value: JobActivityCategory.Research, text: 'research' },
        { value: JobActivityCategory.Experience, text: 'experiences' },
        { value: JobActivityCategory.Skills, text: 'skills' },
    ];

    const isSubmitEnabled = computed(() => {
        return (
            !!current?.value?.title && !!current?.value?.description && !!current?.value?.category
        );
    });

    function onSaveClick() {
        store.saveNew();
    }
</script>

<style lang="scss">
    // TODO This is duplicated in a few places, so it should be moved to a shared file
    // See SkillListItemForm and PersonalStrengthListItemForm

    .v-card.app-card {
        border: 1px dashed;
        border-color: rgb(var(--v-theme-custom-neutral-grey-700));
        border-image-slice: 4;
    }
</style>
