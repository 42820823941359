<template>
    <app-global-loader v-if="jobsStore.isLoadingJobs" />
    <app-page-content v-else :fill-height="true">
        <v-container class="d-flex flex-column pa-0 ga-10">
            <v-container class="w-100 text-center pa-0" max-width="600">
                <div class="text-heading-h1 mb-5">Your potential jobs within these pathways</div>
                <div class="text-paragraph-md">
                    Achieving your vision starts with knowing what jobs you might wish to take on
                    next. Based on the pathway choices you have made, we have matched the following
                    jobs to your preferences, and identified any gaps in skills or experience you
                    will want to work on closing. Select as many jobs as you'd like to start working
                    towards.
                </div>
            </v-container>

            <v-container class="d-flex flex-column pa-0 ga-4">
                <GrowPathJobsSelectionJobOption
                    v-for="(jobOption, index) in jobsStore.pathJobOptions"
                    :key="index"
                    :value="jobOption"
                    :path-title="pathwaysStore.path!.title"
                    :path-description="pathwaysStore.path!.description"
                    :is-selected="jobsStore.isJobSelected(jobOption)"
                    @on-selected="jobsStore.toggleJobSelection(jobOption)"
                />
            </v-container>
        </v-container>
    </app-page-content>
</template>

<script setup lang="ts">
    import { useGrowPathwaysStore } from '@/store/grow/pathwaysStore';
    import { useGrowJobsStore } from '@/store/grow/jobsStore';
    import GrowPathJobsSelectionJobOption from '@/components/grow/path/pathways/GrowPathJobsSelectionJobOption.vue';

    const pathwaysStore = useGrowPathwaysStore();
    const jobsStore = useGrowJobsStore();
</script>
