<template>
    <HomeAbout
        title="About Grow"
        :description="aboutDescription"
        :image="aboutSrc"
        :process-cards="processCards"
        :process-subtitle="processSubtitle"
        :submit-disabled="!canAccessGrowth"
        :submit-tooltip="submitTooltip"
    />
</template>

<script setup lang="ts">
    import aboutSrc from '@/assets/images/grow/about.svg';
    import HomeAbout from '@/components/common/home/HomeAbout.vue';

    import { useGrowAccess } from '@/composables/grow/useGrowAccess';

    const aboutDescription =
        'We already know a lot about you. So now lets craft a pathway forward for you that will help you reach your full career goals.';

    const processSubtitle =
        'We’ve designed this process based on a proven methodology.  It’s simple to follow and is intuitively designed to help you make the most informed choices about your future.';

    const processCards = [
        {
            order: 1,
            title: 'Vision & Preferences',
            description:
                'Get clear on the future you are trying to design, by having a clear vision and defining your preferences.  We help you make the hard trade-offs so you have clarity on what you most want.',
        },
        {
            order: 2,
            title: 'Pathways & Jobs',
            description:
                'There are many ways to work now, so making choices about the pathway best suited to you is a great first step.  Then we can show you the types of jobs you could do and what gaps you might need to close.',
        },
        {
            order: 3,
            title: 'Action plan',
            description:
                'Transitioning and growing in your career takes work.  We make it easy by helping you map out the steps and the activities you will need to take to ensure your vision becomes a reality.',
        },
    ];

    const { canAccessGrowth, submitTooltip } = await useGrowAccess();
</script>
