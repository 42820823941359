import { useUsersStore } from "@/store/user/store";
import { computed } from "vue";
import { useStoryAccess } from '@/composables/common/useStoryAccess';

export const useGrowAccess = async () => {
    const userStore = useUsersStore();
    const hasGPFeature = computed(() => userStore.hasGPFeature);

    const { hasUvp, createTooltip } = await useStoryAccess();

    const canAccessGrowth = computed(() => hasGPFeature.value && hasUvp.value);
    const submitText = computed(() => (hasGPFeature.value ? 'Go to Grow' : 'Coming soon'));
    const submitTooltip = computed(() => (hasGPFeature.value ? createTooltip('Grow').value : ''));

    return {
        canAccessGrowth,
        submitText,
        submitTooltip
    };
};
