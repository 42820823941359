<template>
    <v-card elevation="0" class="text-custom-neutral-grey-900 w-100">
        <v-card-title class="pa-0" v-if="title">
            <!-- Note that an extra div is added, as if not v-card-title overrides the font styles -->
            <div
                class="text-heading-h2 text-wrap pa-0"
                :class="subtitle ? 'mb-4' : isDesktop ? 'mb-8' : 'mb-6'"
            >
                {{ title }}
            </div>
        </v-card-title>

        <p v-if="subtitle" class="mb-8 text-paragraph-md" v-html="subtitle" />

        <slot />
    </v-card>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { title } = defineProps<{
        title?: string;
        subtitle?: string;
    }>();

    const { isDesktop } = useIsDesktop();
</script>
