import { computed } from 'vue';
import { useStoryStore } from '@/store/story/store';
import { useCanvasStore } from '@/store/canvas/store';

export const useNavigationTitles = () => {
    const storyStore = useStoryStore();

    const hasUvp = computed(() => storyStore.isUvpCompleted);
    const solveTitle = computed(() => hasUvp.value ? 'Solve' : 'Solve (finish your Story for access)');
    const growTitle = computed(() => hasUvp.value ? 'Grow' : 'Grow (finish your Story for access)');

    return {
        hasUvp,
        solveTitle,
        growTitle
    };
}
