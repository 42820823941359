<template>
    <div
        class="mt-4 font-weight-regular d-print-none"
        :class="isDesktop ? 'text-paragraph-md' : 'text-paragraph-sm'"
    >
        Doesn't sound like you?
        <a class="font-weight-600 text-decoration-underline cursor-pointer" @click="emit('edit')">
            Edit my answers
        </a>
    </div>
</template>

<script setup lang="ts">
    import { useLifeVisionStore } from '@/store/grow/lifeVisionStore';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const store = useLifeVisionStore();

    const emit = defineEmits(['edit']);

    const { isDesktop } = useIsDesktop();
</script>
