import { create, fetch, fetchAllowNull, update } from '@/services/base';
import {
    getLifeVision,
    getPredefinedLifeVisionItems,
    postLifeVision,
    putLifeVision,
} from '@/services/grow/life-vision/api';
import type {
    ApiGrowLifeVision,
    ApiGrowLifeVisionCreateOrUpdate,
    ApiGrowPredefinedItem,
} from '@/api/types/grow/lifeVision';

export async function fetchGrowPredefinedLifeVisionItems(
    accessToken: string,
): Promise<ApiGrowPredefinedItem[]> {
    return fetch(
        'predefined-life-vision-items',
        async () => await getPredefinedLifeVisionItems(accessToken),
    );
}

export async function fetchGrowLifeVision(
    canvasId: number,
    accessToken: string,
): Promise<ApiGrowLifeVision | null> {
    return fetchAllowNull('life-vision', async () => await getLifeVision(canvasId, accessToken));
}

export async function updateGrowLifeVision(
    canvasId: number,
    lifeVisionId: number,
    updateData: ApiGrowLifeVisionCreateOrUpdate,
    accessToken: string,
): Promise<void> {
    return update(
        'update-life-vision',
        async () => await putLifeVision(canvasId, lifeVisionId, updateData, accessToken),
    );
}

export async function createGrowLifeVision(
    canvasId: number,
    updateData: ApiGrowLifeVisionCreateOrUpdate,
    accessToken: string,
): Promise<ApiGrowLifeVision> {
    const [lifeVision, _response] = await create(
        'create-life-vision',
        async () => await postLifeVision(canvasId, updateData, accessToken),
    );

    return lifeVision
}
