import { asyncTimeout } from '@/lib';
import type { AxiosRequestConfig } from 'axios';
import { throwIfAborted } from '@/lib/genericAbortSignal';
import type { ApiStory } from '@/api/types/canvas/apiStory';
import { fetchStory } from '@/services/story/service';

// const log = taggedLogger('poll-challenge');

/** 5 minute timeout */
const DEFAULT_POLL_TIMEOUT_MILLISECONDS = 60 * 1000 * 5;

/** Poll every 2 seconds */
const POLL_INTERVAL_MILLISECONDS = 2000 as const;

/** Log every 5th attempt at info level */
const LOG_ATTEMPT_AT_INFO = 5 as const;

export class TimeoutError extends Error {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, TimeoutError.prototype);
    }
}

/**
 * Repeatedly fetch a resource until a condition is met or a timeout reached
 *
 * @throws TimeoutError if the timeout elapses.
 */
export async function pollStory(
    canvasId: number,
    condition: (story: ApiStory) => boolean,
    config: AxiosRequestConfig & { pollTimeout?: number },
    accessToken: string,
): Promise<ApiStory> {
    // Calculate the time we need to time-out
    const timeout = config?.pollTimeout ?? DEFAULT_POLL_TIMEOUT_MILLISECONDS;
    const timeoutTime = Date.now() + timeout;

    // Attempt to fetch the template
    const fetch = async (): Promise<ApiStory | null> => {
        const story = await fetchStory(canvasId, accessToken);
        return condition(story) ? story : null;
    };

    for (let attempt = 1; ; ++attempt) {
        throwIfAborted(config?.signal);

        // Log every Nth attempt at info level
        const logLevel = attempt % LOG_ATTEMPT_AT_INFO === 0 ? 'info' : 'debug';
        console.log(logLevel, 'Attempt %s to fetch story...', attempt);

        // Attempt to fetch the user challenge
        const story = await fetch();
        if (story) {
            if (attempt > 1) {
                console.info('Story fetched after %d attempts', attempt);
            }
            return story;
        }
        throwIfAborted(config?.signal);

        if (Date.now() >= timeoutTime) {
            throw new TimeoutError('Timed-out while attempting to fetch challenge');
        }

        await asyncTimeout(POLL_INTERVAL_MILLISECONDS, config?.signal);
    }
}
