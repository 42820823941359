export enum PathOptionTitle {
    GROWTH = 'growth',
    LADDER = 'ladder',
    HORIZONTAL = 'horizontal',
    PIVOT = 'pivot',
    LEADERSHIP = 'leadership',
    PORTFOLIO = 'portfolio',
    CONSULTANT_COACH = 'consultant/coach',
    CREATIVE = 'creative',
    ENTREPRENEUR = 'entrepreneur',
    PHILANTHROPY = 'philanthropy',
    ACADEMIC = 'academic',
}
export const PREDEFINED_PATH_DEFINITION = {
    [PathOptionTitle.GROWTH]: {
        icon: 'chart-line', // Represents growth and progress.
    },
    [PathOptionTitle.LADDER]: {
        icon: 'stairs', // Represents climbing a career ladder.
    },
    [PathOptionTitle.HORIZONTAL]: {
        icon: 'arrows-left-right', // Represents lateral movement or horizontal career shifts.
    },
    [PathOptionTitle.PIVOT]: {
        icon: 'sync-alt', // Represents a career pivot or change.
    },
    [PathOptionTitle.LEADERSHIP]: {
        icon: 'user-tie', // Represents leadership and management roles.
    },
    [PathOptionTitle.PORTFOLIO]: {
        icon: 'folder-open', // Represents managing a portfolio of projects or skills.
    },
    [PathOptionTitle.CONSULTANT_COACH]: {
        icon: 'chalkboard-user', // Represents consulting, coaching, or mentoring.
    },
    [PathOptionTitle.CREATIVE]: {
        icon: 'paint-brush', // Represents creativity, arts, and design-related careers.
    },
    [PathOptionTitle.ENTREPRENEUR]: {
        icon: 'rocket', // Represents entrepreneurship and startups.
    },
    [PathOptionTitle.PHILANTHROPY]: {
        icon: 'hand-holding-heart', // Represents philanthropy and giving back to the community.
    },
    [PathOptionTitle.ACADEMIC]: {
        icon: 'graduation-cap', // Represents academia and research.
    },
} as const;
