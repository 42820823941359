import { RoutesName } from '@/router/routesName';

export type DeclaredRoute = {
    name: RoutesName;
    query?: any;
};

export const guideAdmin = { name: RoutesName.GuideAdmin };
export const profileRoute: DeclaredRoute = { name: RoutesName.Profile };
export const welcomeRoute: DeclaredRoute = { name: RoutesName.Welcome };
export const homeRoute: DeclaredRoute = { name: RoutesName.Home };

export const storyRoute: DeclaredRoute = { name: RoutesName.Story };
export const planRoute: DeclaredRoute = { name: RoutesName.Plan };
export const challengesHomeRoute: DeclaredRoute = { name: RoutesName.ChallengesHome };
export const challengePathwaysRoute: DeclaredRoute = { name: RoutesName.ChallengePathways };

export const challengeActivityRoute: DeclaredRoute = { name: RoutesName.ChallengesActivity };

export const challengeSequencingRoute: (id: number) => DeclaredRoute = (id: number) => ({
    name: RoutesName.SingleChallengeSequencing,
    params: { id: id },
});
export const futureAspirationPlanRoute: DeclaredRoute = { name: RoutesName.CareerPlan };

export const futureAspirationRoute: DeclaredRoute = { name: RoutesName.CanvasFutureAspirations };

export const personalValuesRoute = { name: RoutesName.CanvasPersonalValues };
export const skillsRoute = { name: RoutesName.CanvasSkills };
export const personalStrengthRoute = { name: RoutesName.CanvasPersonalStrength };

export const careerHistoryRoute: DeclaredRoute = {
    name: RoutesName.CareerHistoryExperiences,
    query: { tab: 'ch' },
};
export const memorableExperienceRoute = {
    name: RoutesName.CareerHistoryExperiences,
    query: { tab: 'me' },
};

export const growHomeRoute: DeclaredRoute = { name: RoutesName.GrowHome };
export const growPathRoute: DeclaredRoute = { name: RoutesName.GrowPath };
export const growLifeVisionRoute: DeclaredRoute = {
    name: RoutesName.GrowPath,
    query: { tab: 'vision' },
};
export const growPreferencesRoute: DeclaredRoute = {
    name: RoutesName.GrowPath,
    query: { tab: 'preferences' },
};
export const growPathsRoute: DeclaredRoute = {
    name: RoutesName.GrowPath,
    query: { tab: 'pathways' },
};

export const growJobsRoute: DeclaredRoute = {
    name: RoutesName.GrowPath,
    query: { tab: 'jobs' },
};

export const growActivitiesRoute: DeclaredRoute = {
    name: RoutesName.GrowPath,
    query: { tab: 'activities' },
};

export const growProgressRoute: DeclaredRoute = {
    name: RoutesName.GrowPath,
    query: { tab: 'progress' },
};

export const personalityTypeRoute = { name: RoutesName.CanvasPersonalityType };
export const assistantRoute = { name: RoutesName.Assistant };
export const privacyRoute = { name: RoutesName.Privacy };
export const termsRoute = { name: RoutesName.Terms };
