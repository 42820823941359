<template>
    <v-container
        v-if="props.renderHtml"
        class="d-flex flex-column pa-0"
        height="fit-content"
        :class="isDesktop ? 'ga-8' : 'ga-5'"
    >
        <!-- If you change this statement update statementAsText below & the generate_life_vision_statement function in the backend -->
        <div>
            The dream is that in
            <span class="life-vision-input-word text-lowercase">{{ value.timeframe }}</span>
            I will be living
            <span class="life-vision-input-word text-lowercase">{{ value.location }}</span
            >, embracing my passions for
            <span class="life-vision-input-word text-lowercase">{{ value.passions }}</span
            >, and working
            <span class="life-vision-input-word text-lowercase">{{ value.way_of_working }}</span
            >.
        </div>

        <div>
            My one word for what I'm imagining is
            <span class="life-vision-input-word text-lowercase">{{ value.what_i_imagine }}</span
            >.
        </div>
    </v-container>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import type { CanvasGrowLifeVision } from '@/store/grow/types';
    import { computed } from 'vue';

    const { isDesktop } = useIsDesktop();

    const props = withDefaults(defineProps<{
        value: CanvasGrowLifeVision;
        highlightColor?: string,
        renderHtml?: boolean
    }>(), {
        renderHtml: true
    });

    // If you change this statement update renderHtml above &
    const statementAsText = computed(() => {
        return `
            The dream is that in ${props.value.timeframe}
            I will be living ${props.value.location},
            embracing my passions for ${props.value.passions},
            and working ${props.value.way_of_working}.
            My one word for what I'm imagining is ${props.value.what_i_imagine}.
        `;
    });

    defineExpose({ statementAsText });
</script>

<style scoped lang="scss">
    .life-vision-input-word {
        text-underline-offset: 12px; // Moves the underline away of the text
        text-decoration: underline;
        text-decoration-color: v-bind(highlightColor);
        font-style: normal;
    }
</style>
