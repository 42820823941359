<template>
    <StorySectionActivityInlineList
        label="Needs"
        :items="needsItems"
        class="w-100"
    />
    <StorySectionActivityInlineList
        label="Nice"
        :items="niceItems"
        class="w-100"
    />
    <StorySectionActivityInlineList
        label="Bonus"
        :items="bonusItems"
        class="w-100"
    />
</template>

<script setup lang="ts">
    import StorySectionActivityInlineList from '@/components/story/section/activity/StorySectionActivityInlineList.vue';
    import { useGrowPreferencesStore } from '@/store/grow/preferencesStore';
    import { computed } from 'vue';
    import type { StoryActivity } from '@/store/story/types';

    const store = useGrowPreferencesStore();

    // this is needed for correct rendering on the Story page, do not remove
    defineProps<{
        item?: StoryActivity;
    }>();

    const needsItems = computed(() => {
        return store.needList.map((item) => item.title);
    });

    const niceItems = computed(() => {
        return store.niceList.map((item) => item.title);
    });

    const bonusItems = computed(() => {
        return store.bonusList.map((item) => item.title);
    });
</script>
