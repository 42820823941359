import { useCanvasStore } from '@/store/canvas/store';
import { useStoryStore } from '@/store/story/store';
import { computed } from 'vue';
import type { ComputedRef } from 'vue';

export const useStoryAccess = async () => {
    const canvasStore = useCanvasStore();
    const storyStore = useStoryStore();

    // make sure these are loaded so we can access the story UVP
    await canvasStore.load();
    await storyStore.load();

    const hasUvp = computed(() => storyStore.isUvpCompleted);
    const baseTooltip = 'is available when you complete your Story. Completion occurs when you have your Superpower';

    const createTooltip = (feature: string): ComputedRef<string> => {
        return computed(() => (!hasUvp.value ? `${feature} ${baseTooltip}` : ''));
    };

    return {
        hasUvp,
        createTooltip
    };
};
