<template>
    <app-page-content :fill-height="true">
        <v-container class="d-flex flex-column pa-0 ga-10">
            <v-container class="w-100 text-center pa-0" max-width="600">
                <div class="text-heading-h1 mb-5">Suitability matching</div>
                <div class="text-paragraph-md">
                    There are many ways to work now. Everybody wonders if they are suited to working
                    a different way. Let's find out!
                    <br />
                    <br />
                    Based on your preferences, we've analysed the pathways most suited to you and
                    shown them in order of fit. Select up to 2 pathways that appeal, and we will
                    show you some jobs we think you're suited to in those pathways.
                </div>
            </v-container>

            <v-container class="d-flex flex-column pa-0 ga-4">
                <GrowPathPathwaysSuitabilityPathOption
                    v-for="(pathOption, index) in store.pathOptions"
                    ref="pathRefs"
                    :key="index"
                    :value="pathOption"
                    :expand-default="store.anySelected ? store.isSelected(pathOption) : index === 0"
                    :is-selected="store.isSelected(pathOption)"
                    @on-selected="store.toggleSelection(pathOption)"
                />
            </v-container>
        </v-container>
    </app-page-content>
</template>

<script setup lang="ts">
    import { useGrowPathwaysStore } from '@/store/grow/pathwaysStore';
    import GrowPathPathwaysSuitabilityPathOption from '@/components/grow/path/pathways/GrowPathPathwaysSuitabilityPathOption.vue';
    import { type ComponentPublicInstance, nextTick, onMounted, ref } from 'vue';

    const store = useGrowPathwaysStore();

    type PathOptionComponent = ComponentPublicInstance<{ $el: HTMLElement }>;
    const pathRefs = ref<PathOptionComponent[]>([]);

    onMounted(() => {
        nextTick(() => {
            const selectedIndex = store.pathOptions.findIndex((option) => store.isSelected(option));
            const foundRef = pathRefs.value[selectedIndex];
            if (selectedIndex !== -1 && foundRef) {
                foundRef.$el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            }
        });
    });
</script>
