export enum UserActivityArea {
    PersonalValues = 'PersonalValues',
    PersonalityType = 'PersonalityType',
    CurrentChallenges = 'CurrentChallenges',
    Profile = 'Profile',
    Welcome = 'Welcome',
    FutureAspiration = 'FutureAspiration',
    LearnedExperiences = 'LearnedExperiences',
    Skills = 'Skills',
    PersonalStrengths = 'PersonalStrengths',
    UniqueValueProposition = 'UniqueValueProposition',
    Canvas = 'Canvas',
    Story = 'Story',
    Plan = 'Plan',
    ChallengePath = 'ChallengePath',
    Intro = 'Intro',
    GuideOverview = 'GuideOverview',
    PlanCurrent = 'PlanCurrent',
    PlanFuture = 'PlanFuture',
    GrowPath = 'GrowPath',

    Home = 'Home',
    Privacy = 'Privacy',
    Terms = 'Terms',
}

export enum UserActivityEvent {
    Entered = 'Entered',
    Exited = 'Exited',
    Completed = 'Completed',
    GuideOpened = 'GuideOpened',
    GuideMessaged = 'GuideMessaged',
}

type BaseUserActivity = {
    area: UserActivityArea;
    event: UserActivityEvent;
    user_id: number;
};
export type UserActivity = BaseUserActivity & {
    created_at: string;
};

export type CreateUserActivityData = {
    area: UserActivityArea;
    event: UserActivityEvent;
    user_id: number;
};

export interface CountMetric {
    metric_name: string;
    metric_value: number;
    metric_dimensions: {};
}
