import { useStoryAccess } from '@/composables/common/useStoryAccess';

export const useSolveAccess = async () => {
    const { hasUvp, createTooltip } = await useStoryAccess();

    const canAccessSolve = hasUvp;
    const submitTooltip = createTooltip('Solve');

    return {
        canAccessSolve,
        submitTooltip
    };
};
