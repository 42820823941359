import { defineStore } from 'pinia';
import { useCanvasStore } from '@/store/canvas/store';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
    setInUpdating,
} from '@/store/common/dataState';
import { cloneDeep, isEqual } from 'lodash';
import type { CanvasGrowLifeVision, LifeVisionStoreState } from '@/store/grow/types';
import {
    createGrowLifeVision,
    fetchGrowLifeVision,
    fetchGrowPredefinedLifeVisionItems,
    updateGrowLifeVision,
} from '@/services/grow/life-vision/service';
import type { ApiGrowLifeVision, ApiGrowPredefinedItem } from '@/api/types/grow/lifeVision';
import { verify } from '@/store/verify';
import { isLifeVisionCompleted } from '@/store/grow/util';
import { useGrowPathwaysStore } from '@/store/grow/pathwaysStore';

export function makeEmpty(): CanvasGrowLifeVision {
    return {
        timeframe: '',
        location: '',
        passions: '',
        way_of_working: '',
        what_i_imagine: '',
    };
}

export const useLifeVisionStore = defineStore({
    id: 'grow-life-vision-store',
    state: (): LifeVisionStoreState => ({
        options: [],
        original: null,
        value: null,

        statementView: false,

        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        isLifeVisionCompleted(state): boolean {
            const value = state.value;
            return !!value && isLifeVisionCompleted(value);
        },
        isSubmitEnabled(state): boolean {
            return this.isLifeVisionCompleted;
        },
        isNew(state): boolean {
            return !state.value || (!!state.value && state.value.id === undefined);
        },
        isEdit(state): boolean {
            return !this.isNew;
        },
        locationItems(state): ApiGrowPredefinedItem[] {
            return state.options.filter((item) => item.type === 'location');
        },
        waysOfWorkingItems(state): ApiGrowPredefinedItem[] {
            return state.options.filter((item) => item.type === 'way-of-working');
        },
        timeframeItems(state): ApiGrowPredefinedItem[] {
            return state.options.filter((item) => item.type === 'timeframe');
        },
        isDirty(state): boolean {
            return !isEqual(state.value, state.original);
        },
        isAreaComplete(state): boolean {
            return this.isLifeVisionCompleted && this.isEdit;
        },
        needsLoading(state): boolean {
            return state.original === null;
        },
    },
    actions: {
        async load() {
            if (!this.needsLoading) {
                console.info('Life vision already loaded');
                return;
            }

            console.info('Loading life vision ...');

            setInLoading(this);

            try {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();
                const predefinedItems = await fetchGrowPredefinedLifeVisionItems(accessToken);
                const lifeVision = await fetchGrowLifeVision(canvasId, accessToken);

                this._setOptions(predefinedItems);
                this._setLifeVision(lifeVision);

                // Initialising the statement view
                this.statementView = this.isLifeVisionCompleted;

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        async saveProgress(): Promise<void> {
            setInUpdating(this);
            try {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                const updateData = verify(this.value, 'life vision is not set');

                if (this.isNew) {
                    await createGrowLifeVision(canvasId, updateData, accessToken);
                } else {
                    const lifeVisionId = verify(this.value?.id, 'life vision id is not set');
                    if (this.isDirty) {
                        useGrowPathwaysStore().setNeedsUpdate();
                        await updateGrowLifeVision(canvasId, lifeVisionId, updateData, accessToken);
                    }
                }

                const updatedGrowLifeVision = await fetchGrowLifeVision(canvasId, accessToken);

                this._setLifeVision(updatedGrowLifeVision);

                setInIdle(this);
            } catch (error: unknown) {
                console.error(error instanceof Error ? error.message : error);
                setInError(this, error);
            }
        },

        // ###############################
        //
        // Side - effects
        //
        // ###############################
        _setLifeVision: function (rawValues: ApiGrowLifeVision | null): void {
            const current = rawValues === null ? makeEmpty() : rawValues;

            this.original = current;
            this.value = cloneDeep(current);
        },
        _setOptions: function (predefinedItems: ApiGrowPredefinedItem[]): void {
            this.options = predefinedItems;
        },
    },
});
