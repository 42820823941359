import { createRouter, createWebHashHistory, type RouteLocationNormalized } from 'vue-router';
import CallbackView from '@/views/auth/CallbackView.vue';
import { authGuard } from '@/auth/auth';
import { RoutesName } from '@/router/routesName';
import PersonalValuesView from '@/views/canvas/PersonalValuesView.vue';
import PersonalityTypeView from '@/views/canvas/PersonalityTypeView.vue';
import ProfileView from '@/views/ProfileView.vue';
import GuideAdmin from '@/views/admin/GuideAdmin.vue';
import CurrentChallengesView from '@/views/canvas/CurrentChallengesView.vue';
import SkillsView from '@/views/canvas/SkillsView.vue';
import FutureAspirationsView from '@/views/canvas/FutureAspirationsView.vue';
import StoryView from '@/views/StoryView.vue';
import PlanView from '@/views/PlanView.vue';
import ChallengePathView from '@/views/pathways/ChallengePathView.vue';
import CareerPlanView from '@/views/plan/CareerPlanView.vue';
import TermsOfUseView from '@/views/TermsOfUse.vue';
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue';
import { storyRoute } from '@/router/routes';
import LearnedExperienceView from '@/views/canvas/LearnedExperienceView.vue';
import PersonalStrengthsView from '@/views/canvas/PersonalStrengthsView.vue';
import WelcomeView from '@/views/WelcomeView.vue';
import { ref } from 'vue';
import { useLoadingStore } from '@/store/loadingStore';
import HomeView from '@/views/HomeView.vue';
import { CHALLENGE_PATH_THEME, GROW_PATH_THEME } from '@/plugins/vuetify';
import ChallengesHomeView from '@/views/pathways/ChallengesHomeView.vue';
import GrowHomeView from '@/views/grow/GrowHomeView.vue';
import GrowPathView from '@/views/grow/GrowPathView.vue';

export const routes = [
    {
        path: '/welcome',
        name: RoutesName.Welcome,
        component: WelcomeView,
        beforeEnter: authGuard,
    },
    {
        path: '/',
        name: RoutesName.Home,
        component: HomeView,
        beforeEnter: authGuard,
    },
    {
        path: '/profile',
        name: RoutesName.Profile,
        component: ProfileView,
        beforeEnter: authGuard,
    },
    {
        path: '/admin/guide',
        name: RoutesName.GuideAdmin,
        component: GuideAdmin,
        beforeEnter: authGuard,
    },
    {
        path: '/auth-callback',
        name: RoutesName.AuthCallback,
        component: CallbackView,
    },

    //
    //
    // Canvas related
    //
    //

    {
        path: '/canvas',
        name: RoutesName.Canvas,
        // To support users that have bookmarked this page.
        redirect(to: any) {
            return storyRoute;
        },
        beforeEnter: authGuard,
    },
    {
        path: '/canvas/personality-type',
        name: RoutesName.CanvasPersonalityType,
        component: PersonalityTypeView,
        beforeEnter: authGuard,
        meta: {
            hasNavAndFooter: true,
        },
    },
    {
        path: '/canvas/personal-strengths',
        name: RoutesName.CanvasPersonalStrength,
        component: PersonalStrengthsView,
        beforeEnter: authGuard,
        meta: {
            hasNavAndFooter: true,
        },
    },
    {
        path: '/canvas/personal-values',
        name: RoutesName.CanvasPersonalValues,
        component: PersonalValuesView,
        beforeEnter: authGuard,
        meta: {
            hasNavAndFooter: true,
        },
    },
    {
        path: '/canvas/skills',
        name: RoutesName.CanvasSkills,
        component: SkillsView,
        beforeEnter: authGuard,
        meta: {
            hasNavAndFooter: true,
        },
    },
    {
        path: '/canvas/future-aspirations',
        name: RoutesName.CanvasFutureAspirations,
        component: FutureAspirationsView,
        beforeEnter: authGuard,
        meta: {
            hasNavAndFooter: true,
        },
    },
    // {
    //     path: '/canvas/career-history',
    //     name: RoutesName.CareerHistory,
    //     component: CareerHistoryView,
    //     beforeEnter: authGuard,
    // },
    {
        path: '/canvas/career-history',
        name: RoutesName.CareerHistoryExperiences,
        component: LearnedExperienceView,
        beforeEnter: authGuard,
        meta: {
            hasNavAndFooter: true,
        },
    },

    //
    //
    // Story
    //
    //
    {
        path: '/story',
        name: RoutesName.Story,
        component: StoryView,
        beforeEnter: authGuard,
    },

    {
        path: '/plan',
        name: RoutesName.Plan,
        component: PlanView,
        beforeEnter: authGuard,
    },

    //
    //
    // Challenges section
    //
    //
    {
        path: '/challenges',
        name: RoutesName.ChallengesHome,
        component: ChallengesHomeView,
        beforeEnter: authGuard,
        meta: {
            theme: CHALLENGE_PATH_THEME,
        },
    },
    {
        path: '/challenges/current',
        name: RoutesName.ChallengesActivity,
        component: CurrentChallengesView,
        beforeEnter: authGuard,
        meta: {
            theme: CHALLENGE_PATH_THEME,
            hasNavAndFooter: true,
        },
    },
    {
        path: '/challenges/pathways/',
        name: RoutesName.ChallengePathways,
        component: ChallengePathView,
        beforeEnter: authGuard,
        meta: {
            theme: CHALLENGE_PATH_THEME,
            hasNavAndFooter: true,
        },
    },
    //
    //
    // Grow section
    //
    //
    {
        path: '/grow',
        name: RoutesName.GrowHome,
        component: GrowHomeView,
        beforeEnter: authGuard,
        meta: {
            theme: GROW_PATH_THEME,
        },
    },
    {
        path: '/grow/path/',
        name: RoutesName.GrowPath,
        component: GrowPathView,
        beforeEnter: authGuard,
        meta: {
            theme: GROW_PATH_THEME,
            hasNavAndFooter: true,
        },
    },
    {
        path: '/career-plan',
        name: RoutesName.CareerPlan,
        component: CareerPlanView,
        beforeEnter: authGuard,
    },
    {
        path: '/terms-of-use',
        name: RoutesName.Terms,
        component: TermsOfUseView,
        // beforeEnter: authGuard,
    },
    {
        path: '/privacy-policy',
        name: RoutesName.Privacy,
        component: PrivacyPolicyView,
        // beforeEnter: authGuard,
    },
];

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: routes,
});

export const previousRoute = ref<RouteLocationNormalized | null>(null);
// Track the previous route during navigation
router.beforeEach((to, from, next) => {
    previousRoute.value = from; // Capture the previous route name reactively
    next(); // Proceed with navigation
});

// Initialize the router guard from the store
setTimeout(() => {
    const loadingStore = useLoadingStore();
    loadingStore.initializeRouterGuard();
});

export default router;
