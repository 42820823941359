<template>
    <v-container class="pa-0 pt-0 mt-12 d-flex flex-column align-center justify-center ga-2">
        <div class="w-100 text-center text-heading-h5">Add to:</div>
        <v-container class="pa-0 mt-2 d-flex justify-center ga-2">
            <app-button
                v-for="(category, index) in categoryValues"
                class="text-center text-custom-neutral-grey-900 text-paragraph-md bg-white"
                @click="emit('click', category.value)"
                :key="index"
            >
                <span class="font-weight-600"> {{ category.title }}</span>
            </app-button>
        </v-container>
    </v-container>
</template>
<script setup lang="ts">
    import { categoryValues } from '@/components/grow/path/preferences/utils';

    const emit = defineEmits(['click']);
</script>
