import { asyncTimeout } from '@/lib';
import type { AxiosRequestConfig } from 'axios';
import { throwIfAborted } from '@/lib/genericAbortSignal';
import { fetchPathOptions } from '@/services/grow/pathways/service';
import type { ApiPathOption } from '@/api/types/grow/pathways';

// const log = taggedLogger('poll-challenge');

/** 5 minute timeout */
const DEFAULT_POLL_TIMEOUT_MILLISECONDS = 60 * 1000 * 5;

/** Poll every second */
const POLL_INTERVAL_MILLISECONDS = 10000 as const;

/** Log every 5th attempt at info level */
const LOG_ATTEMPT_AT_INFO = 5 as const;

export class PollEndpointTimeoutError extends Error {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, PollEndpointTimeoutError.prototype);
    }
}

/**
 * Repeatedly fetch a resource until a condition is met or a timeout reached
 *
 * @throws PollEndpointTimeoutError if the timeout.
 */
export async function pollUntil<T extends any>(
    name: string,
    fetch: () => Promise<T | null>,
    config: AxiosRequestConfig & { pollTimeout?: number },
): Promise<T> {
    // Calculate the time we need to time-out
    const timeout = config?.pollTimeout ?? DEFAULT_POLL_TIMEOUT_MILLISECONDS;
    const timeoutTime = Date.now() + timeout;

    for (let attempt = 1; ; ++attempt) {
        throwIfAborted(config?.signal);

        // Log every Nth attempt at info level
        const logLevel = attempt % LOG_ATTEMPT_AT_INFO === 0 ? 'info' : 'debug';
        console.log(logLevel, `Attempt %s to fetch ${name} ...`, attempt);

        // Attempt to fetch the
        const pathOptions = await fetch();
        if (pathOptions) {
            if (attempt > 1) {
                console.info(`${name} fetched after %d attempts`, attempt);
            }
            return pathOptions;
        }
        throwIfAborted(config?.signal);

        if (Date.now() >= timeoutTime) {
            throw new PollEndpointTimeoutError(
                `Timed-out while attempting to fetch ${name}`,
            );
        }

        await asyncTimeout(POLL_INTERVAL_MILLISECONDS, config?.signal);
    }
}
