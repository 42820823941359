<template>
    <v-btn
        flat
        v-for="(item, index) in items"
        :key="index"
        :disabled="item.disabled ? item.disabled() : false"
        @click="() => onClickNavigation(item)"
        class="app-page-navigation-item fill-height d-flex align-center pa-4 text-none rounded-0 fill-height"
        :class="currentIndex > index ? 'previous-tab' : currentIndex === index ? 'current-tab' : ''"
    >
        <v-icon v-if="item.completed ? item.completed() : false" class="ml-1 mr-2 icon-color" size="16">
            mdi-check-circle
        </v-icon>
        <span class="text-paragraph-md font-weight-600">{{ item.title }}</span>
    </v-btn>
</template>

<script setup lang="ts">
    import type { NavigationTabItem } from '@/components/common/app-page/types';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import { computed } from 'vue';

    const emit = defineEmits(['click-navigation']);

    withDefaults(
        defineProps<{
            items: NavigationTabItem[];
        }>(),
        {},
    );

    const navigationStore = useNavigationStore();

    const onClickNavigation = (item: NavigationTabItem) => {
        navigationStore.setCurrent(item);
    };

    const currentIndex = computed(() => {
        return navigationStore.item?.order ?? -1
    })
</script>

<style scoped lang="scss">
    .icon-color {
        color: rgb(var(--v-theme-navigation-outline));
    }

    .previous-tab {
        border-bottom: 1px solid rgb(var(--v-theme-navigation-outline));
    }

    .current-tab {
        border-bottom: 3px solid rgb(var(--v-theme-navigation-outline));
    }
</style>
