<template>
    <GrowPathVisionStatementContainer v-if="store.statementView" />
    <app-page-content v-else>
        <template v-slot:intro>
            <app-page-content-intro title="Tell us about your life vision">
                <v-container class="pa-0 d-flex flex-column ga-5">
                    <div class="text-paragraph-md">
                        First, let's get a clear vision statement on what 'success' looks like for you, in
                        your future. Keep this updated as your vision changes as it will impact some choices
                        we recommend.
                    </div>

                    <GrowPathUpdateMessage />
                </v-container>
            </app-page-content-intro>
        </template>
        <GrowPathVisionForm />
    </app-page-content>

    <SubmitNotification :store="store" :activity-area="UserActivityArea.GrowPath" />

    <WarningChangesWillBeLost
        :value="store.isDirty"
        @on-discard-changes="onDiscardChanges"
        @on-submit="onSaveClick"
        :submit-disabled="!store.isSubmitEnabled"
    />

    <GrowVisionAndPrefsGuide />

    <app-page-footer>
        <template v-slot:actions>
            <app-page-tabs-footer-actions
                close-text="Return Home"
                :close-to="growHomeRoute"
                title="Continue"
                @on-submit="onSave"
                :submit-tooltip="
                    store.isSubmitEnabled ? '' : 'Complete each field before continuing...'
                "
                :submit-disabled="!store.isSubmitEnabled"
            />
        </template>
    </app-page-footer>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted } from 'vue';
    import SubmitNotification from '@/components/footer-actions/SubmitNotification.vue';
    import WarningChangesWillBeLost from '@/components/footer-actions/WarningChangesWillBeLost.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import { growHomeRoute } from '@/router/routes';
    import { useRouter } from 'vue-router';
    import { useActivityStore } from '@/store/activity/store';
    import GrowPathVisionForm from '@/components/grow/path/vision/GrowPathVisionForm.vue';
    import { useLifeVisionStore } from '@/store/grow/lifeVisionStore';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import GrowVisionAndPrefsGuide from '@/components/grow/GrowVisionAndPrefsGuide.vue';
    import GrowPathVisionStatementContainer from '@/components/grow/path/vision/GrowPathVisionStatementContainer.vue';
    import GrowPathUpdateMessage from '@/components/grow/common/GrowPathUpdateMessage.vue';

    const router = useRouter();
    const store = useLifeVisionStore();
    const activityStore = useActivityStore();

    await store.load();

    onMounted(async () => {
        await activityStore.load();
    });

    const onDiscardChanges = () => {
        // This is done to reset the store, so changes are discarded, so next time the data is loaded from scratch
        // This is only done on stores that are not save on change, and have an explicit save button
        store.$reset();
    };

    const onSaveClick = async () => {
        await store.saveProgress();

        if (!store.isError) {
            await router.push(growHomeRoute);
        }
    };

    const onSave = async () => {
        await store.saveProgress();

        if (!store.isError) {
            if (store.statementView) {
                await useNavigationStore().setNext();
            } else {
                store.statementView = true;
            }
        }
    };

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.FutureAspiration);
    });
</script>
