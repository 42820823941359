<template>
    <LiveChatWidget
      license="19094965"
      visibility="minimized"
      :customerName="customerName"
      :customerEmail="customerEmail"
    />
</template>

<script lang="ts" setup>
    import { LiveChatWidget } from '@livechat/widget-vue'
    import { useUsersStore } from '@/store/user/store';
    import { computed } from 'vue';

    const userStore = useUsersStore();
    const customerName = computed(() => {
        const userName = userStore.displayName || "<unknown>";
        const userCompany = userStore.user?.current_company || "<unknown>";

        // return "name (email)"
        return `${userName} (${userCompany})`;
    });
    const customerEmail = computed(() => userStore.user?.email);
</script>
