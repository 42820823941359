import type { CanvasGrowPreference } from '@/store/grow/types';

export enum DraggableListItemType {
    Item = 'item',
    MaxAlert = 'max-alert',
}

export type MaxAlertListItem = {
    itemType: DraggableListItemType.MaxAlert;
};

export type DraggableGrowPreferenceSelection = CanvasGrowPreference & {
    itemType: DraggableListItemType.Item;
};

export type DraggableListItem = DraggableGrowPreferenceSelection | MaxAlertListItem;

export const makeListItem = (item: CanvasGrowPreference): DraggableGrowPreferenceSelection => ({
    ...item,
    itemType: DraggableListItemType.Item,
});

export function makeMaxAlertItem(): MaxAlertListItem {
    return {
        itemType: DraggableListItemType.MaxAlert,
    };
}
