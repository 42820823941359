<template>
    <v-dialog :model-value="modelValue" @update:model-value="emitModelValue" max-width="650">
        <v-container class="mx-0 pa-0 overflow-y-auto max-h-screen-95">
            <v-card class="w-100 cursor-pointer border-2-secondary mx-0 pa-0 app-guide-inline-card">
                <v-card-item class="pa-2 pb-0 mb-n4" v-if="closable">
                    <template v-slot:append>
                        <v-icon @click="close" color="white" class="ma-2">mdi-close</v-icon>
                    </template>
                </v-card-item>
                <v-card-text class="pa-5">
                    <div class="text-paragraph-md text-white">
                        <div class="ma-0">
                            <app-guide-avatar
                                size="60"
                                class="mt-0 cursor-pointer avatar-inline-guide"
                                @click="guideStore.showDialog"
                            />
                        </div>
                        <div
                            v-if="title || hasSlot('title')"
                            class="inline-guide-title text-wrap px-0 pt-5 mb-0 text-white text-heading-h2"
                        >
                            <span v-if="title">{{ title }}</span>
                            <slot name="title" v-else-if="hasSlot('title')"></slot>
                        </div>

                        <div
                            class="pb-0 pl-0 pr-4 pt-4 text-paragraph-md text-word-phrase fill-height"
                        >
                            <span v-if="text">{{ text }}</span>
                            <slot v-else name="default" :close="close" />

                            <v-row
                                v-if="!hasSlot('actions') && actionText"
                                no-gutters
                                class="mt-4 w-100 justify-center"
                            >
                                <app-button
                                    @click="emit('submit')"
                                    :to="actionTo"
                                    height="48"
                                    class="text-paragraph-sm"
                                    color="primary"
                                    border="secondary"
                                    variant="flat"
                                    override-css="px-6 py-3"
                                >
                                    {{ actionText }}
                                </app-button>
                            </v-row>
                            <v-row v-else-if="hasSlot('actions')" no-gutters class="mt-4 w-100">
                                <slot name="actions" :close="close" />
                            </v-row>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>
    </v-dialog>
</template>

<script setup lang="ts">
    import { useSlots } from 'vue';
    import { useGuideStore } from '@/store/guide/store';
    import type { DeclaredRoute } from '@/router/routes';

    const props = withDefaults(
        defineProps<{
            title?: string;
            text?: string;
            closable?: boolean;
            actionText?: string;
            actionTo?: DeclaredRoute;
            modelValue: boolean;
        }>(),
        {
            actionText: 'Close',
            variant: 'short',
            disabledNext: false,
            navigation: 'relative',
        },
    );

    const guideStore = useGuideStore();

    function emitModelValue(value: boolean) {
        emit('update:modelValue', value);
    }

    const emit = defineEmits(['update:modelValue', 'submit']);

    const close = () => {
        emitModelValue(false);
    };

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>

<style scoped lang="scss">
    .app-guide-inline-card {
        border-radius: 12px;
        background: var(
            --Secondary-Purple-Gradient,
            linear-gradient(135deg, #1638a5 0%, #571fba 100%)
        );
        box-shadow: 0px 4px 8px 2px rgba(38, 38, 38, 0.08);
    }

    .avatar-inline-guide {
        z-index: 999;
    }
</style>
