import { type ApiResponse, get, post, put } from "@/services/apiClient";
import type { ApiPathOption } from "@/api/types/grow/pathways";
import type { ApiFullPathOption } from '@/api/types/grow/jobs';

export async function postGeneratePathOptions(
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = post<void, void>(`canvas/${canvasId}/grow/path/generate/`);
    return request(accessToken);
}

export async function getPathOptions(
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<ApiPathOption[]>> {
    const request = get<ApiPathOption[]>(`canvas/${canvasId}/grow/path/`);
    return request(accessToken);
}

export async function getAcceptedPaths(
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<ApiFullPathOption[]>> {
    const request = get<ApiFullPathOption[]>(`canvas/${canvasId}/grow/path/accepted`);
    return request(accessToken);
}

export async function putAcceptPathOption(
    canvasId: number,
    growPathOptionId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<void, void>(`canvas/${canvasId}/grow/path/${growPathOptionId}/accept`);
    return request(accessToken);
}
