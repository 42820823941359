import type { GenericAbortSignal } from 'axios';
import { pollUntil } from '@/services/pollUntil';
import type { ApiJobActivity } from '@/api/types/grow/jobs';
import { fetchJobOptionActivities } from '@/services/grow/activities/service';

/**
 * We assume that path options will be generated within 90 seconds. If not, we will show an error message,
 * and allow the user to try again. On the retry, we will show the loading screen again.
 * However, if the path options are generated within 90 seconds, we will show the path options.
 *
 * It is possible that path options from the previous run are shown, but this is acceptable.
 * Even maybe, the user see the double activities, but this is acceptable too.
 */
const POLLING_GROW_JOB_OPTIONS_ACTIONS_TIMEOUT = 90 * 1000;

/**
 * Repeatedly fetch a resource until a condition is met or a timeout reached
 */
export async function pollJobActivities(
    canvasId: number,
    growPathId: number,
    jobId: number,
    accessToken: string,
    signal: GenericAbortSignal,
    amountOfSuggestions: number = 0,
): Promise<ApiJobActivity[]> {
    return await pollUntil(
        'grow-jobs-activities',
        async (): Promise<ApiJobActivity[] | null> => {
            const jobOptions = await fetchJobOptionActivities(
                canvasId,
                growPathId,
                jobId,
                accessToken,
            );
            return jobOptions.length > amountOfSuggestions ? jobOptions : null;
        },
        { pollTimeout: POLLING_GROW_JOB_OPTIONS_ACTIONS_TIMEOUT, signal },
    );
}
