<template>
    <v-container class="pa-0 mb-15">
        <v-row no-gutters class="d-flex">
            <GrowPathPriorityActivitySelectionCategory
                v-for="(category, index) in categoryValues"
                :title="category.title"
                :category="category.value"
                :items="store.getListByCategory(category.value)"
                :required-amount="category.maxAmount"
                class="pa-1"
            />
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
    import GrowPathPriorityActivitySelectionCategory from '@/components/grow/path/preferences/GrowPathPriorityActivitySelectionCategory.vue';
    import { useGrowPreferencesStore } from '@/store/grow/preferencesStore';
    import { categoryValues } from '@/components/grow/path/preferences/utils';

    const store = useGrowPreferencesStore();
</script>
