<template>
    <v-card
        class="py-8 px-6 preference-card cursor-pointer"
        :class="cssClass"
        variant="flat"
        :color="preferenceType.color"
        @click="emit('click')"
        :width="width"
        :min-width="width"
        :height="height"
    >
        <div>
            <app-fa-icon :icon="preferenceType.icon" :class="preferenceType.iconColor" size="2x" />
        </div>
        <div class="text-heading-h4 text-center text-custom-grey-900">{{ value.title }}</div>
        <div class="text-caption-subtitle text-custom-grey-900">{{ preferenceType.title }}</div>
    </v-card>
</template>

<script setup lang="ts">
    import { type ApiGrowPredefinedPreferenceItem } from '@/api/types/grow/preferences';
    import type { CanvasGrowPreference, CanvasGrowPreferenceType } from '@/store/grow/types';

    import { computed } from 'vue';

    const emit = defineEmits(['click']);

    const props = withDefaults(
        defineProps<{
            isSelected?: boolean;
            value: ApiGrowPredefinedPreferenceItem | CanvasGrowPreference;
            preferenceType: CanvasGrowPreferenceType;
            width?: string | number;
            height?: number;
        }>(),
        {
            isSelected: false,
        },
    );

    const cssClass = computed(() => {
        return {
            [`preference-type-${props.preferenceType.type.toLowerCase()}`]: true,
            ['is-selected']: props.isSelected,
        };
    });
</script>

<style lang="scss" scoped>
    .preference-card {
        border-radius: 8px;
        display: flex;
        padding: 32px 24px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &.preference-type-culture {
            background-color: rgb(var(--v-theme-secondary-pink-light));

            &.is-selected {
                border: 4px solid rgb(var(--v-theme-secondary-purple));
            }
        }

        &.preference-type-industry {
            background-color: rgb(var(--v-theme-yellow-light));

            &.is-selected {
                border: 4px solid rgb(var(--v-theme-story-button));
            }
        }

        &.preference-type-outcomes {
            background-color: rgb(var(--v-theme-secondary-red-light));

            &.is-selected {
                border: 4px solid rgb(var(--v-theme-secondary-red));
            }
        }

        &.preference-type-place {
            background-color: rgb(var(--v-theme-secondary-blue-light));

            &.is-selected {
                border: 4px solid rgb(var(--v-theme-secondary-blue));
            }
        }

        &.preference-type-activities {
            background-color: rgb(var(--v-theme-secondary-pink-light));

            &.is-selected {
                border: 4px solid rgb(var(--v-theme-secondary-pink));
            }
        }

        &.preference-type-people {
            background-color: rgb(var(--v-theme-yellow-light));

            &.is-selected {
                border: 4px solid rgb(var(--v-theme-story-button));
            }
        }

        &.preference-type-money {
            background-color: rgb(var(--v-theme-secondary-blue-light));

            &.is-selected {
                border: 4px solid rgb(var(--v-theme-secondary-blue));
                border-radius: 8px;
            }
        }

        &.preference-type-causes {
            background-color: rgb(var(--v-theme-secondary-red-light));

            &.is-selected {
                border: 4px solid rgb(var(--v-theme-secondary-red));
                border-radius: 8px;
            }
        }

        // Default preferenceType (fallback)
        &.preference-type-default {
            background-color: rgb(var(--v-theme-gray-light));

            &.is-selected {
                border: 4px solid rgb(var(--v-theme-gray));
                border-radius: 8px;
            }
        }
    }
</style>
