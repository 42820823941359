<template>
    <v-col cols="12">
        <v-card class="text-left text-heading-h3 pa-0 mb-5" variant="flat" color="">
            {{ props.title }}
            ({{ sortedSelectedItems.length }}/{{ requiredAmount }})
        </v-card>
        <v-container class="mb-2 pa-0" no-gutters>
            <draggable
                v-model="draggableList"
                group="group"
                item-key="id"
                class="d-flex flex-wrap ga-2"
                ghost-class="ghost"
                handle=".preference-card-handle"
                @start="draggingSelected = true"
                @end="draggingSelected = false"
            >
                <template #item="{ element, index }">
                    <GrowPathPriorityActivitySelectionCategorySelectedItem
                        v-if="element.itemType === DraggableListItemType.Item"
                        :category="category"
                        class="table-cell"
                        :preference="element"
                        :class="{
                            'first-row': index === 0,
                            'last-row': index === draggableList.length - 1,
                        }"
                        :key="`category-selected-${element.id}`"
                    />
                </template>
            </draggable>
        </v-container>
    </v-col>
</template>
<script setup lang="ts">
    import draggable from 'vuedraggable';
    import { computed, ref } from 'vue';
    import { useGrowPreferencesStore } from '@/store/grow/preferencesStore';
    import type {
        CanvasGrowPreference,
        SelectableGrowPreferenceCategory,
    } from '@/store/grow/types';
    import {
        type DraggableListItem,
        DraggableListItemType,
        makeListItem,
    } from '@/components/grow/path/preferences/draggableListUtils';
    import GrowPathPriorityActivitySelectionCategorySelectedItem from '@/components/grow/path/preferences/GrowPathPriorityActivitySelectionCategorySelectedItem.vue';

    const draggingSelected = ref(false);

    const store = useGrowPreferencesStore();

    const props = defineProps<{
        title: string;
        category: SelectableGrowPreferenceCategory;
        items: CanvasGrowPreference[];
        requiredAmount: number;
    }>();

    /**
     * The draggable list is a computed property with a getter and a setter.
     * The getter allows us the list of items including the display the max alert indication
     * The setter part allows us to set in the pinia store only the information
     * we need (removing the max alert indication)
     *
     * @see https://stackoverflow.com/a/74952600
     */
    const draggableList = computed({
        get: () => {
            const selected = sortedSelectedItems.value;

            return [...selected.map(makeListItem)];
        },
        set: async (orderedList: DraggableListItem[]) => {
            const items = makeListToUpdate(orderedList);
            await store.updateCategoryList(items, props.category);
        },
    });

    function makeListToUpdate(orderedList: DraggableListItem[]): CanvasGrowPreference[] {
        return orderedList.filter((item, index) => {
            return item.itemType === DraggableListItemType.Item;
        }) as CanvasGrowPreference[];
    }

    const selectedItems = computed(() => {
        return props.items.filter((item) => {
            return item.category;
        });
    });

    // const byOrderComparator = (a: CanvasGrowPreference, b: CanvasGrowPreference) => {
    //     // equal items sort equally
    //     if (a.order === b.order) {
    //         return 0;
    //     }
    //
    //     if (a.order === undefined) {
    //         return 1;
    //     }
    //     if (b.order === undefined) {
    //         return -1;
    //     }
    //
    //     return a.order < b.order ? -1 : 1;
    // };

    const sortedSelectedItems = computed(() => {
        return selectedItems.value;
        // TODO add sorting to selected items
        // .sort(byOrderComparator);
    });
</script>

<style scoped lang="scss">
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .not-draggable {
        cursor: no-drop;
    }

    .table-cell {
        --border-color: rgb(var(--v-theme-custom-neutral-grey-200));
        --cell-border: 2px solid var(--border-color);

        border-top-color: var(--cell-border);
        border-left-color: var(--cell-border);
        border-right-color: var(--cell-border);
        border-bottom: 0;

        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 8px;
        padding-left: 0px;

        /* Add rounded corners to the first row */
        &.first-row {
            border-color: var(--border-color) !important;
            border-radius: 4px 4px 0 0;
        }

        /* Add rounded corners to the last row */
        &.last-row {
            border-color: var(--border-color) !important;
            border-radius: 0 0 4px 4px;
            border-bottom: var(--cell-border);
        }

        &.first-row.last-row {
            border-radius: 4px;
        }

        /* Ensure color is applied to the middle rows */
        &:not(.first-row):not(.last-row) {
            border-radius: 0;
            border-color: var(--border-color) !important;
        }
    }
</style>
