<template>
    <v-container class="w-100 app-activity-layout" fluid :class="cssClass">
        <v-container
            v-if="hasSlot('intro')"
            class="pa-0 app-page-content-intro-container"
            :class="responsiveClass"
            :min-width="introContainerWidth"
            :max-width="introContainerWidth"
            :width="introContainerWidth"
        >
            <slot name="intro" />
        </v-container>
        <v-container class="pa-0" :class="fillHeight ? 'fill-height' : ''">
            <slot />
        </v-container>
    </v-container>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { computed, useSlots } from 'vue';

    const { isDesktop, responsiveClass } = useIsDesktop();

    const props = withDefaults(
        defineProps<{
            /**
             * Whether the layout should fill the height with the parent container
             *
             * Usually this is not needed as the layout will automatically fill
             * the height with existing content. For pages where the content is less,
             * this can be used to fill the height and vertically center the content.
             */
            fillHeight?: boolean;
        }>(),
        {
            fillHeight: false,
        },
    );

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };

    const introContainerWidth = computed(() => {
        return isDesktop.value ? 384 : '100%';
    });

    const cssClass = computed(() => {
        return {
            [responsiveClass.value]: true,
            'fill-height': props.fillHeight,
        };
    });
</script>

<style lang="scss" scoped>
    .app-activity-layout {
        &.desktop {
            display: flex;
            max-width: 1200px;
            justify-content: center;
            align-items: flex-start;
            gap: 80px;
            align-self: stretch;

            // Bottom padding accounts for 64px + 54px of the footer which is in fixed position
            padding: 64px 0 118px;
        }

        &.mobile {
            // Top padding accounts for 64px + 48px of the footer which is in fixed position
            padding: 112px 0 64px;
        }
    }

    .app-page-content-intro-container.desktop {
        position: sticky;
        top: 64px;
    }

    .app-page-content-intro-container.mobile {
        margin-top: 0;
    }
</style>
