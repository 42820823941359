<template>
    <app-page-content>
        <template #intro>
            <app-page-content-intro>
                <template #title>
                    <div class="text-heading-h1 font-weight-regular">
                        {{ jobsStore.job?.title }}
                    </div>

                    <br />
                    You’re ready to get started working on this Pathway! We’ve brought the
                    activities for the jobs you’ve selected into a plan for you. We suggest you keep
                    this up to date, and share with your manager to get their support to make the
                    transition work for them.
                    <br />
                    <br />

                    <GrowPathwayTitle
                        v-if="pathwaysStore.path"
                        :title="pathwaysStore.path?.title"
                        :description="pathwaysStore.path?.description"
                        class="mt-1"
                    />
                </template>

                <GrowPathProgressJobProgressCard />

                <GrowPatchActivityCategoryDescription class="mt-8" />
            </app-page-content-intro>
        </template>

        <v-container
            class="pa-0 d-flex challenge-sequencing-container"
            v-for="(action, index) in store.acceptedActivities"
            :key="`${index}`"
        >
            <app-sorting-controls
                :position="index + 1"
                :total="store.acceptedActivities.length"
                :hide-sort-buttons="true"
                line-color="grey"
            />
            <GrowPathProgressActivityListItem
                :key="index"
                :item="action"
                class="mb-2 w-100"
                @mark-as-completed="onMarkAsCompletedClick(action)"
            />
        </v-container>
    </app-page-content>

    <app-page-footer>
        <template v-slot:actions>
            <app-page-tabs-footer-actions
                close-text="Back"
                @on-back="navigationStore.setPrevious"
                :submit-hide="true"
            />
        </template>
    </app-page-footer>

    <GrowPathwaysGuide />

    <AppGuideModal
        v-model="showSuccess"
        title="Wow you really showed that job path who’s boss!"
        action-text="Continue"
        @submit="onContinueAfterComplete"
    >
        <template #default>
            Give yourself a pat on the back for overcoming this job path. Now is a great to to
            reflect on the experience.
        </template>
    </AppGuideModal>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import AppSortingControls from '@/components/common/AppSortingControls.vue';
    import { useGrowJobsStore } from '@/store/grow/jobsStore';
    import { useGrowPathwaysStore } from '@/store/grow/pathwaysStore';
    import GrowPathProgressJobProgressCard from '@/components/grow/path/progress/GrowPathProgressJobProgressCard.vue';
    import GrowPathProgressActivityListItem from '@/components/grow/path/progress/GrowPathProgressActivityListItem.vue';
    import type { PathAcceptedJobActivity } from '@/store/grow/types';
    import { useGrowJobActivityStore } from '@/store/grow/jobActivityStore';
    import GrowPathwaysGuide from '@/components/grow/GrowPathwaysGuide.vue';
    import GrowPatchActivityCategoryDescription from '@/components/grow/path/approach/GrowPatchActivityCategoryDescription.vue';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import GrowPathwayTitle from '@/components/grow/common/GrowPathwayTitle.vue';

    const jobsStore = useGrowJobsStore();
    const pathwaysStore = useGrowPathwaysStore();
    const store = useGrowJobActivityStore();
    const navigationStore = useNavigationStore();

    const showSuccess = ref(false);

    const onContinueAfterComplete = async () => {
        showSuccess.value = false;
    };

    const onMarkAsCompletedClick = async (item: PathAcceptedJobActivity) => {
        await store.onMarkAsCompleted(item);

        if (store.isActivitiesCompleted) {
            showSuccess.value = true;
        }
    };
</script>
