import { StoryActivityStatus } from '@/store/story/types';

export const makeActivityStatus = (
    completed: boolean,
    inProgress?: boolean,
): StoryActivityStatus => {
    if (completed) {
        return StoryActivityStatus.Completed;
    }

    if (inProgress) {
        return StoryActivityStatus.InProgress;
    }

    return StoryActivityStatus.NotStarted;
};