<template>
    <v-card
        variant="outlined"
        elevation="0"
        class="suitability-path-option"
        :class="isDesktop ? 'rounded-12' : 'rounded-8'"
    >
        <v-card-item v-if="isDesktop" class="px-4 py-6 suitability-path-option__title ga-6">
            <template #prepend>
                <app-fa-icon size="2x" :icon="value.icon" />
            </template>
            <div class="text-heading-h3">{{ value.title }}</div>
            <div class="text-paragraph-sm text-neutral-black">{{ value.description }}</div>

            <template #append>
                <v-container class="pa-0 ga-3 d-flex flex-column">
                    <GrowPathPathwaysContinueButton
                        text="Continue with this path"
                        :is-selected="isSelected"
                        @on-selected="emit('onSelected')"
                    />
                    <GrowPathPathwaysSuitabilityPathOptionShowMeWhy
                        class="justify-end"
                        @click="expanded = !expanded"
                        :expanded="expanded"
                    />
                </v-container>
            </template>
        </v-card-item>
        <v-card-item v-else class="px-4 py-6 suitability-path-option__title">
            <v-container class="pa-0 d-flex ga-4 align-center">
                <app-fa-icon size="2x" :icon="value.icon" />

                <div>
                    <div class="text-heading-h3">{{ value.title }}</div>
                    <div class="text-paragraph-sm text-neutral-black">{{ value.description }}</div>
                </div>
            </v-container>
            <v-container class="pa-0 ga-3 d-flex">
                <GrowPathPathwaysSuitabilityPathOptionShowMeWhy
                    class="justify-start"
                    @click="expanded = !expanded"
                    :expanded="expanded"
                />
                <GrowPathPathwaysContinueButton
                    text="Continue with this path"
                    :is-selected="isSelected"
                    @on-selected="emit('onSelected')"
                />
            </v-container>
        </v-card-item>

        <v-card-text
            class="suitability-path-option__content d-flex flex-column ga-8 pa-8"
            v-if="expanded"
        >
            <div class="text-paragraph-md">{{ value.long_description }}</div>

            <v-divider />

            <div class="w-100 d-flex ga-8" v-if="isDesktop">
                <div>
                    <div class="text-heading-h4 font-weight-600 mb-2">Your needs match</div>
                    <div class="text-paragraph-md">{{ value.needs_description }}</div>
                </div>
                <v-container width="150" height="150" class="pa-0">
                    <GrowPathPathwaysSuitabilityPathOptionPieChart :value="value.needs_match_pct" />
                </v-container>
            </div>
            <div class="w-100 d-flex flex-column ga-4" v-else>
                <v-container height="150" class="pa-0 d-flex justify-start">
                    <GrowPathPathwaysSuitabilityPathOptionPieChart :value="value.needs_match_pct" />
                </v-container>
                <div>
                    <div class="text-heading-h4 font-weight-600 mb-2">Your needs match</div>
                    <div class="text-paragraph-md">{{ value.needs_description }}</div>
                </div>
            </div>

            <div class="w-100 d-flex ga-8" v-if="isDesktop">
                <div>
                    <div class="text-heading-h4 font-weight-600 mb-2">
                        Your skills & experience match
                    </div>
                    <div class="text-paragraph-md">
                        {{ value.skills_and_experiences_description }}
                    </div>
                </div>
                <v-container width="150" height="150" class="pa-0">
                    <GrowPathPathwaysSuitabilityPathOptionPieChart
                        :value="value.skills_and_experiences_match_pct"
                    />
                </v-container>
            </div>
            <div class="w-100 d-flex flex-column ga-4" v-else>
                <v-container height="150" class="pa-0 d-flex justify-start">
                    <GrowPathPathwaysSuitabilityPathOptionPieChart
                        :value="value.skills_and_experiences_match_pct"
                    />
                </v-container>
                <div>
                    <div class="text-heading-h4 font-weight-600 mb-2">
                        Your skills & experience match
                    </div>
                    <div class="text-paragraph-md">
                        {{ value.skills_and_experiences_description }}
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import type { PathOption } from '@/store/grow/types';
    import GrowPathPathwaysSuitabilityPathOptionPieChart from '@/components/grow/path/pathways/GrowPathPathwaysSuitabilityPathOptionPieChart.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import GrowPathPathwaysContinueButton from '@/components/grow/path/pathways/GrowPathPathwaysContinueButton.vue';
    import GrowPathPathwaysSuitabilityPathOptionShowMeWhy from '@/components/grow/path/pathways/GrowPathPathwaysSuitabilityPathOptionShowMeWhy.vue';

    const props = defineProps<{
        value: PathOption;
        isSelected: boolean;
        expandDefault: boolean;
    }>();

    const emit = defineEmits(['onSelected']);

    const { isDesktop } = useIsDesktop();

    const expanded = ref(props.expandDefault);

    // Note: Do not delete this variable, as it is bound to the < style />
    const matchPercentage = computed(() => {
        return (props.value.needs_match_pct + props.value.skills_and_experiences_match_pct) / 2;
    });
</script>

<style lang="scss" scoped>
    .suitability-path-option {
        border: 2px solid rgb(var(--v-theme-navigation-outline));

        .suitability-path-option__title {
            background: linear-gradient(
                to right,
                rgba(var(--v-theme-primary), 0.5) v-bind('matchPercentage + "%"'),
                transparent v-bind('matchPercentage + "%"')
            );
        }

        .suitability-path-option__content {
            border-top: 2px solid rgb(var(--v-theme-navigation-outline));
        }
    }
</style>
