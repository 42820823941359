<template>
    <app-page class="app-page" :class="responsiveClass">
        <app-page-header title="Profile"></app-page-header>
        <app-page-content class="align-center justify-center d-flex">
            <v-form ref="formRef">
                <!--
                    The layout expects a 64px separation between divs in desktop, and 40px in mobile
                    This is tricky to do in one place, as some sections end up
                    with a non-visible div that takes space.
                    This is the typical < v-input__details /> that only shows when there is an error.
                    This divs takes 22px, therefore the separation is 42px and 18px.
                 -->
                <v-row no-gutters class="w-100 profile-section">
                    <UserDetails />
                </v-row>
                <v-row no-gutters class="w-100 profile-section">
                    <CurrentEmployment />
                </v-row>
                <v-row no-gutters class="w-100 profile-section">
                    <Location />
                </v-row>
                <v-row no-gutters class="w-100 profile-section">
                    <Languages />
                </v-row>
                <v-row no-gutters class="w-100 profile-section">
                    <CurriculumVitaeSection />
                </v-row>
                <v-row no-gutters class="w-100 profile-section">
                    <ProfileCard title="Career history">
                        <v-card-text class="pa-0 text-wrap text-subtitle-1">
                            <app-button
                                class="text-custom-purple-deep bg-white"
                                variant="outlined"
                                :to="careerHistoryRoute"
                            >
                                See my career history
                            </app-button>
                        </v-card-text>
                    </ProfileCard>
                </v-row>
                <v-row no-gutters class="w-100 profile-section-no-previous">
                    <CareerConfidence />
                </v-row>

                <!--            <v-row no-gutters class="w-100" v-if="!firstProfileView">-->
                <!--                &lt;!&ndash;-->
                <!--                    Hide the change password option on the profile until after-->
                <!--                    the user has first logged in and set the profile-->
                <!--                 &ndash;&gt;-->
                <!--                <ChangePassword />-->
                <!--            </v-row>-->

                <v-row
                    v-if="!firstProfileView"
                    no-gutters
                    class="w-100 profile-section-no-previous"
                >
                    <ProfileCard title="Logout">
                        <LogoutButton class="justify-center" />
                    </ProfileCard>
                </v-row>

                <v-row no-gutters class="w-100 profile-section-no-previous" v-if="firstProfileView">
                    <ProfileCard title="Legal">
                        <div>
                            <v-btn
                                prepend-icon="mdi-security"
                                variant="text"
                                @click="goTerms"
                                target="_blank"
                            >
                                ACTVO's Terms of Use
                            </v-btn>
                        </div>

                        <div>
                            <v-btn
                                prepend-icon="mdi-security"
                                variant="text"
                                @click="goPrivacy"
                                target="_blank"
                            >
                                ACTVO's Privacy Policy
                            </v-btn>
                        </div>
                    </ProfileCard>
                </v-row>
                <v-row no-gutters class="w-100" v-if="firstProfileView">
                    <v-banner
                        density="compact"
                        color="yellow"
                        class="text-subtitle-1"
                        icon="mdi-text-box-check-outline"
                        text="By clicking save below you are confirming you agree to our Terms and Services and Privacy Policy"
                        :stacked="true"
                    >
                    </v-banner>
                </v-row>
            </v-form>
        </app-page-content>

        <app-page-footer>
            <template v-slot:errors>
                <SubmitNotification :store="store" :activity-area="UserActivityArea.Profile" />
            </template>
            <template v-slot:actions>
                <SaveProgressButtons
                    :close-to="homeRoute"
                    close-variant="flat"
                    v-if="firstProfileView"
                    submit-text="Save and get started"
                    :submit-loading="store.isUpdating"
                    :submit-disabled="!store.isDirty"
                    @on-submit="onFirstSaveClick"
                />
                <SaveProgressButtons
                    :close-to="homeRoute"
                    close-variant="flat"
                    v-if="!firstProfileView"
                    submit-text="Update user profile"
                    :submit-loading="store.isUpdating"
                    :submit-disabled="!store.isDirty || !store.isComplete"
                    @on-submit="onSaveClick"
                />
                <WarningChangesWillBeLost :value="store.isDirty" @on-submit="onSaveClick" />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.Profile" />
        <ProfileGuide />
    </app-page>
</template>

<script setup lang="ts">
    import { useUsersStore } from '@/store/user/store';
    import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';
    import { useUserProfileStore } from '@/store/profile/store';
    import CurriculumVitaeSection from '@/components/profile/curriculum/CurriculumVitaeSection.vue';
    import CurrentEmployment from '@/components/profile/CurrentEmployment.vue';
    import SaveProgressButtons from '@/components/footer-actions/SaveProgressButtons.vue';
    import SubmitNotification from '@/components/footer-actions/SubmitNotification.vue';
    import UserDetails from '@/components/profile/UserDetails.vue';
    import { useRouter } from 'vue-router';
    import { careerHistoryRoute, homeRoute } from '@/router/routes';
    import WarningChangesWillBeLost from '@/components/footer-actions/WarningChangesWillBeLost.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import LogoutButton from '@/components/buttons/LogoutButton.vue';
    import { useActivityStore } from '@/store/activity/store';
    import CareerConfidence from '@/components/profile/CareerConfidence.vue';
    import ProfileGuide from '@/components/profile/ProfileGuide.vue';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import ProfileCard from '@/components/profile/ProfileCard.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import Location from '@/components/profile/Location.vue';
    import Languages from '@/components/profile/Languages.vue';

    const store = useUserProfileStore();
    const usersStore = useUsersStore();
    const activityStore = useActivityStore();

    const router = useRouter();

    const { responsiveClass } = useIsDesktop();

    onBeforeMount(async () => {
        store.$reset();
    });

    onMounted(async () => {
        store.init();

        // Trigger validation on load to display error messages of required fields if needed
        // TODO: review this UX decision
        const form = formRef.value;
        // @ts-ignore not sure which type this should be
        if (form?.validate()) {
            // Form is valid, submit data
            console.log('Form submitted:', store.userInputs);
        } else {
            // Form is invalid
            console.log('Form validation failed');
        }

        await activityStore.addEntryActivity(UserActivityArea.Profile);
    });

    const formRef = ref(null);

    const navigationStore = useNavigationStore();
    await navigationStore.init([]);

    const onSaveClick = async () => {
        await store.save();
    };

    const onFirstSaveClick = async () => {
        await onSaveClick();
        await router.push('/');
    };

    const firstProfileView = computed(() => {
        return !usersStore.hasProfileBeenSet;
    });

    const goPrivacy = async () => {
        await router.push('/privacy-policy');
    };

    const goTerms = async () => {
        await router.push('/terms-of-use');
    };

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.Profile);
    });
</script>

<style scoped lang="scss">
    :deep(.v-messages__message) {
        margin-top: 8px;
        padding-left: 0;
        padding-bottom: 16px;
    }

    /**
        The layout expects a 64px separation between divs in desktop, and 40px in mobile
        This is tricky to do in one place, as some sections end up with a non-visible div that takes space.
        This is the typical < v-input__details /> that only shows when there is an error.
        This divs takes 22px. Therefore:
         - for some divs, where the previous section had <v-input__details/>
            we add a margin-top of 42px in desktop and 18px in mobile.
         - if previous section did not have an input like this as the last element, we add the full margin value.
     */
    .app-page {
        &.desktop {
            .profile-section {
                margin-top: 42px;
            }

            .profile-section-no-previous {
                margin-top: 64px;
            }
        }

        &.mobile {
            .profile-section {
                margin-top: 18px;
            }

            .profile-section-no-previous {
                margin-top: 40px;
            }
        }
    }
</style>
