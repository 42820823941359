import { defineStore } from 'pinia';
import type {
    FullJobOption,
    FullPathOption,
    GrowPathStoreState,
    PathJobOption,
    PathOption,
} from '@/store/grow/types';
import { useLifeVisionStore } from '@/store/grow/lifeVisionStore';
import { useGrowPreferencesStore } from '@/store/grow/preferencesStore';
import { useAppErrorStore } from '@/store/appErrorStore';
import type { ApiFullPathOption } from '@/api/types/grow/jobs';
import { fetchAcceptedPaths } from '@/services/grow/pathways/service';
import { useCanvasStore } from '@/store/canvas/store';
import { useGrowPathwaysStore } from '@/store/grow/pathwaysStore';
import { useGrowJobsStore } from '@/store/grow/jobsStore';
import { useGrowJobActivityStore } from '@/store/grow/jobActivityStore';
import { growActivitiesRoute, growPathsRoute, growProgressRoute } from '@/router/routes';
import type { Router } from 'vue-router';

export const useGrowPathStore = defineStore({
    id: 'grow-store',
    state: (): GrowPathStoreState => ({
        paths: [],
        path: null,
    }),

    getters: {
        acceptedPaths(state): FullPathOption[] {
            return state.paths.filter(
                (pathOption: ApiFullPathOption) => pathOption.state === 'accepted',
            );
        },
        currentAcceptedJobs(state): FullJobOption[] {
            return state.path?.job_options.filter((job) => job.state === 'accepted') ?? [];
        },
    },
    actions: {
        async load() {
            await useAppErrorStore().catchErrors(async () => {
                await useLifeVisionStore().load();
                await useGrowPreferencesStore().load();

                const { canvasId, accessToken } = await useCanvasStore().makeContext();
                const paths = await fetchAcceptedPaths(canvasId, accessToken);

                this._setPaths(paths);
            });
        },
        /**
         * This functions orchestrates the navigation to the progress tab or
         * the activities tab based on the current state of the path and job.
         *
         * It assembles the necessary data for the progress tab and activities tab on each of the
         * stores and navigates to the appropriate tab.
         */
        async updatePathJobProgress(path: FullPathOption, job: FullJobOption, router: Router) {
            const currentPath = path;

            const pathwaysStore = useGrowPathwaysStore();
            const jobStore = useGrowJobsStore();
            const activitiesStore = useGrowJobActivityStore();

            await useAppErrorStore().catchErrors(async () => {
                pathwaysStore.$reset();
                jobStore.$reset();
                activitiesStore.$reset();

                pathwaysStore.toggleSelection(currentPath as unknown as PathOption);
                jobStore.toggleJobSelection(job as unknown as PathJobOption);

                await pathwaysStore.load();
                await jobStore.load();
                await activitiesStore.load();

                if (activitiesStore.acceptedActivities.length === 0) {
                    await router.push(growActivitiesRoute);
                } else {
                    await router.push(growProgressRoute);
                }
            });
        },
        /**
         * This function orchestrates the navigation to the pathways tab
         */
        async onSelectJob(path: FullPathOption, router: Router) {
            const currentPath = path;

            const pathwaysStore = useGrowPathwaysStore();
            const jobStore = useGrowJobsStore();
            const activitiesStore = useGrowJobActivityStore();

            await useAppErrorStore().catchErrors(async () => {
                pathwaysStore.$reset();
                jobStore.$reset();
                activitiesStore.$reset();

                pathwaysStore.toggleSelection(currentPath as unknown as PathOption);

                await pathwaysStore.load();

                await router.push(growPathsRoute);
            });
        },
        setCurrentPath(path: FullPathOption) {
            this.path = path;
        },
        _setPaths(paths: ApiFullPathOption[]) {
            this.paths = paths;
            const firstAcceptedPath = paths.find((path) => path.state === 'accepted');
            if (firstAcceptedPath) {
                this.setCurrentPath(firstAcceptedPath);
            }
        },
    },
});
