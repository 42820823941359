<template>
    <v-container class="home-about-card" variant="flat" fluid :class="cssClass">
        <div class="w-100">
            <div class="w-100 text-heading-h4">
                {{ title }}
            </div>

            <v-divider class="mt-4 mb-0 w-100" />
        </div>

        <v-row class="pa-0 ga-10" no-gutters>
            <v-col :cols="isDesktop ? true : 12">
                <v-container
                    class="pa-0 w-100 fill-height flex-column"
                    :class="isDesktop ? 'justify-center align-center' : ''"
                >
                    <div class="text-heading-h1 w-100">
                        {{ title }}
                    </div>
                    <div class="text-paragraph-md mt-4 w-100">
                        {{ description }}
                    </div>
                </v-container>
            </v-col>
            <v-col :cols="isDesktop ? true : 12">
                <v-img :src="image" class="rounded-16 border-2-primary" />
            </v-col>
        </v-row>

        <v-container class="home-about-card__process bg-primary-light w-100 pa-0">
            <v-container
                class="pa-0 w-100"
                max-width="800"
                :class="isDesktop ? 'text-center mb-10' : 'text-left mb-4'"
            >
                <div class="w-100" :class="isDesktop ? 'text-heading-h1' : 'text-heading-h2'">
                    The process
                </div>

                <div
                    v-if="processSubtitle"
                    class="w-100 text-heading-md"
                    :class="isDesktop ? 'mt-4' : 'mt-2'"
                >
                    {{ processSubtitle }}
                </div>
            </v-container>

            <v-row
                class="home-about-card__process__cards"
                no-gutters
                :class="isDesktop ? 'ga-5' : 'ga-3'"
            >
                <v-col
                    class="flex-column d-flex"
                    :cols="isDesktop ? true : 12"
                    v-for="(processCard, index) in processCards"
                >
                    <HomeProcessCard
                        :order="processCard.order"
                        :title="processCard.title"
                        :description="processCard.description"
                    />
                </v-col>
            </v-row>
        </v-container>

        <v-container class="pa-0 justify-center d-flex" v-if="showSubmit">
            <v-tooltip
                :text="submitTooltip"
                :disabled="!submitTooltip"
                location="top"
                open-on-hover
                open-on-focus
                :open-on-click="false"
            >
                <template v-slot:activator="{ props }">
                    <span v-bind="props">
                        <app-button size="large" color="button" variant="flat" @click="emit('click')" :disabled="submitDisabled">
                            Get started
                        </app-button>
                    </span>
                </template>
            </v-tooltip>
        </v-container>
    </v-container>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { computed } from 'vue';
    import HomeProcessCard from '@/components/common/home/HomeProcessCard.vue';

    const { isDesktop, responsiveClass } = useIsDesktop();

    const emit = defineEmits(['click']);

    type ProcessCardItem = {
        order: number;
        title: string;
        description: string;
    };

    const props = withDefaults(
        defineProps<{
            color?: string;
            title: string;
            description: string;
            image: string;
            processSubtitle?: string;
            processCards: ProcessCardItem[];
            showSubmit?: boolean;
            submitDisabled?: boolean;
            submitTooltip?: string;
        }>(),
        { showSubmit: true, submitDisabled: false, submitTooltip: '' },
    );

    const cssClass = computed(() => {
        return {
            [responsiveClass.value]: true,
            [`bg-${props.color}`]: !!props.color,
        };
    });
</script>

<style scoped lang="scss">
    .home-about-card {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        background: rgb(var(--v-theme-primary-light));

        &.desktop {
            padding: 48px 64px;
            border-radius: 32px;
            gap: 48px;
        }

        &.mobile {
            padding: 20px 20px;
            border-radius: 16px;
            flex-direction: column;
            gap: 32px;

            .home-about-card__header {
                min-width: 100%;
                width: 100%;
            }

            .home-about-card__header__image {
                margin-bottom: 20px;
            }

            .home-about-card__header__description {
                margin-top: 20px;
            }
        }
    }
</style>
