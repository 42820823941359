<template>
    <HomeSection
        :disabled="!canAccessSolve"
        type="challenges"
        :about-description="aboutDescription"
        :image="image"
        :section-items="sectionItems"
        :to="challengesHomeRoute"
        submit-text="Go to Solve"
        :submit-tooltip="submitTooltip"
    />
</template>

<script setup lang="ts">
    import image from '@/assets/images/home/solve-text.svg';

    import HomeSection from '@/components/home/HomeSection.vue';
    import { challengesHomeRoute } from '@/router/routes';
    import { useSolveAccess } from '@/composables/solve/useSolveAccess';

    const aboutDescription = 'Solve is where we get you unstuck from everyday challenges that might hold you back.';

    const sectionItems = [
        {
            icon: 'search',
            title: 'Identify Needs',
            description:
                'We know the common challenges people like you face, and we want to help you solve them. We support you to identify the personal things that are holding you back, that might be hard to share with others at work.',
        },
        {
            icon: 'arrow-up-right-dots',
            title: 'Get Unstuck',
            description:
                'We offer you a set of activities to suit your learning style, that will help you solve these challenges. Presented in pathways, it helps you easily plan your approach to tackling the challenges you face at work.',
        },
        {
            icon: 'compass',
            title: 'Share your Plan',
            description:
                'We put your activities into an action plan, which is ideal for organising yourself and sharing with your manager to gain their support and input. You can also reflect on what you\'ve learned.',
        },
    ];

    const { canAccessSolve, submitTooltip } = await useSolveAccess();
</script>
