import { createInBackground, fetch, update } from '@/services/base';
import {
    getAcceptedPaths,
    getPathOptions,
    postGeneratePathOptions,
    putAcceptPathOption,
} from '@/services/grow/pathways/api';
import type { ApiPathOption } from '@/api/types/grow/pathways';
import type { ApiResponse } from '@/services/apiClient';
import type { ApiFullPathOption } from '@/api/types/grow/jobs';

export async function generatePathOptions(
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    return await createInBackground(
        'generate-path-options',
        async () => await postGeneratePathOptions(canvasId, accessToken),
    );
}

export async function fetchPathOptions(
    canvasId: number,
    accessToken: string,
): Promise<ApiPathOption[]> {
    return fetch('path-options', async () => await getPathOptions(canvasId, accessToken));
}

export async function fetchAcceptedPaths(
    canvasId: number,
    accessToken: string,
): Promise<ApiFullPathOption[]> {
    return fetch(
        'accepted-path-options',
        async () => await getAcceptedPaths(canvasId, accessToken),
    );
}

export async function acceptPathOption(
    canvasId: number,
    growPathOptionId: number,
    accessToken: string,
): Promise<void> {
    return update(
        'accept-path-option',
        async () => await putAcceptPathOption(canvasId, growPathOptionId, accessToken),
    );
}
