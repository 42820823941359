<template>
    <v-card
        min-height="60"
        min-width="260"
        class="rounded-0 rounded-lg job-item border-2-grey-200"
        elevation="0"
        :class="contentClass"
    >
        <v-card-text class="pa-0">
            <v-row no-gutters>
                <v-col :cols="smAndDown ? 8 : 10" class="px-5 py-5">
                    <span
                        class="text-heading-h4 cursor-pointer"
                        :class="isArchived ? 'opacity-50' : ''"
                    >
                        {{ item.title }}
                    </span>
                    <br />
                    <div class="pt-2" v-if="!isArchived && isInProgress">
                        <v-progress-linear
                            :model-value="progress"
                            color="primary"
                            height="8"
                            :rounded="true"
                            class="w-100"
                        ></v-progress-linear>
                    </div>
                </v-col>
                <v-col
                    :cols="smAndDown ? 4 : 2"
                    class="d-flex align-center justify-center action-column"
                >
                    <app-button
                        class="px-0 fill-height w-100 text-none text-custom-neutral-grey-900 font-weight-600 opacity-1 rounded-0"
                        v-if="isArchived"
                        variant="flat"
                        color="white"
                        :ripple="false"
                        @click="onUnarchiveClick"
                        >Unarchive
                    </app-button>
                    <app-button
                        class="px-0 fill-height w-100 text-none text-custom-neutral-grey-900 font-weight-600 opacity-1 rounded-0"
                        v-else-if="isInProgress"
                        variant="flat"
                        color="primary"
                        :ripple="false"
                        @click="onUpdateInProgressClick"
                        >Continue
                    </app-button>
                    <app-button
                        class="px-0 fill-height w-100 text-none text-custom-neutral-grey-900 font-weight-600 opacity-1 rounded-0"
                        v-else-if="notStarted"
                        variant="flat"
                        color="primary"
                        :ripple="false"
                        @click="onStartClick"
                        >Start
                    </app-button>
                    <v-icon v-else-if="isCompleted">mdi-check</v-icon>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import type { FullJobOption, FullPathOption } from '@/store/grow/types';
    import { computed } from 'vue';
    import { useDisplay } from 'vuetify';
    import { useGrowPathStore } from '@/store/grow/store';
    import { useRouter } from 'vue-router';

    const emit = defineEmits(['unarchive', 'update', 'start']);

    const { smAndDown } = useDisplay();

    const props = withDefaults(
        defineProps<{
            item: FullJobOption;
            isEditable: boolean;
        }>(),
        { isEditable: true },
    ); // set the isEditable prop true by default

    const router = useRouter();
    const store = useGrowPathStore();

    const contentClass = computed(() => {
        if (isArchived.value) {
            return 'job-item-archived';
        }

        if (isCompleted.value) {
            return 'job-item-completed';
        } else if (notStarted.value) {
            return 'job-item-not-started';
        }
        return 'job-item-in-progress';
    });

    // TODO: CORE-265 - move some of this logic to the store?
    const acceptedActivities = computed(() => {
        return props.item.activities.filter((item) => {
            return item.state === 'accepted';
        });
    });

    const isInProgress = computed(() => {
        // if the length of acceptedActivities is greater than 0
        return acceptedActivities.value.length > 0 && !isCompleted.value;
    });

    const notStarted = computed(() => {
        return acceptedActivities.value.length === 0;
    });

    const isCompleted = computed(() => {
        // if all of props.item.activities are completed, then the job is completed
        return acceptedActivities.value.every((activity) => activity.progress_state === 'completed');
    });

    const isArchived = computed(() => {
        // if the item's state is denied, then the job is archived
        return props.item.state === 'denied';
    });

    const progress = computed(() => {
        const total = acceptedActivities.value.length; // should be the number of accepted activities
        const completedItems = acceptedActivities.value.filter((item) => {
            return item.progress_state === 'completed';
        });

        const progress_amt = (completedItems.length * 100) / total;

        return progress_amt;
    });

    const onUpdateInProgressClick = async () => {
        onStartOrUpdate('update');
    };

    const onStartClick = async () => {
        onStartOrUpdate('start');
    };

    const onStartOrUpdate = async (signalName: 'update' | 'start') => {
        // Find the path that contains this job
        const path = store.paths.find((path: FullPathOption) =>
            path.job_options.some((job: FullJobOption) => job.id === props.item.id)
        );

        if (path) {
            await useGrowPathStore().updatePathJobProgress(path, props.item, router);

            emit(signalName, props.item);
        }
    };

    const onUnarchiveClick = async () => {
        emit('unarchive', props.item);
    };
</script>

<style scoped lang="scss">
    .job-item {
        .action-column {
            height: 100%;
            position: absolute;
            padding-left: 0;
            top: 0;
            right: 0;

            :deep(.v-btn__content) {
                letter-spacing: -0.175px;
            }
        }

        &.job-item-in-progress,
        &.job-item-not-started {
            .action-column {
                border-left: 1px solid rgba(var(--v-theme-custom-neutral-grey-900), 0.1);
            }
        }

        &.job-item-completed,
        &.job-item-archived {
            .action-column {
                border-left: 1px solid rgba(#fff, 0.2);
            }
        }

        &.v-card {
            background-color: white;
            color: rgb(var(--v-theme-custom-neutral-900));
        }
    }
</style>
