import type { User } from '@/api/types/user';

function _featureFlags(user: User | null): string[] {
    const featureFlagsString = user?.feature_flags || '';
    // feature flags are presented as comma separated string, which can contain spaces
    return featureFlagsString.split(/[ ,]+/);
}

export function hasGPDisabledFeature(user: User | null) {
    const featureFlags = _featureFlags(user);
    return featureFlags.includes('GPD');
}
