import type { SelectableGrowPreferenceCategory } from '@/store/grow/types';
import { verify } from '@/store/verify';

export type CategoryValueDefinition = {
    title: string;
    value: SelectableGrowPreferenceCategory;
    maxAmount: number;
    minAmount: number;
    description: string;
};

export const categoryValues: {
    title: string;
    value: SelectableGrowPreferenceCategory;
    maxAmount: number;
    minAmount: number;
    description: string;
}[] = [
    {
        title: 'Needs',
        value: 'need',
        maxAmount: 5,
        minAmount: 1,
        description: 'Your non-negotiables - the baseline for you to take a different role or job',
    },
    {
        title: 'Nice',
        value: 'nice',
        maxAmount: 3,
        minAmount: 1,
        description:
            'Things that would be satisfying to have, in addition to your base needs being met',
    },
    {
        title: 'Bonus',
        value: 'bonus',
        maxAmount: 2,
        minAmount: 1,
        description:
            'Things you would only desire if you had your base and satisfier needs met first',
    },
];

export function findCategoryValue(
    value: SelectableGrowPreferenceCategory,
): CategoryValueDefinition {
    return verify(
        categoryValues.find((category) => category.value === value),
        'Category not found',
    );
}
