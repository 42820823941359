import { type Ref, ref } from 'vue';

export function useScrollToRef() {
    const refElement = ref<HTMLElement | null>(null);

    function scrollToRef() {
        scrollTo(refElement);
    }

    function scrollTo(ref: Ref<HTMLElement | null>) {
        ref.value?.scrollIntoView({ behavior: 'smooth' });
    }

    return { refElement, scrollToRef, scrollTo };
}
