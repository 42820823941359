import type { GenericAbortSignal } from 'axios';
import { pollUntil } from '@/services/pollUntil';
import type { ApiJobOption } from '@/api/types/grow/jobs';
import { fetchJobOptions } from '@/services/grow/jobs/service';

/**
 * We assume that path options will be generated within 90 seconds. If not, we will show an error message,
 * and allow the user to try again. On the retry, we will show the loading screen again.
 * However, if the path options are generated within 90 seconds, we will show the path options.
 *
 * It is possible that path options from the previous run are shown, but this is acceptable.
 * Even maybe, the user see the double activities, but this is acceptable too.
 */
const POLLING_GROW_JOB_OPTIONS_ACTIONS_TIMEOUT = 90 * 1000;

/**
 * Repeatedly fetch a resource until a condition is met or a timeout reached
 */
export async function pollGrowJobs(
    canvasId: number,
    growPathId: number,
    accessToken: string,
    signal: GenericAbortSignal,
): Promise<ApiJobOption[]> {
    return await pollUntil(
        'grow-jobs',
        async (): Promise<ApiJobOption[] | null> => {
            const jobOptions = await fetchJobOptions(canvasId, growPathId, accessToken);
            return jobOptions.length > 0 ? jobOptions : null;
        },
        { pollTimeout: POLLING_GROW_JOB_OPTIONS_ACTIONS_TIMEOUT, signal },
    );
}
