import { create, createInBackground, fetch, update } from '@/services/base';
import {
    getJobOptionActivities,
    postCreateJobOptionActivity,
    postGenerateJobOptionActivity,
    postRegenerateJobOptionActivity,
    putBulkUpdateJobOptionActivities,
    putUpdateJobOptionActivity,
} from '@/services/grow/activities/api';
import type { ApiResponse } from '@/services/apiClient';
import type { PathJobActivity, JobActivityCreateData } from '@/store/grow/types';
import type { ApiBulkUpdateJobActivity, ApiJobActivity, ApiJobActivityUpdateData } from '@/api/types/grow/jobs';

export async function generateJobOptionActivity(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    return await createInBackground(
        'generate-job-option-activity',
        async () =>
            await postGenerateJobOptionActivity(
                canvasId,
                growPathOptionId,
                jobOptionId,
                accessToken,
            ),
    );
}

export async function fetchJobOptionActivities(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    accessToken: string,
): Promise<PathJobActivity[]> {
    return fetch(
        'job-option-activities',
        async () =>
            await getJobOptionActivities(canvasId, growPathOptionId, jobOptionId, accessToken),
    );
}

export async function createJobOptionActivity(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    accessToken: string,
    activity: JobActivityCreateData, // Replace this with your actual activity model
): Promise<[ApiJobActivity, ApiResponse<ApiJobActivity>]> {
    return create(
        'create-job-option-activity',
        async () =>
            await postCreateJobOptionActivity(
                canvasId,
                growPathOptionId,
                jobOptionId,
                accessToken,
                activity,
            ),
    );
}

export async function updateJobOptionActivity(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    activityId: number,
    accessToken: string,
    updatedActivity: ApiJobActivityUpdateData,
): Promise<void> {
    return update(
        'update-job-option-activity',
        async () =>
            await putUpdateJobOptionActivity(
                canvasId,
                growPathOptionId,
                jobOptionId,
                activityId,
                accessToken,
                updatedActivity,
            ),
    );
}

export async function bulkUpdateJobOptionActivities(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    accessToken: string,
    updatedActivity: ApiBulkUpdateJobActivity[],
): Promise<void> {
    return update(
        'bulk-update-job-option-activities',
        async () =>
            await putBulkUpdateJobOptionActivities(
                canvasId,
                growPathOptionId,
                jobOptionId,
                accessToken,
                updatedActivity,
            ),
    );
}

export async function regenerateJobOptionActivity(
    canvasId: number,
    growPathOptionId: number,
    jobOptionId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    return await createInBackground(
        'regenerate-job-option-activity',
        async () =>
            await postRegenerateJobOptionActivity(
                canvasId,
                growPathOptionId,
                jobOptionId,
                accessToken,
            ),
    );
}
