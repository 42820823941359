import { type ApiResponse, delete_, get, post, put } from '@/services/apiClient';
import type {
    ApiGrowPredefinedPreferenceType,
    ApiGrowPreference,
    ApiGrowPreferenceCreate,
    ApiGrowPreferenceUpdate,
} from '@/api/types/grow/preferences';

export async function getPredefinedPreferenceTypes(
    accessToken: string,
): Promise<ApiResponse<ApiGrowPredefinedPreferenceType[]>> {
    const request = get<ApiGrowPredefinedPreferenceType[]>(`grow/predefined-preference-types/`);
    return request(accessToken);
}

export async function getPreferences(
    canvasId: number,
    accessToken: string,
): Promise<ApiResponse<ApiGrowPreference[]>> {
    const request = get<ApiGrowPreference[]>(`canvas/${canvasId}/grow/preferences/`);
    return request(accessToken);
}

export async function postPreference(
    canvasId: number,
    createData: ApiGrowPreferenceCreate,
    accessToken: string,
): Promise<ApiResponse<ApiGrowPreference>> {
    const request = post<ApiGrowPreferenceCreate, ApiGrowPreference>(
        `canvas/${canvasId}/grow/preferences/`,
    );
    return request(accessToken, createData);
}

export async function putPreference(
    canvasId: number,
    preferenceId: number,
    updateData: ApiGrowPreferenceUpdate,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = put<ApiGrowPreferenceUpdate, void>(
        `canvas/${canvasId}/grow/preferences/${preferenceId}`,
    );
    return request(accessToken, updateData);
}

export async function deletePreference(
    canvasId: number,
    preferenceId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    const request = delete_<void>(`canvas/${canvasId}/grow/preferences/${preferenceId}`);
    return request(accessToken);
}
