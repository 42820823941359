import type { ActionFrequency } from '@/api/types/plan/currentChallengeAction';
export function formatFrequency(f: ActionFrequency, min: number, max?: number): string {
    const durationMin = min;
    let durationMax: number | undefined | null = max;
    const frequency = f;

    // Handle cases where max is less than min by ignoring max
    if (!!durationMax && durationMax <= durationMin) {
        durationMax = null;
    }

    if (!frequency) {
        return 'No frequency specified';
    }

    switch (frequency) {
        case 'daily':
            return 'Do this daily';

        case 'weekly':
            return 'Do this weekly';

        case 'monthly':
            if (durationMin <= 1) {
                return 'Do this monthly';
            } else {
                return `Do this fortnightly`;
            }

        case 'yearly':
            return 'Do this quarterly';
        default:
            return 'Frequency format not recognized';
    }
}
