import { defineStore } from 'pinia';
import { useCanvasStore } from '@/store/canvas/store';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
} from '@/store/common/dataState';
import type {
    CanvasGrowPreference,
    GrowPredefinedPreferenceType,
    PreferencesStoreState,
    SelectableGrowPreferenceCategory,
} from '@/store/grow/types';
import { cloneDeep, find, remove, shuffle } from 'lodash';
import {
    createGrowPreference,
    fetchGrowPredefinedPreferenceTypes,
    fetchGrowPreferences,
    removeGrowPreference,
    updateGrowPreference,
} from '@/services/grow/preferences/service';
import {
    type ApiGrowPredefinedPreferenceItem,
    type ApiGrowPredefinedPreferenceType,
    type ApiGrowPreference,
    type ApiPreferenceCategory,
    GrowPreferencePreferenceTypeId,
} from '@/api/types/grow/preferences';
import { verify } from '@/store/verify';
import { PREDEFINED_PREFERENCE_TYPE_DEFINITION } from '@/components/grow/path/preferences/predefinedPreferenceTypeDefinition';
import { findCategoryValue } from '@/components/grow/path/preferences/utils';

export const MINIMUM_AMOUNT_PREFERENCES = 10;

export const useGrowPreferencesStore = defineStore({
    id: 'grow-preferences-store',
    state: (): PreferencesStoreState => ({
        predefinedOptions: [],
        original: [],
        value: [],
        carouselItems: [],
        currentIndex: 0,
        niceList: [],
        needList: [],
        bonusList: [],
        prioritiseSelectionView: false,

        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        selectedIds(state): Array<{title: string, type: GrowPreferencePreferenceTypeId}> {
            // CORE-226 We need a way to identify the selection back to the original predefined list
            // At the moment the original predefined list is not exposed in the API (neither DB)
            // We use the title as a unique identifier for now, but with the risk of
            // having duplicates / or title changes
            return state.value.map((item) => ({
                title: item.title,
                type: item.type
            }));
        },
        current(state): CanvasGrowPreference {
            return state.carouselItems[state.currentIndex];
        },
        isAreaComplete(state): boolean {
            return this.isPreferencesSelectionComplete && this.isPrioritySelectionComplete;
        },
        isAreaInProgress(state): boolean {
            return this.selectedIds.length > 0;
        },
        isPreferencesSelectionComplete(state): boolean {
            return this.selectedIds.length >= MINIMUM_AMOUNT_PREFERENCES;
        },
        isPrioritySelectionComplete(state): boolean {
            const needDefinition = findCategoryValue('need');
            const niceDefinition = findCategoryValue('nice');
            const bonusDefinition = findCategoryValue('bonus');
            return (
                this.needList.length >= needDefinition.minAmount &&
                this.needList.length <= needDefinition.maxAmount &&
                this.niceList.length >= niceDefinition.minAmount &&
                this.niceList.length <= niceDefinition.maxAmount &&
                this.bonusList.length >= bonusDefinition.minAmount &&
                this.bonusList.length <= bonusDefinition.maxAmount
            );
        },
        needsLoading(state): boolean {
            return state.original.length === 0;
        },
    },
    actions: {
        async load() {
            if (!this.needsLoading) {
                console.info('Preferences already loaded');
                return;
            }

            console.info('Loading preferences ...');

            setInLoading(this);

            try {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();
                const predefinedItems = await fetchGrowPredefinedPreferenceTypes(accessToken);
                const preferences = await fetchGrowPreferences(canvasId, accessToken);

                this._setPredefinedOptions(predefinedItems);
                this._setPreferences(preferences);
                this.prioritiseSelectionView = this.isPreferencesSelectionComplete;

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        isSelected(option: ApiGrowPredefinedPreferenceItem | ApiGrowPreference, type: GrowPreferencePreferenceTypeId): boolean {
            return this.selectedIds.some(id => id.title === option.title && id.type === type);
        },
        async togglePreference(
            option: ApiGrowPredefinedPreferenceItem,
            type: GrowPreferencePreferenceTypeId,
        ) {
            try {
                if (this.isSelected(option, type)) {
                    await this.removePreference(option, type);
                } else {
                    await this.addPreference(option, type);
                }
            } catch (error: unknown) {
                console.error(error instanceof Error ? error.message : error);
            }
        },
        async addPreference(
            option: ApiGrowPredefinedPreferenceItem,
            type: GrowPreferencePreferenceTypeId,
        ) {
            try {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                const preference = await createGrowPreference(
                    canvasId,
                    { ...option, type: type, category: null },
                    accessToken,
                );

                if (this.isSelected(option, type)) {
                    console.warn(`Preference ${option.title} already selected`);
                } else {
                    this.value = [...this.value, preference];
                    this.carouselItems = [...this.carouselItems, preference];
                }
            } catch (error) {
                console.error(error instanceof Error ? error.message : error);
            }
        },
        async updatePreference(preference: CanvasGrowPreference, new_category: ApiPreferenceCategory | null = null) {
            try {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                await updateGrowPreference(
                    canvasId,
                    preference.id,
                    { category: new_category },
                    accessToken,
                );
            } catch (error) {
                console.error(error instanceof Error ? error.message : error);
            }
        },
        async removePreference(option: ApiGrowPredefinedPreferenceItem, type: GrowPreferencePreferenceTypeId) {
            const preferenceToDelete = this.value.find((item) => item.title === option.title && item.type === type);

            if (!preferenceToDelete) {
                return;
            }

            // If the preference has a category, remove it from the corresponding category list
            if (preferenceToDelete.category) {
                this._removeFromCategoryList(
                    this.getListByCategory(preferenceToDelete.category),
                    preferenceToDelete
                );
            } else {
                // If the preference is not categorized, it might be in the carousel
                this._removeFromCategoryList(this.carouselItems, preferenceToDelete);
            }

            this.value = this.value.filter((item) => item !== preferenceToDelete);

            const { canvasId, accessToken } = await useCanvasStore().makeContext();

            await removeGrowPreference(canvasId, preferenceToDelete.id, accessToken);
        },
        async updateCategory(category: ApiPreferenceCategory) {
            const preference = this._removeCurrentFromCarousel();
            await this._updateSelectionWithCategory(preference, category);
        },
        async updateCategoryList(list: CanvasGrowPreference[], type: SelectableGrowPreferenceCategory) {
            // Only update items with a different category to avoid unnecessary operations
            const itemsToUpdate = list.filter(item => item.category !== type);
            await Promise.all(
                itemsToUpdate.map(item => this._updateSelectionWithCategory(item, type))
            );
        },
        _updateSelectionWithCategory: async function (
            preference: CanvasGrowPreference,
            value: ApiPreferenceCategory,
        ) {
            // Skip if the category hasn't changed
            if (preference.category === value) {
                return;
            }

            // First remove from current category if it exists
            const currentCategory = preference.category;
            if (currentCategory) {
                this._removeFromCategoryList(this.getListByCategory(currentCategory), preference);
            }

            // Then add to new category if it exists
            if (value) {
                const list = this.getListByCategory(value);
                if (!list.some(item => item.id === preference.id)) {
                    list.push(preference);
                }
            }

            // Update the preference category and persist to backend
            preference.category = value;
            await this.updatePreference(preference, value);
        },
        _removeFromCategoryList: function (
            list: CanvasGrowPreference[],
            preference: CanvasGrowPreference,
        ) {
            remove(list, (item) => {
                return item.id === preference.id;
            });
        },
        getListByCategory: function (
            category: SelectableGrowPreferenceCategory,
        ): CanvasGrowPreference[] {
            switch (category) {
                case 'nice':
                    return this.niceList;
                case 'need':
                    return this.needList;
                case 'bonus':
                    return this.bonusList;
                default:
                    throw new Error('Invalid category');
            }
        },
        // ###############################
        //
        // Side - effects
        //
        // ###############################
        _setPreferences: function (preferences: ApiGrowPreference[]): void {
            this.original = preferences;
            this.value = cloneDeep(preferences);
            const carouselItems = this.value.filter((item) => !item.category);
            this.carouselItems = shuffle(carouselItems);
            // This is for a better UX, so there cards left and right on the carousel
            this.currentIndex = getMiddleIndex(this.carouselItems);

            this.needList = this.value.filter((item) => item.category === 'need');
            this.niceList = this.value.filter((item) => item.category === 'nice');
            this.bonusList = this.value.filter((item) => item.category === 'bonus');
        },
        _setPredefinedOptions: function (predefinedItems: ApiGrowPredefinedPreferenceType[]): void {
            this.predefinedOptions = predefinedItems
                .map((category: GrowPredefinedPreferenceType) => {
                    const definition = verify(
                        PREDEFINED_PREFERENCE_TYPE_DEFINITION[
                            category.title.toLowerCase() as GrowPreferencePreferenceTypeId
                        ],
                        `Preference type definition not found for ${category.title}`,
                    );

                    return {
                        ...category,
                        ...definition,
                    };
                })
                .sort((a, b) => a.order - b.order);
        },
        async onCategoryRemoved(preference: CanvasGrowPreference): Promise<void> {
            // First remove from current category if it exists
            const currentCategory = preference.category;
            if (currentCategory) {
                this._removeFromCategoryList(this.getListByCategory(currentCategory), preference);
            }

            // Add back to carousel
            if (!this.carouselItems.some(item => item.id === preference.id)) {
                this.carouselItems.push(preference);
            }

            // Update the preference category to null
            await this._updateSelectionWithCategory(preference, null);
        },
        _removeCurrentFromCarousel: function (): CanvasGrowPreference {
            const [removedItem] = this.carouselItems.splice(this.currentIndex, 1);

            if (removedItem) {
                const totalItems = this.carouselItems.length;
                if (this.currentIndex === totalItems) {
                    this.currentIndex = totalItems - 1;
                }

                return removedItem;
            } else {
                throw new Error('personal card removed but did not exist');
            }
        },
    },
});

const getMiddleIndex = (list: any[]) => {
    return list.length ? Math.floor(list.length / 2) : 0;
};
